import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import Router from "./utils/router";
// If you are using Less, import the `index.less` file.
import "rsuite/Button/styles/index.css";

function App() {
  // In your index.js or App.js
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }
  // In your index.js or App.js
  let deferredPrompt;
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    deferredPrompt = e;
    // Show the install prompt
    // You can add a button or trigger this programmatically
  });

  return (
    <>
      <Router />
      <Toaster position="top-right" />
    </>
  );
}

export default App;
