import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import toast from "react-hot-toast";
import { Oval, TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postFirmNamePost } from "../utils/redux/slice/postFirmNameSlice";
import { getFirmProfileGet } from "../utils/redux/slice/getFirmProfileSlice";
import { putEditFirmPut } from "../utils/redux/slice/putEditFirmSlice";
import { domainUrl, listedStates } from "../utils/constants";
import { motion } from "framer-motion";
import CurrencyFormat from "react-currency-format";

const UpdateFirms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [loading, setLoading] = useState(false);

  const firm_id = localStorage.getItem("firm_id");

  const [firmDetail, setFirmDetail] = useState({
    firm_name: "",
    email: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    cell_phone_number: "",
    secondary_cell_phone_number: "",
    firm_code: "",
    id: "",
    //financial_details: {},
    //payment_cards: [],
    //direct_deposit: {},
  });
  const [firmDetailFlag, setFirmDetailFlag] = useState({
    firm_name: "",
    email: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    cell_phone_number: "",
    secondary_cell_phone_number: "",
    firm_code: "",
    //financial_details: {},
    //payment_cards: [],
    //direct_deposit: {},
  });
  const getInitialData = async () => {
    setLoading(true);
    const res = await dispatch(getFirmProfileGet(navigate));

    if (res.payload) {
      setFirmDetail({
        ...firmDetail,
        id: res.payload.id,
        firm_name: res.payload.firm_name,
        email: res.payload.email,
        address_line1: res.payload.address_line1,
        address_line2: res.payload.address_line2,
        city: res.payload.city,
        state: res.payload.state,
        zip_code: res.payload.zip_code,
        cell_phone_number: res.payload.cell_phone_number,
        secondary_cell_phone_number: res.payload.secondary_cell_phone_number,
        firm_code: res.payload.firm_code,
        //financial_details: res.payload.//financial_details,
        //payment_cards: res.payload.//payment_cards,
        //direct_deposit: res.payload.//direct_deposit,
      });
      setSelectedState(getStateByCode(res.payload.state));
      setLoading(false);
    }
    if (res.error) {
      console.log(res.error);
      toast.error(res.error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (firm_id) {
      getInitialData();
    } else {
      navigate(-1);
    }
  }, []);

  const firmCodeLoader = useSelector(
    (state) => state.postFirmNameStorage.isLoading
  );

  const filterNonNumbers = (value) => {
    return value.replace(/[^0-9]/g, "");
  };
  const filterNumberLength = (value, max) => {
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length > max) {
      return numericValue.slice(0, max);
    }
    return numericValue;
  };

  const getStateByCode = (code) => {
    const state = listedStates.find((state) => state.code === code);
    return state ? state : null;
  };
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();

  const [query, setQuery] = useState("");
  const [queryCity, setQueryCity] = useState("");
  const [city, setCity] = useState([]);
  const filteredcity =
    queryCity === ""
      ? city
      : city.filter((city) =>
          city.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(queryCity.toLowerCase().replace(/\s+/g, ""))
        );

  const filteredstates =
    query === ""
      ? listedStates
      : listedStates.filter((listedStates) =>
          listedStates.code
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const errMsg = {
    email: "Enter a valid email.",
    cell_phone_number: "Phone number must be 10 digit",
    secondary_cell_phone_number: "Secondary Phone number must be 10 digit",
    zip_code: "zipcode should be 5 digit",
    first_name: "Please enter a valid first name (1-30 characters)",
    last_name: "Please enter a valid last name (1-30 characters)",
    escrow_percentage: "Exceeds the percentage limit for Escrow",
    na_fee_percentage: "Exceeds the percentage limit for NA Fee",
    bank_routing_number: "Bank Routing number should be of length 9 digits.",
    bank_account_number:
      "The bank account number must be between 5 and 18 digits in length.",
    cvv: "CVV should be 3 or 4 digits long. ",
    card_number: "Card number should be 16 digits long.",
  };

  const [errorFlag, setErrorFlag] = useState({
    firm_name: "",
    email: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    cell_phone_number: "",
    secondary_cell_phone_number: "",
    firm_code: "",
  });

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email.length > 0 && regex.test(email);
  };
  const formatAlphabetsWithSpace = (value) => {
    let formattedAlphabets;
    const { length } = value;

    // Filter non-alphabets and spaces
    const regex = () => value.replace(/[^a-zA-Z\s]+/g, "");

    // New validation
    if (length > 0) {
      formattedAlphabets = regex();
    }

    return formattedAlphabets;
  };

  const length_validate = {
    zip_code: 5,
    cvv: 4,
    card_number: 16,
    bank_routing_number: 9,
    bank_account_number: 18,
    escrow_percentage: 2,
    na_fee_percentage: 2,
  };

  const formatcell_phone_number = (value) => {
    let convertedNumber = value.replace(/\D/g, "");
    convertedNumber = convertedNumber.replace(/^1/, "");
    convertedNumber = convertedNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1 $2 $3"
    );
    console.log(convertedNumber.length);
    return convertedNumber;
  };

  const formHandler = async (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // contains firm details & primary attorney

    // firm Data handling
    if (e.target.name == "firm_name") {
      console.log("firm");
      setFirmDetail({
        ...firmDetail,
        firm_name: formatAlphabetsWithSpace(e.target.value),
      });
    } else if (
      ["cell_phone_number", "secondary_cell_phone_number"].includes(
        e.target.name
      )
    ) {
      setFirmDetail({
        ...firmDetail,
        [e.target.name]: e.target.value,
      });
    } else if (["state", "city"].includes(e.target.name)) {
      // 'state' json  is available in selectedState
      setFirmDetail({
        ...firmDetail,
        [e.target.name]: formatAlphabetsWithSpace(e.target.value),
      });
    } else if (e.target.name == "zip_code") {
      setFirmDetail({
        ...firmDetail,
        [e.target.name]: filterNumberLength(
          e.target.value,
          length_validate[e.target.name]
        ),
      });
    } else {
      setFirmDetail({
        ...firmDetail,
        [e.target.name]: e.target.value,
      });
    }

    // Validate form of any type

    setTypingTimeout(setTimeout(async () => validateFormFields(e), 350));
  };

  const validateFormFields = async (e) => {
    if (e.target.value) {
      {
        // Firm Validations
        console.log("validating firm");
        if (e.target.name == "firm_name") {
          // Perform firm unique code generation
          if (e.target.value.length > 0) {
            // const res = await dispatch(
            //   postFirmNamePost({ firm_name: firmDetail.firm_name },navigate)
            // );
            // if (res.payload) {
            //   toast.success("Firm Code Generated", { id: 1 });
            //   setFirmDetail((prev) => ({
            //     ...prev,
            //     firm_code: res.payload.firm_code,
            //   }));
            //   console.log("Backend response");
            // }
            // if (res.error) {
            //   console.log("res.error postFirmName", res.error);
            // }
          } else {
            // Remove generated firm code
            // setFirmDetail((prev) => ({
            //   ...prev,
            //   firm_code: "",
            // }));
          }
          return;
        } else if (e.target.name == "email") {
          console.log("validating firm email");

          setErrorFlag((prevState) => ({
            ...prevState,
            [e.target.name]: !validateEmail(e.target.value)
              ? errMsg[e.target.name]
              : "",
          }));
        } else if (e.target.name == "zip_code") {
          setErrorFlag((prevState) => ({
            ...prevState,
            [e.target.name]:
              e.target.value.length != 5 ? errMsg[e.target.name] : "",
          }));
        } else if (
          e.target.name == "cell_phone_number" ||
          e.target.name == "secondary_cell_phone_number"
        ) {
          setErrorFlag((prevState) => ({
            ...prevState,
            [e.target.name]:
              formatcell_phone_number(e.target.value).length != 12
                ? errMsg[e.target.name]
                : "",
          }));
        }
      }
    }
  };

  const isButtonFirmSecDisabled = () => {
    // Check if all required fields are filled except address_lin1, city, zip_code, and secondary_cell_phone_number.
    const requiredFields = [
      firmDetail.firm_name,
      firmDetail.email,
      firmDetail.state,
      firmDetail.city,
      firmDetail.cell_phone_number,
      // firmDetail.secondary_cell_phone_number,
      firmDetail.zip_code,
      firmDetail.address_line1,
      // firmDetail.address_line2,
    ];
    for (const key in errorFlag) {
      console.log(key, firmDetail[key]);
      if (firmDetail[key] == "") {
        // errorFlag.firm[key] = "This Field is required";
        if (key == "secondary_cell_phone_number" || key == "address_line2") {
          continue;
        }

        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [key]: `*${key
            .replace("cell_phone_number", "primary phone")
            .replace(/_/g, " ")
            .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
        }));
      }
    }

    return requiredFields.some((field) => field === "");
  };

  const submitUpdateFirmHandler = async () => {
    let submitData = firmDetail;
    submitData.cell_phone_number = formatcell_phone_number(
      submitData.cell_phone_number
    );
    submitData.secondary_cell_phone_number = formatcell_phone_number(
      submitData.secondary_cell_phone_number
    );
    if (firmDetail) {
      const checkField = isButtonFirmSecDisabled();
      if (checkField == true) {
        toast.error("Complete the fields", { id: 1 });
        return false;
      }
    }
    const res = await dispatch(putEditFirmPut(submitData, navigate));
    if (res.payload) {
      console.log(res.payload);
      toast.success("Updated Successfully");
      navigate("/view-firm");
    }
    if (res.error) {
      console.log("=>", JSON.parse(res.error.message));
      // toast.error(res.error.message);
      setErrorFlag(JSON.parse(res.error.message));
    }
  };

  const updateLoader = useSelector(
    (state) => state.putEditFirmStorage.isLoading
  );
  return (
    <>
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          {updateLoader ? (
            <div className="w-full h-screen flex justify-center items-center">
              <Oval
                height={80}
                width={80}
                color="#1a3253"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#396181"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <motion.div
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.1, type: "spring", stiffness: 120 }}
            >
              <div className="mt-8  border rounded-lg shadow-md py-4">
                <div className="mx-5 ml-5">
                  <div className="mt-3 px-5">
                    <div className="text-xl font-bold">UPDATE FIRM</div>
                    <div className="text-sm text-[#9ca0b3]">Firm Details</div>
                  </div>

                  <form>
                    <div className="py-6 mt-3">
                      <div className="space-y-12  lg:p-5 ">
                        <div className="border-gray-900/10">
                          {firmDetail.firm_code == "" && (
                            <div className="font-bold">
                              Firm Unique Code: <span className="ml-2">--</span>
                            </div>
                          )}
                          {firmDetail.firm_code !== "" && (
                            <>
                              <div className="flex gap-2">
                                <div className="font-bold">
                                  Firm Unique Code:
                                </div>
                                <div>
                                  {!firmCodeLoader ? (
                                    <>{firmDetail.firm_code}</>
                                  ) : (
                                    <span>
                                      <TailSpin
                                        height="20"
                                        width="20"
                                        color="black"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </>
                          )}

                          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Firm Name
                              </label>
                              <div className="mt-2">
                                <input
                                  name="firm_name"
                                  value={firmDetail.firm_name}
                                  onChange={(e) => formHandler(e)}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-4">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                              </label>
                              <div className="mt-2">
                                <input
                                  name="email"
                                  onBlur={() => {
                                    setFirmDetailFlag((prev) => ({
                                      ...prev,
                                      email: false,
                                    }));
                                  }}
                                  value={firmDetail.email}
                                  onChange={(e) => {
                                    formHandler(e);
                                  }}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag.email}
                                </span>
                              </div>
                            </div>
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Address 1
                              </label>
                              <div className="mt-2">
                                <input
                                  name="address_line1"
                                  value={firmDetail.address_line1}
                                  onChange={(e) => formHandler(e)}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                                <span className="text-amber-500 text-xs">
                                  {errorFlag.address_line1}
                                </span>
                              </div>
                            </div>
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Address 2
                              </label>
                              <div className="mt-2">
                                <input
                                  name="address_line2"
                                  value={firmDetail.address_line2}
                                  onChange={(e) => formHandler(e)}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                                <span className="text-amber-500 text-xs">
                                  {errorFlag.address_line2}
                                </span>
                              </div>
                            </div>
                            <div className="sm:col-span-2 ">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                City
                              </label>
                              <div className="mt-2">
                                <input
                                  name="city"
                                  value={firmDetail.city}
                                  onChange={(e) => formHandler(e)}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                              </div>
                              <span className="text-red-500 text-xs">
                                {errorFlag.city}
                              </span>
                            </div>

                            <div className="sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                State / Province
                              </label>

                              <div className="mt-1 w-full h-full flex items-center">
                                <div className="grow h-full">
                                  <Combobox
                                    value={selectedState}
                                    onChange={(value) => {
                                      console.log(value);
                                      setSelectedState(value);
                                      // formHandler()
                                      setFirmDetail({
                                        ...firmDetail,
                                        state: value.code,
                                      });
                                    }}
                                  >
                                    <div className="relative">
                                      <div className="relative w-full h-10 cursor-default overflow-hidden rounded-lg bg-white text-left  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                        <Combobox.Input
                                          className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                          displayValue={(listedStates) =>
                                            listedStates.code
                                          }
                                          onChange={(event) => {
                                            setQuery(event.target.value);
                                          }}
                                          placeholder="Select state"
                                        />
                                      </div>
                                      <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        afterLeave={() => setQuery("")}
                                      >
                                        <Combobox.Options className="absolute  max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {filteredstates.length === 0 &&
                                          query !== "" ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                              No match found.
                                            </div>
                                          ) : (
                                            filteredstates.map(
                                              (listedStates) => (
                                                <Combobox.Option
                                                  key={listedStates.id}
                                                  className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-10 pr-4  ${
                                                      active
                                                        ? "bg-[#376080] text-white"
                                                        : "text-gray-900"
                                                    }`
                                                  }
                                                  value={listedStates}
                                                  disabled={
                                                    listedStates.unavailable
                                                  }
                                                >
                                                  {({ selected, active }) => (
                                                    <>
                                                      <span
                                                        className={`block truncate ${
                                                          selected
                                                            ? "font-medium"
                                                            : "font-normal"
                                                        }`}
                                                      >
                                                        {listedStates.code}
                                                      </span>
                                                      {selected ? (
                                                        <span
                                                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                            active
                                                              ? "text-white"
                                                              : "text-[#376080]"
                                                          }`}
                                                        >
                                                          <CheckIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                          />
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </Combobox.Option>
                                              )
                                            )
                                          )}
                                        </Combobox.Options>
                                      </Transition>
                                    </div>
                                  </Combobox>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>

                            <div className="sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                ZIP / Postal code
                              </label>
                              <div className="mt-2">
                                <input
                                  name="zip_code"
                                  value={firmDetail.zip_code}
                                  onChange={(e) => formHandler(e)}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                              </div>

                              <span className="text-red-500 text-xs">
                                {errorFlag.zip_code}
                              </span>
                            </div>

                            <div className="sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Primary Phone
                              </label>
                              <div className="mt-2">
                                <CurrencyFormat
                                  name="cell_phone_number"
                                  value={firmDetail.cell_phone_number}
                                  onChange={(e) => formHandler(e)}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                  format="+1 (###) ###-####"
                                  mask="_"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag.cell_phone_number}
                                </span>
                              </div>
                            </div>
                            <div className="sm:col-span-2">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Secondary Phone
                              </label>
                              <div className="mt-2">
                                <CurrencyFormat
                                  name="secondary_cell_phone_number"
                                  value={firmDetail.secondary_cell_phone_number}
                                  onChange={(e) => formHandler(e)}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                  format="+1 (###) ###-####"
                                  mask="_"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag.secondary_cell_phone_number}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 pt-5 flex justify-end gap-5">
                            <button
                              type="button"
                              onClick={() => {
                                submitUpdateFirmHandler();
                              }}
                              className="bg-[#272729] text-white px-4 py-2 rounded-lg hover:bg-[#172729] focus:outline-none focus:bg-[#272729]"
                            >
                              Update
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                navigate("/view-firm");
                              }}
                              className="bg-[#272729] text-white px-4 py-2 rounded-lg hover:bg-[#172729] focus:outline-none focus:bg-[#272729]"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </motion.div>
          )}
        </>
      )}
    </>
  );
};

export default UpdateFirms;
