import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFirmProfileGet } from "../utils/redux/slice/getFirmProfileSlice";
import { getAttorneyProfileGet } from "../utils/redux/slice/getAttorneyProfileSlice";
import axios from "axios";
import { domainUrl } from "../utils/constants";
import { useDispatch } from "react-redux";

function Breadcrumb() {
  const location = useLocation();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const access_token = localStorage.getItem("access_token");

  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  const [secondChild, setSecondChild] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!role && !access_token) {
      navigate("/login");
    } else {
      if (role === "admin" && location.pathname === "/") {
        navigate("/admin-home");
      }
      if (role === "primaryattorney" && location.pathname === "/") {
        navigate("/firm-home");
      }
      if (role === "attorney" && location.pathname === "/") {
        navigate("/attorney-home");
      }
      if (role === "client" && location.pathname === "/") {
        navigate("/client-home");
      }
    }
    fetchFromLocal();
  }, [location.pathname]);

  const navigation = {
    admin: [
      {
        title: "Home",
        src: "/admin-home",
      },
      {
        title: "Manage Firms",
        subMenus: [
          {
            title: "Firms Overview",
            src: "/firm-overview",
          },
          {
            title: "Escrow Overview",
            src: "/escrow-overview",
          },
          {
            title: "Add Firm",
            src: "/add-firm",
          },
          {
            title: "List Firm",
            src: "/list-firm",
          },
          {
            title: "Firm Payouts",
            src: "/firm-payouts",
          },
          {
            title: "Payout History",
            src: "/payout-history",
          },
        ],
        current: false,
      },
      {
        title: "Manage Attorneys",
        subMenus: [
          {
            title: "Add Attorney",
            src: "/add-attorney",
          },
          {
            title: "List Attorney",
            src: "/list-attorneys",
          },
          {
            title: "Pending Invitations",
            src: "/attorney-pending-invitations",
          },
        ],
        current: false,
      },
      {
        title: "Manage Clients",
        subMenus: [
          {
            title: "Client Overview",
            src: "/client-overview",
          },
          {
            title: "List Client",
            src: "/list-clients",
          },
          {
            title: "Pending Invitations",
            src: "/pending-invitations",
          },
          {
            title: "Pending Approval",
            src: "/pending-clients",
          },
          {
            title: "Approved Clients",
            src: "/approved-clients",
          },
          {
            title: "Rejected Clients",
            src: "/rejected-clients",
          },
          {
            title: "Paused Clients",
            src: "/paused-clients",
          },
          {
            title: "Missed Payments",
            src: "/missed-payment-clients",
          },
          {
            title: "Delinquent Clients",
            src: "/delinquent-clients",
          },
          {
            title: "Pre-Delinquent Clients",
            src: "/predelinquent-clients",
          },
          {
            title: "Past Delinquent Clients",
            src: "/pastdelinquent-clients",
          },
          {
            title: "Archived Clients",
            src: "/archived-clients",
          },
        ],
        current: false,
      },
      {
        title: "Manage Holidays",
        src: "/manage-holidays",
      },
      {
        title: "Reports",
        subMenus: [
          {
            title: "Escrow History",
            src: "/escrow-history",
          },
          {
            title: "Transaction History",
            src: "/transaction-history",
          },
          {
            title: "Delinquent Payments",
            src: "/delinquent-payments",
          },
          {
            title: "Expected Payments",
            src: "/expected-payments",
          },
          {
            title: "Financial Reports",
            src: "/financial-reports",
          },
        ],
        current: false,
      },
    ],
    primaryattorney: [
      {
        title: "Home",
        src: "/firm-home",
      },
      {
        title: "Escrow Overview",
        src: "/escrow-overview",
      },
      {
        title: "Add Attorney",
        src: "/add-attorney",
      },
      {
        title: "List Attorney",
        src: "/list-attorneys",
      },
      {
        title: "Manage Clients",
        current: false,
        subMenus: [
          {
            title: "Add New Client",
            src: "/add-clients",
          },
          {
            title: "List Client",
            src: "/list-clients",
          },
          {
            title: "Pending Invitation",
            src: "/pending-invitations",
          },
          {
            title: "Pending Approval",
            src: "/pending-clients",
          },
          {
            title: "Approved Clients",
            src: "/approved-clients",
          },
          {
            title: "Rejected Clients",
            src: "/rejected-clients",
          },
          {
            title: "Missed Payments",
            src: "/missed-payment-clients",
          },
          {
            title: "Delinquent Clients",
            src: "/delinquent-clients",
          },
          {
            title: "Pre-Delinquent Clients",
            src: "/predelinquent-clients",
          },
        ],
      },
      {
        title: "Reports",
        subMenus: [
          {
            title: "Financial Reports",
            src: "/financial-reports",
          },
        ],
        current: false,
      },
    ],

    attorney: [
      {
        title: "Home",
        src: "/attorney-home",
      },
      {
        title: "Manage Clients",
        current: false,
        subMenus: [
          {
            title: "Add New Client",
            src: "/add-clients",
          },
          {
            title: "List Client",
            src: "/list-clients",
          },
          {
            title: "Pending Invitation",
            src: "/pending-invitations",
          },
          {
            title: "Pending Approval",
            src: "/pending-clients",
          },
          {
            title: "Approved Clients",
            src: "/approved-clients",
          },
          {
            title: "Rejected Clients",
            src: "/rejected-clients",
          },
          {
            title: "Delinquent Clients",
            src: "/delinquent-clients",
          },
          {
            title: "Pre-Delinquent Clients",
            src: "/predelinquent-clients",
          },
        ],
      },
    ],

    client: [
      { title: "Home", src: "/client-home" },
      {
        title: "Attorney & Contract",
        src: "/client-contracts-view",
      },
    ],
  };

  function Arrowsvg() {
    return (
      <svg
        className="w-3 h-3 text-gray-400 mx-1"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 6 10"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 9 4-4-4-4"
        />
      </svg>
    );
  }
  // Get the second child data
  //  Local storage at this instance
  async function fetchFromLocal() {
    // Firm DAta
    if (localStorage.getItem("firm_id") !== null) {
      const res = await dispatch(getFirmProfileGet(navigate));
      if (res.payload) {
        setSecondChild(res.payload.firm_name);
      }
    }

    // Attorney DAta
    else if (localStorage.getItem("view_attorney_id") !== null) {
      const res = await dispatch(getAttorneyProfileGet(navigate));
      if (res.payload) {
        setSecondChild(
          res.payload.user.first_name + " " + res.payload.user.last_name
        );
      }
    }

    // Client DAta
    else if (localStorage.getItem("view_client_id") !== null) {
      const res = await axios.get(
        `${domainUrl}client?id=${localStorage.getItem("view_client_id")}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        setSecondChild(
          res.data.user.first_name + " " + res.data.user.last_name
        );
      }
    } else {
      setSecondChild("");
    }
  }
  // This function returns the breadcrumb data in formate Home/ parent.title/ first_child.titls/ second_child.title
  function BreadcrumbHelper(props) {
    return (
      <div className="flex items-center">
        <>
          <Arrowsvg />
          <Link
            to={props.parent.src}
            className="text-sm font-medium cursor-pointer text-gray-700 hover:text-gray-600"
          >
            <div>{props.parent.title}</div>
          </Link>
        </>

        <>
          <Arrowsvg />
          <Link
            to={props.first_child.src}
            className="text-sm font-medium cursor-pointer text-gray-700 hover:text-gray-600"
          >
            <div>{props.first_child.title}</div>
          </Link>
        </>

        {props.second_child != "" ? (
          <>
            <Arrowsvg />
            <Link
              to="#"
              className=" text-sm font-medium cursor-pointer text-gray-700 hover:text-gray-600"
            >
              <div>{secondChild}</div>
            </Link>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center ">
        <li className="mr-0">
          <Link
            to="/"
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-600"
          >
            <svg
              className="w-3 h-3 mr-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </Link>
        </li>
        {pathSegments.map((segment, index) => (
          <li key={index}>
            <div className="flex items-center m-0 p-0 ">
              {role == "admin" ? (
                <>
                  {navigation.admin.map((value, index) => {
                    let jsx = "";
                    if (value.src == "/" + segment) {
                      if (value.title != "Home") {
                        jsx = (
                          <BreadcrumbHelper
                            parent={value}
                            second_child={""}
                            first_child={""}
                            segment={segment}
                          />
                        );
                      }
                    }
                    if ("subMenus" in value) {
                      value.subMenus.map((sub_value, index) => {
                        if (sub_value.src == "/" + segment) {
                          if (value.title == "Home") {
                            jsx = (
                              <BreadcrumbHelper
                                parent={""}
                                second_child={""}
                                first_child={segment}
                                segment={""}
                              />
                            );
                          } else {
                            jsx = (
                              <BreadcrumbHelper
                                parent={value}
                                second_child={""}
                                first_child={sub_value}
                              />
                            );
                          }
                        }
                      });
                    }
                    if ("subMenus" in value) {
                      value.subMenus.map((sub_value, index) => {
                        if ("subMenus" in sub_value) {
                          sub_value.subMenus.map((sub_sub_value, index) => {
                            if (sub_sub_value.src == "/" + segment) {
                              jsx = (
                                <BreadcrumbHelper
                                  parent={value}
                                  first_child={sub_value}
                                  second_child={sub_sub_value}
                                />
                              );
                            }
                          });
                        }
                      });
                    }
                    return jsx;
                  })}
                </>
              ) : (
                ""
              )}
              {role == "primaryattorney" ? (
                <>
                  {navigation.primaryattorney.map((value, index) => {
                    let jsx = "";
                    if (value.src == "/" + segment) {
                      if (value.title != "Home") {
                        jsx = (
                          <BreadcrumbHelper
                            parent={value}
                            second_child={""}
                            first_child={""}
                          />
                        );
                      }
                    }
                    if ("subMenus" in value) {
                      value.subMenus.map((sub_value, index) => {
                        if (sub_value.src == "/" + segment) {
                          jsx = (
                            <BreadcrumbHelper
                              parent={value}
                              second_child={""}
                              first_child={sub_value}
                            />
                          );
                        }
                      });
                    }
                    if ("subMenus" in value) {
                      value.subMenus.map((sub_value, index) => {
                        if ("subMenus" in sub_value) {
                          sub_value.subMenus.map((sub_sub_value, index) => {
                            if (sub_sub_value.src == "/" + segment) {
                              jsx = (
                                <BreadcrumbHelper
                                  parent={value}
                                  first_child={sub_value}
                                  second_child={sub_sub_value}
                                />
                              );
                            }
                          });
                        }
                      });
                    }
                    return jsx;
                  })}
                </>
              ) : (
                ""
              )}
              {role == "attorney" ? (
                <>
                  {navigation.attorney.map((value, index) => {
                    let jsx = "";
                    if (value.src == "/" + segment) {
                      if (value.title != "Home") {
                        jsx = (
                          <BreadcrumbHelper
                            parent={value}
                            second_child={""}
                            first_child={""}
                          />
                        );
                      }
                    }
                    if ("subMenus" in value) {
                      value.subMenus.map((sub_value, index) => {
                        if (sub_value.src == "/" + segment) {
                          jsx = (
                            <BreadcrumbHelper
                              parent={value}
                              second_child={""}
                              first_child={sub_value}
                            />
                          );
                        }
                      });
                    }
                    if ("subMenus" in value) {
                      value.subMenus.map((sub_value, index) => {
                        if ("subMenus" in sub_value) {
                          sub_value.subMenus.map((sub_sub_value, index) => {
                            if (sub_sub_value.src == "/" + segment) {
                              jsx = (
                                <BreadcrumbHelper
                                  parent={value}
                                  first_child={sub_value}
                                  second_child={sub_sub_value}
                                />
                              );
                            }
                          });
                        }
                      });
                    }
                    return jsx;
                  })}
                </>
              ) : (
                ""
              )}
              {role == "client" ? (
                <>
                  {navigation.client.map((value, index) => {
                    let jsx = "";
                    if (value.src == "/" + segment) {
                      if (value.title != "Home") {
                        jsx = (
                          <BreadcrumbHelper
                            parent={value}
                            second_child={""}
                            first_child={""}
                          />
                        );
                      }
                    }
                    if ("subMenus" in value) {
                      value.subMenus.map((sub_value, index) => {
                        if (sub_value.src == "/" + segment) {
                          jsx = (
                            <BreadcrumbHelper
                              parent={value}
                              second_child={""}
                              first_child={sub_value}
                            />
                          );
                        }
                      });
                    }
                    if ("subMenus" in value) {
                      value.subMenus.map((sub_value, index) => {
                        if ("subMenus" in sub_value) {
                          sub_value.subMenus.map((sub_sub_value, index) => {
                            if (sub_sub_value.src == "/" + segment) {
                              jsx = (
                                <BreadcrumbHelper
                                  parent={value}
                                  first_child={sub_value}
                                  second_child={sub_sub_value}
                                />
                              );
                            }
                          });
                        }
                      });
                    }
                    return jsx;
                  })}
                </>
              ) : (
                ""
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
export default Breadcrumb;
