import Login from "../../Pages/Login";
import PublicAuth from "./PublicAuth";
import RequireAuth from "./RequiredAuth";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../../Components/Dashboard";
import ForgotPassword from "../../Pages/ForgotPassword";
import AddFirms from "../../Pages/AddFirms";
import ListFirms from "../../Pages/ListFirms";
import ProfilePage from "../../Pages/ProfilePage";
import AdminDashboard from "../../Pages/Dashboards/AdminDashboard";
import FirmDashboard from "../../Pages/Dashboards/FirmDashboard";
import AddAttorney from "../../Pages/AddAttorney";
import ViewFirm from "../../Pages/ViewFirm";
import ClientView from "../../Pages/ClientView";
import ClientDashboard from "../../Pages/Dashboards/ClientDashboard";
import AttorneyDashboard from "../../Pages/Dashboards/AttorneyDashboard";
import NotFoundPage from "../../Pages/NotFoundPage";
import UpdateFirms from "../../Pages/UpdateFirms";
import InvitationsPage from "../../Pages/InvitationsPage";
import ListAttorneys from "../../Pages/ListAttorneys";
import AttorneyProfilePage from "../../Pages/AttorneyProfilePage";
import WebformPageClient from "../../Pages/WebformPageClient";
import AddClients from "../../Pages/AddClients";
import ViewPendingClient from "../../Pages/ViewPendingClient";
import AttorneyPendingInvitations from "../../Pages/AttorneyPendingInvitations";
import FirmOverView from "../../Pages/FirmOverView";
import ClientOverView from "../../Pages/ClientOverView";
import ClientAttorneyView from "../../Pages/ClientAttorneyView";
import ListClients from "../../Pages/ListClients";
import PendingClients from "../../Pages/PendingClients";
import PendingInvitations from "../../Pages/PendingInvitations";
import ApprovedClients from "../../Pages/ApprovedClients";
import MissedPaymentClients from "../../Pages/MissedPaymentClients";
import PausedClients from "../../Pages/PausedClients";
import ManageHolidays from "../../Pages/ManageHolidays";
import DelinquentClients from "../../Pages/DelinquentClients";
import PreDelinquentClients from "../../Pages/PreDelinquentClients";
import FirmPayouts from "../../Pages/FirmPayouts";
import PayoutHistory from "../../Pages/PayoutHistory";
import EscrowOverView from "../../Pages/EscrowOverView";
import UpdateClients from "../../Pages/UpdateClients";
import ArchivedClients from "../../Pages/ArchivedClients";
import ClientContractsView from "../../Pages/ClientContractsView";
import EscrowHistory from "../../Pages/EscrowHistory";
import TransactionHistoryTable from "../../Pages/Tables/TransactionHistoryTable";
import AdminPaymentPage from "../../Pages/AdminPaymentPage";
import RejectedClients from "../../Pages/RejectedClients";
import ViewRejectedClient from "../../Pages/ViewRejectedClient";
import PastDelinquentClients from "../../Pages/PastDelinquentClients";
import DelinquentPayments from "../../Pages/DelinquentPayments";
import AdminDashboardDev from "../../Pages/Dashboards/AdminDashboardDev";
import ExpectedPayments from "../../Pages/ExpectedPayments";
import ClientExpectedTable from "../../Pages/Tables/ClientExpectedTable";
import ClientReceivedTable from "../../Pages/Tables/ClientReceivedTable";
import OnTimePaymentTable from "../../Pages/Tables/OnTimePaymentTable";
import DelayedPaymentTable from "../../Pages/Tables/DelayedPaymentTable";
import CompletedPaymentTable from "../../Pages/Tables/CompletedPaymentTable";
import FinancialReports from "../../Pages/FinancialReports";
import TotalEscrowBalanceTable from "../../Pages/Tables/TotalEscrowBalanceTable";
import EscrowPerformanceTable from "../../Pages/Tables/EscrowPerformanceTable";
import AuditLogTable from "../../Pages/Tables/AuditLogTable";
import AttorneyPayoutHistory from "../../Pages/AttorneyPayoutHistory";
import AttorneyAllPaymentsTable from "../../Pages/Tables/AttorneyAllPaymentsTable";
import AttorneyClientContractsTable from "../../Pages/Tables/AttorneyClientContractsTable";
import AttorneyTotalClientsTable from "../../Pages/Tables/AttorneyTotalClientsTable";

const Router = () => {
  let pathElementMapping = {
    //common

    "/dashboard": <Dashboard />,
    "/profile-page": <ProfilePage />,
    "/invitations": <InvitationsPage />,
    "/webform": <WebformPageClient />,

    // individual dashboards

    "/admin-home": <AdminDashboard />,
    "/admin-home-dev": <AdminDashboardDev />,
    "/firm-home": <FirmDashboard />,
    "/attorney-home": <AttorneyDashboard />,
    "/client-home": <ClientDashboard />,

    // overview

    "/firm-overview": <FirmOverView />,
    "/client-overview": <ClientOverView />,

    // major routes

    "/add-firm": <AddFirms />,
    "/list-firm": <ListFirms />,
    "/firm-payouts": <FirmPayouts />,
    "/payout-history": <PayoutHistory />,
    "/escrow-overview": <EscrowOverView />,
    "/view-firm": <ViewFirm />,
    "/update-firm": <UpdateFirms />,
    "/add-clients": <AddClients />,
    "/list-clients": <ListClients />,
    "/update-clients": <UpdateClients />,
    "/client-view": <ClientView />,
    "/delinquent-clients": <DelinquentClients />,
    "/predelinquent-clients": <PreDelinquentClients />,
    "/pastdelinquent-clients": <PastDelinquentClients />,
    "/add-attorney": <AddAttorney />,
    "/list-attorneys": <ListAttorneys />,
    "/attorney-profile": <AttorneyProfilePage />,
    "/pending-clients": <PendingClients />,
    "/rejected-clients": <RejectedClients />,
    "/pending-invitations": <PendingInvitations />,
    "/approved-clients": <ApprovedClients />,
    "/archived-clients": <ArchivedClients />,
    "/admin-payment-page": <AdminPaymentPage />,
    "/view-pending-client": <ViewPendingClient />,
    "/view-rejected-client": <ViewRejectedClient />,
    "/escrow-history": <EscrowHistory />,
    "/transaction-history": <TransactionHistoryTable />,
    "/delinquent-payments": <DelinquentPayments />,
    "/expected-payments": <ExpectedPayments />,
    "/attorney-pending-invitations": <AttorneyPendingInvitations />,
    "/client-attorney-view": <ClientAttorneyView />,
    "/client-contracts-view": <ClientContractsView />,
    "/paused-clients": <PausedClients />,
    "/missed-payment-clients": <MissedPaymentClients />,
    "/manage-holidays": <ManageHolidays />,
    "/client-total-expected": <ClientExpectedTable />,
    "/client-total-received": <ClientReceivedTable />,
    "/on-time-clients": <OnTimePaymentTable />,
    "/delayed-clients": <DelayedPaymentTable />,
    "/completed-clients": <CompletedPaymentTable />,
    "/financial-reports": <FinancialReports />,
    "/total-escrow-balance": <TotalEscrowBalanceTable />,
    "/firm-escrow-performance": <EscrowPerformanceTable />,
    "/attorney-payout-history": <AttorneyPayoutHistory />,
    "/attorney-all-payments": <AttorneyAllPaymentsTable />,
    "/attorney-client-contracts": <AttorneyClientContractsTable />,
    "/attorney-total-clients": <AttorneyTotalClientsTable />,
    "/audit-log": <AuditLogTable />,
  };
  let TheRoute = [];
  let keys = Object.keys(pathElementMapping);
  for (let e of keys) {
    TheRoute.push({
      path: e,
      element: pathElementMapping[e],
    });
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={
              <PublicAuth>
                <Login />
              </PublicAuth>
            }
          />
          <Route
            path="/forgot-pass"
            element={
              <PublicAuth>
                <ForgotPassword />
              </PublicAuth>
            }
          />

          <Route
            path="/invitations"
            element={
              <PublicAuth>
                <InvitationsPage />
              </PublicAuth>
            }
          />
          <Route
            path="/webform"
            element={
              <PublicAuth>
                <WebformPageClient />
              </PublicAuth>
            }
          />

          {TheRoute ? (
            <Route path="/" element={<Dashboard />}>
              {TheRoute.map(({ path, element }, key) => (
                <Route
                  path={path}
                  element={<RequireAuth path={path}>{element}</RequireAuth>}
                  key={key}
                />
              ))}
            </Route>
          ) : (
            <Navigate to="/login" replace />
          )}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
