import React from "react";
import RejectedClientsTable from "./Tables/RejectedClientsTable";

const RejectedClients = () => {
  return (
    <>
      <RejectedClientsTable />
    </>
  );
};

export default RejectedClients;
