import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { Dialog, RadioGroup, Switch, Transition } from "@headlessui/react";
import ModalComponent from "../Components/ModalComponent";
import {
  ArrowPathIcon,
  CurrencyDollarIcon,
  CursorArrowRaysIcon,
  HomeIcon,
  InformationCircleIcon,
  LockClosedIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import OTPInput from "react-otp-input";
import { setAuthPost } from "../utils/redux/slice/setAuthPostSlice";
import { setEmailSmsAuthPost } from "../utils/redux/slice/setEmailSmsAuthPostSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { editAttorneyProfilePut } from "../utils/redux/slice/editAttorneyProfileSlice";
import {
  MagnifyingGlass,
  Oval,
  ProgressBar,
  ThreeDots,
} from "react-loader-spinner";
import { getAttorneyProfileGet } from "../utils/redux/slice/getAttorneyProfileSlice";
import { domainUrl, logOutHandler } from "../utils/constants";
import axios from "axios";
import PendingClientsTable from "./Tables/PendingClientsTable";
import PendingInvitationsTable from "./Tables/PendingInvitationsTable";
import ApprovedClientsTable from "./Tables/ApprovedClientsTable";
import CurrencyFormat from "react-currency-format";
import ClientDataVersionTable from "./Tables/ClientDataVersionTable";
import LoginHistoryTable from "./Tables/LoginHistoryTable";
import AttorneyPayoutHistory from "./AttorneyPayoutHistory";
import AttorneyEscrowHistoryTable from "./Tables/AttorneyEscrowHistoryTable";

const AttorneyProfilePage = () => {
  const view_attorney_id = localStorage.getItem("view_attorney_id");
  const role = localStorage.getItem("role");
  const [attorneyData, setAttorneyData] = useState({});
  const [refreshData, setRefreshData] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (view_attorney_id) {
      console.log(view_attorney_id);
    } else {
      navigate(-1);
    }
  }, []);

  const getAttorneyProfileHandler = async () => {
    const res = await dispatch(getAttorneyProfileGet(navigate));
    if (res.payload) {
      setAttorneyData(res.payload);
      setRefreshData(!refreshData);
      console.log(res.payload);
      let attorneyProfile = {
        attorney_code: res.payload.attorney_code,
        accepted: res.payload.accepted,
        firmId: res.payload.firm.id,
        firm_name: res.payload.firm.firm_name,
        firm_code: res.payload.firm.firm_code,
        primary_attorney: res.payload.primary_attorney,
        userId: res.payload.user.id,
        attorney_id: res.payload.attorney_id,
        firm_cell_phone_number: res.payload.firm.cell_phone_number,
        firm_secondary_cell_phone_number:
          res.payload.firm.secondary_cell_phone_number,
        firm_email: res.payload.firm.email,
        first_name: res.payload.user.first_name,
        last_name: res.payload.user.last_name,
        email: res.payload.user.email,
        cell_phone_number: res.payload.user.cell_phone_number,
      };

      setProfileDetail({
        ...profileDetail,
        cell_phone_number: res.payload
          ? formatUSCell(res.payload.user.cell_phone_number)
          : "",
        email: res.payload ? res.payload.user.email : "",
        first_name: res.payload ? res.payload.user.first_name : "",
        last_name: res.payload ? res.payload.user.last_name : "",
      });

      localStorage.setItem("attorneyProf", JSON.stringify(attorneyProfile));

      console.log(res.payload);
    }
    if (res.error) {
      console.log(res.error.message);
      toast.error(res.error.message);
    }
  };

  const attorneyPageLoader = useSelector(
    (state) => state.getAttorneyProfileStorage.isLoading
  );

  const attorneyProf = JSON.parse(localStorage.getItem("attorneyProf"));

  useEffect(() => {
    if (view_attorney_id) {
      getAttorneyProfileHandler();
    } else {
      navigate(-1);
    }
    return () => {
      if (role == "admin" || role == "primaryattorney") {
        localStorage.removeItem("view_attorney_id");
        localStorage.removeItem("view_attorneyProf");
      }
      localStorage.removeItem("attorneyProf");
    };
  }, []);

  // const updateAttorneyDetail = () => {
  //   setProfileDetail({
  //     ...profileDetail,
  //     cell_phone_number: attorneyProf ? attorneyProf.cell_phone_number : "",
  //     email: attorneyProf ? attorneyProf.email : "",
  //     first_name: attorneyProf ? attorneyProf.first_name : "",
  //     last_name: attorneyProf ? attorneyProf.last_name : "",
  //   });
  // };

  // useEffect(() => {
  //   if (attorneyProf) {
  //     updateAttorneyDetail();
  //   }
  // }, [attorneyProf]);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const access_token = localStorage.getItem("access_token");

  const userProf = JSON.parse(localStorage.getItem("userProf"));

  const [toggle, setToggle] = useState(false);

  const profileRef = useRef(null);
  const basicRef = useRef(null);
  const firmRef = useRef(null);
  const payoutRef = useRef(null);
  const escrowRef = useRef(null);
  const pendingApproveRef = useRef(null);
  const pendingInviteRef = useRef(null);
  const approvedClientRef = useRef(null);
  const loginHistRef = useRef(null);

  const [typingTimeout, setTypingTimeout] = useState(0);
  const [profileDetail, setProfileDetail] = useState({
    cell_phone_number: "",
    email: "",
    first_name: "",
    last_name: "",
  });

  const [errorFlag, setErrorFlag] = useState({
    cell_phone_number: "",
    email: "",
    first_name: "",
    last_name: "",
  });

  const editAttorenyProfLoader = useSelector(
    (state) => state.editAttorneyProfileStorage.isLoading
  );

  const [profileDetailFlag, setProfileDetailFlag] = useState({
    cell_phone_number: true,
    email: true,
    first_name: true,
    last_name: true,
  });
  const formatAlphabetsWithSpace = (value) => {
    let formattedAlphabets;
    const { length } = value;

    // Filter non-alphabets and spaces
    const regex = () => value.replace(/[^a-zA-Z\s]+/g, "");

    // New validation
    if (length > 0) {
      formattedAlphabets = regex();
    }

    return formattedAlphabets;
  };

  const [editProfileFlag, setEditProfileFlag] = useState(false);

  const formatcell_phone_number = (value) => {
    let convertedNumber = value.replace(/\D/g, "");
    convertedNumber = convertedNumber.replace(/^1/, "");
    convertedNumber = convertedNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1 $2 $3"
    );
    console.log(convertedNumber.length);
    return convertedNumber;
  };

  function formatUSCell(usPhoneNumber) {
    // Remove any non-digit characters
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    // Check if the number has the expected length of 10 digits
    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    // Format the number in the original format and add +1 in front
    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  const filterNumberLength = (value, max) => {
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length > max) {
      return numericValue.slice(0, max);
    }
    return numericValue;
  };
  function formatAlphabetsAndSpaces(input) {
    // Use a regular expression to match only alphabets and spaces
    const regex = /[^a-zA-Z ]/g;
    // Replace any characters that don't match the regex with an empty string
    const formattedString = input.replace(regex, "");

    return formattedString;
  }
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email.length > 0 && regex.test(email);
  };

  const formHandler = (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setErrorFlag((prevErrorFlag) => ({
      ...prevErrorFlag,
      [e.target.name]: "",
    }));
    if (
      ["first_name", "last_name", "email", "cell_phone_number"].includes(
        e.target.name
      )
    ) {
      setProfileDetail((prevValue) => ({
        ...prevValue,
        [e.target.name]: ["first_name", "last_name", "city"].includes(
          e.target.name
        )
          ? formatAlphabetsAndSpaces(e.target.value)
          : e.target.name == "cell_phone_number"
          ? e.target.value
          : e.target.name == "zip_code"
          ? filterNumberLength(e.target.value, 5)
          : e.target.value,
      }));
    }
    setTypingTimeout(setTimeout(async () => validateFormFields(e), 300));
  };

  const validateFormFields = async (e) => {
    // // console.log("validating firm");
    if (["first_name", "last_name"].includes(e.target.name)) {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length > 30
            ? `Please enter a valid ${e.target.name} (1-30 characters)`
            : "",
      }));
    } else if (e.target.name == "email") {
      // console.log("validating firm email");

      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]: !validateEmail(e.target.value)
          ? "Enter a valid email."
          : "",
      }));
    } else if (e.target.name == "cell_phone_number") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          formatcell_phone_number(e.target.value).length != 12
            ? "Phone number must be 10 digit"
            : "",
      }));
    }
  };

  const setAsPrimaryHandler = async () => {
    try {
      const res = await axios.put(
        `${domainUrl}set-primary-attorney?attorney=${attorneyProf.attorney_id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setRefreshData(!refreshData);

        // console.log(res.data);
        let attorneyProfile = {
          attorney_code: res.data.attorney_code,
          accepted: res.data.accepted,
          firmId: res.data.firm.id,
          firm_name: res.data.firm.firm_name,
          firm_code: res.data.firm.firm_code,
          primary_attorney: res.data.primary_attorney,
          userId: res.data.user.id,
          attorney_id: res.data.attorney_id,
          firm_cell_phone_number: res.data.firm.cell_phone_number,
          firm_secondary_cell_phone_number:
            res.data.firm.secondary_cell_phone_number,
          firm_email: res.data.firm.email,
          first_name: res.data.user.first_name,
          last_name: res.data.user.last_name,
          email: res.data.user.email,
          cell_phone_number: res.data.user.cell_phone_number,
        };
        setProfileDetail({
          ...profileDetail,
          cell_phone_number: res.data
            ? formatUSCell(res.data.user.cell_phone_number)
            : "",
          email: res.data ? res.data.user.email : "",
          first_name: res.data ? res.data.user.first_name : "",
          last_name: res.data ? res.data.user.last_name : "",
        });
        localStorage.setItem("attorneyProf", JSON.stringify(attorneyProfile));
        setToggle(false);
        toast.success("Promoted to Primary Attorney", { id: 1 });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  useEffect(() => {
    if (toggle) {
      setAsPrimaryHandler();
    }
  }, [toggle]);

  const scrollToContainer = (containerRef) => {
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const isSubmitEditButtonDisabled = () => {
    const requiredFields = [
      "first_name",
      "last_name",
      "cell_phone_number",
      "email",
    ];

    for (const key of requiredFields) {
      console.log(key);
      if (!profileDetail.hasOwnProperty(key) || profileDetail[key] === "") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [key]: `*${key
            .replace("cell_phone_number", "phone number")
            .replace(/_/g, " ")
            .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
        }));
      }
    }

    return (
      requiredFields.some((field) => profileDetail[field] === "") ||
      requiredFields.some((field) => errorFlag[field] !== "")
    );
  };

  const submitEditAttorneyProfileHandler = async () => {
    const isAttorneyDetailError = isSubmitEditButtonDisabled();
    console.log(isAttorneyDetailError);

    if (isAttorneyDetailError) {
      toast.error("Complete the Fields", { id: 1 });
      return;
    }
    // console.log(attorneyProf.userId);
    const res = await dispatch(
      editAttorneyProfilePut(
        {
          cell_phone_number: formatcell_phone_number(
            profileDetail.cell_phone_number
          ),
          email: profileDetail.email,
          first_name: profileDetail.first_name,
          last_name: profileDetail.last_name,
        },
        navigate
      )
    );
    if (res.payload) {
      console.log(res.payload, typeof res.payload);
      setRefreshData(!refreshData);
      attorneyProf.first_name = res.payload.first_name;
      attorneyProf.last_name = res.payload.last_name;
      attorneyProf.email = res.payload.email;
      attorneyProf.cell_phone_number = res.payload.cell_phone_number;
      localStorage.setItem("attorneyProf", JSON.stringify(attorneyProf));
      setEditProfileFlag(false);
      toast.success("Successfully Updated");
    }
    if (res.error) {
      // multiple errors handling in single response
      let err = JSON.parse(res.error.message);
      Object.keys(err).forEach((key) => {
        const value = err[key];
        console.log(`${key}: ${value}`);
        toast.error(value);
      });
      // console.log(err);
    }
  };

  return (
    <div className="">
      {attorneyPageLoader ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="w-full flex  mt-5">
          {/* Left Container */}
          <div className="w-72 hidden h-screen bg-white overflow-y-auto lg:block sticky left-50 top-10">
            <div className="px-4">
              <div className="mt-8">
                <div className="border text-[#344767] shadow-lg rounded-lg py-5 px-4 h-50">
                  <div
                    className="my-4 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(basicRef)}
                  >
                    <div>
                      <InformationCircleIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div> Basic Info </div>
                  </div>
                  <div
                    className="my-4 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(firmRef)}
                  >
                    <div>
                      <HomeIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div> Firm Details </div>
                  </div>
                  <div
                    className="my-4 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(payoutRef)}
                  >
                    <div>
                      <CurrencyDollarIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div> Payout History </div>
                  </div>
                  <div
                    className="my-4 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(escrowRef)}
                  >
                    <div>
                      <CurrencyDollarIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div> Escrow History </div>
                  </div>
                  <div
                    className="my-4 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(pendingApproveRef)}
                  >
                    <div>
                      <UserGroupIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div>Pending Approval</div>
                  </div>
                  <div
                    className="my-4 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(pendingInviteRef)}
                  >
                    <div>
                      <UserGroupIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div>Pending Invitations</div>
                  </div>
                  <div
                    className="my-4 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(approvedClientRef)}
                  >
                    <div>
                      <UserGroupIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div>Approved Clients</div>
                  </div>
                  <div
                    className="my-4 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(loginHistRef)}
                  >
                    <div>
                      <CursorArrowRaysIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div>Login History</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-4/4 overflow-y-auto">
            <div className="p-4">
              <div className="md:w-full lg:w-full lg:p-0  flex items-center justify-center mb-3">
                <div className="border py-8 px-2   sm:w-screen bg-white  rounded-lg  lg:w-full shadow-lg w-96">
                  <div className="w-full flex justify-between items-center">
                    <div ref={profileRef}>
                      <div className="-m-1.5 flex items-center ">
                        <span className="flex items-center ">
                          <span
                            className="ml-4 text-lg font-semibold leading-6 text-blaCK"
                            aria-hidden="true"
                          >
                            {attorneyProf && (
                              <>
                                <span> {attorneyProf.first_name}</span>
                                <span className="ml-1">
                                  {attorneyProf.last_name}
                                </span>
                              </>
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="text-xs md:text-sm text-[#67758d] font-semibold mx-2">
                        Status:
                      </div>
                      <div className="inline-flex items-center gap-x-1 px-1 py-1 font-bold text-[#415271]">
                        {attorneyProf && attorneyProf.accepted ? (
                          <>
                            <svg
                              className="h-1.5 w-1.5 fill-green-500"
                              viewBox="0 0 6 6"
                              aria-hidden="true"
                            >
                              <circle cx={3} cy={3} r={3} />
                            </svg>
                            ACCEPTED
                          </>
                        ) : (
                          <>
                            <svg
                              className="h-1.5 w-1.5 fill-yellow-500"
                              viewBox="0 0 6 6"
                              aria-hidden="true"
                            >
                              <circle cx={3} cy={3} r={3} />
                            </svg>
                            PENDING
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div ref={basicRef} className="p-4">
              <div className=" md:w-full lg:w-full lg:p-0  flex items-center justify-center rounded-lg">
                <div className="border sm:w-screen bg-white  rounded-lg md:w-full lg:w-full p-8  shadow-lg w-96">
                  <h2 className="text-2xl font-semibold mb-4 text-[#344767]">
                    Basic Info
                  </h2>
                  <div className="w-full flex flex-col gap-5 py-2 text-[#8b90a7] sm:flex-row justify-between items-center">
                    <div className="self-start">
                      <span>Attorney Code:</span>{" "}
                      <span className="ml-2 font-bold text-black">
                        {attorneyProf && attorneyProf.attorney_code}
                      </span>
                    </div>
                    {role == "admin" ? (
                      <div className="self-end flex items-center">
                        {attorneyProf && attorneyProf.primary_attorney ? (
                          <div className="ml-2 font-bold inline-flex items-center">
                            Primary Attorney
                            <WorkspacePremiumIcon sx={{ color: "#00225c" }} />
                          </div>
                        ) : (
                          <div className="ml-2 font-bold inline-flex items-center">
                            <div className="inline-flex items-center">
                              <div className="text-xs md:text-sm text-[#67758d] font-semibold mx-2 ">
                                Set as Primary
                              </div>
                              <div>
                                <Switch
                                  checked={toggle}
                                  onChange={setToggle}
                                  className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full "
                                >
                                  <span
                                    aria-hidden="true"
                                    className="pointer-events-none absolute h-full w-full rounded-md bg-[#F0F2F5]"
                                  />
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      toggle ? "bg-[#1a3253]" : "bg-gray-200",
                                      "pointer-events-none absolute mx-auto h-4 w-9 rounded-full border border-gray-300 transition-colors duration-200 ease-in-out"
                                    )}
                                  />
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      toggle
                                        ? "translate-x-5"
                                        : "translate-x-0",
                                      "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-black border border-gray-400 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                                    )}
                                  />
                                </Switch>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <form className="mt-5">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          First Name
                        </label>
                        {!editProfileFlag ? (
                          <>
                            <div className="mt-2 block w-full  border-b-2 text-black">
                              {attorneyProf &&
                                (attorneyProf.first_name ? (
                                  <>
                                    <span>{attorneyProf.first_name}</span>
                                  </>
                                ) : (
                                  <>Nil</>
                                ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <input
                              onChange={(e) => formHandler(e)}
                              name="first_name"
                              value={profileDetail.first_name}
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                            />
                            <span className="text-red-500 text-xs">
                              {errorFlag?.first_name}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          Last Name
                        </label>
                        {!editProfileFlag ? (
                          <>
                            <div className="mt-2 block w-full  border-b-2 text-black">
                              {attorneyProf &&
                                (attorneyProf.last_name ? (
                                  <>
                                    <span>{attorneyProf.last_name}</span>
                                  </>
                                ) : (
                                  <>Nil</>
                                ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <input
                              onChange={(e) => formHandler(e)}
                              name="last_name"
                              value={profileDetail.last_name}
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                            />
                            <span className="text-red-500 text-xs">
                              {errorFlag?.last_name}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          Email
                        </label>
                        {!editProfileFlag ? (
                          <>
                            <div className="mt-2 block w-full  border-b-2 text-black">
                              {attorneyProf &&
                                (attorneyProf.email ? (
                                  <>
                                    <span>{attorneyProf.email}</span>
                                  </>
                                ) : (
                                  <>Nil</>
                                ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <input
                              type="email"
                              onChange={(e) => formHandler(e)}
                              name="email"
                              placeholder="example@willgibbins.com"
                              value={profileDetail.email}
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                            />
                            <span className="text-red-500 text-xs">
                              {errorFlag?.email}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="mb-4">
                        <label
                          onClick={() => {
                            console.log(profileDetail.cell_phone_number);
                          }}
                          className="block font-medium text-[#8b90a7]"
                        >
                          Primary Phone
                        </label>
                        {!editProfileFlag ? (
                          <>
                            <div className="mt-2 block w-full  border-b-2 text-black">
                              {attorneyProf &&
                                (attorneyProf.cell_phone_number ? (
                                  <>
                                    <span>
                                      {formatUSCell(
                                        attorneyProf.cell_phone_number
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>Nil</>
                                ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="w-full flex items-center">
                              <div className="w-full">
                                <CurrencyFormat
                                  name="cell_phone_number"
                                  value={profileDetail.cell_phone_number}
                                  onChange={(e) => formHandler(e)}
                                  className="block w-full pl-3  mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                  format="+1 (###) ###-####"
                                  mask="_"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag?.cell_phone_number}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="w-full mt-5 flex justify-end items-center">
                      {!editProfileFlag ? (
                        <>
                          <div className="">
                            <button
                              type="button"
                              onClick={() => {
                                setEditProfileFlag(true);
                              }}
                              className="bg-[#272729] text-white px-4 py-2 rounded-lg hover:bg-[#172729] focus:outline-none focus:bg-[#272729]"
                            >
                              EDIT PROFILE
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          {editAttorenyProfLoader ? (
                            <div className="w-full flex justify-center items-center">
                              <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#1a3253"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <div className="flex gap-5">
                              <button
                                type="button"
                                onClick={() =>
                                  submitEditAttorneyProfileHandler()
                                }
                                className={`text-white px-4 py-2 rounded-lg bg-[#272729] hover:bg-[#172729] focus:outline-none focus:bg-[#272729]`}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setEditProfileFlag(false);
                                  setProfileDetail((prevState) => ({
                                    ...prevState,
                                    first_name: attorneyProf.first_name,
                                    last_name: attorneyProf.last_name,
                                    email: attorneyProf.email,
                                    cell_phone_number: formatUSCell(
                                      attorneyProf.cell_phone_number
                                    ),
                                  }));
                                  setErrorFlag({
                                    first_name: "",
                                    last_name: "",
                                    email: "",
                                    cell_phone_number: "",
                                  });
                                }}
                                className="bg-[#272729] text-white px-4 py-2 rounded-lg hover:bg-[#172729] focus:outline-none focus:bg-[#272729]"
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div ref={firmRef} className="p-4">
              <div className=" md:w-full lg:w-full lg:p-0  flex items-center justify-center rounded-lg">
                <div className="border sm:w-screen bg-white  rounded-lg md:w-full lg:w-full p-8  shadow-lg w-96">
                  <h2 className="text-2xl font-semibold mb-4 text-[#344767]">
                    Firm Details
                  </h2>
                  <form className="mt-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          Firm Name
                        </label>

                        <>
                          <div className="inline-flex items-center gap-x-3 mt-2 block w-full  border-b-2 text-black">
                            {attorneyProf && (
                              <>
                                <span>{attorneyProf.firm_name}</span>
                                <span>{`[${attorneyProf.firm_code}]`}</span>
                              </>
                            )}
                          </div>
                        </>
                      </div>
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          Email
                        </label>

                        <>
                          <div className="mt-2 block w-full  border-b-2 text-black">
                            {attorneyProf && <>{attorneyProf.firm_email}</>}
                          </div>
                        </>
                      </div>
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          Primary Phone
                        </label>

                        <>
                          <div className="mt-2 block w-full  border-b-2 text-black">
                            {attorneyProf && (
                              <>
                                {formatUSCell(
                                  attorneyProf.firm_cell_phone_number
                                )}
                              </>
                            )}
                          </div>
                        </>
                      </div>
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          Seconday Phone
                        </label>

                        <>
                          <div className="mt-2 block w-full  border-b-2 text-black">
                            {attorneyProf && (
                              <>
                                {formatUSCell(
                                  attorneyProf.firm_secondary_cell_phone_number
                                )}
                              </>
                            )}
                          </div>
                        </>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {role == "admin" && attorneyData?.attorney_id ? (
              <div ref={payoutRef}>
                <AttorneyPayoutHistory attorneyData={attorneyData} />
              </div>
            ) : (
              ""
            )}

            {role == "admin" && attorneyData?.attorney_id ? (
              <div ref={escrowRef}>
                <AttorneyEscrowHistoryTable attorneyData={attorneyData} />
              </div>
            ) : (
              ""
            )}
            <div ref={pendingApproveRef}>
              <PendingClientsTable />
            </div>
            <div ref={pendingInviteRef}>
              <PendingInvitationsTable />
            </div>
            <div ref={approvedClientRef}>
              <ApprovedClientsTable />
            </div>
            {role == "admin" && attorneyData ? (
              <div ref={loginHistRef}>
                <LoginHistoryTable clientData={attorneyData} />
              </div>
            ) : (
              ""
            )}
            {role == "admin" && attorneyData ? (
              <ClientDataVersionTable
                clientData={attorneyData}
                refetchVersion={refreshData}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AttorneyProfilePage;
