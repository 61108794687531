import React from "react";
import { useNavigate } from "react-router-dom/dist";
import { useState } from "react";
import { useEffect } from "react";

import { domainUrl, logOutHandler } from "../utils/constants";
import { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { Oval, Hourglass } from "react-loader-spinner";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { Email as EmailIcon } from "@mui/icons-material";

import axios from "axios";
import toast from "react-hot-toast";

const AttorneyPendingInvitations = () => {
  //data and fetching state
  const [preview, setPreview] = useState(false);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnName, setColumnName] = useState({
    first_name: true,
    lst_name: true,
    email: true,
    cell_phone_number: true,
  });

  const access_token = localStorage.getItem("access_token");

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();
  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [invitationLoaders, setInvitationLoaders] = useState({});

  const [tableRefetch, setTableRefetch] = useState(false);

  function formatUSCell(usPhoneNumber) {
    // Remove any non-digit characters
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    // Check if the number has the expected length of 10 digits
    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    // Format the number in the original format and add +1 in front
    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  const resendInvitation = async (id) => {
    try {
      setInvitationLoaders({ ...invitationLoaders, [id]: true });
      const res = await axios.post(
        `${domainUrl}invitations?id=${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setInvitationLoaders({ ...invitationLoaders, [id]: false });
        toast.success("Invitation Send", { id: 1 });
        setTableRefetch(!tableRefetch);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setInvitationLoaders({ ...invitationLoaders, [id]: false });
      toast.error("Something went wrong", { id: 1 });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log(columnName);
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL("invitations", `${domainUrl}`);
      url.searchParams.set("status", "pending");
      url.searchParams.set("role", "attorney");

      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;
        console.log("###", data);
        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);

        // setRowCount(Math.ceil(data.count / pagination.pageSize));
        // setRowCount(5);
        // Math.ceil(totalCount / pagination.pageSize)
        // setTotalCount(data.count);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    tableRefetch,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "invitations",
        header: "",
        columns: [
          {
            id: "invited_user__first_name",
            header: "Attorney Name",
            accessorFn: (row) => `${row.user.first_name} ${row.user.last_name}`,
            accessorKey: "first_name",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "invited_user__email",
            header: "Email",
            accessorFn: (row) => `${row.user.email}`,
            accessorKey: "email",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "invited_user__cell_phone_number",
            header: "Primary Phone",
            accessorFn: (row) => `${formatUSCell(row.user.cell_phone_number)}`,
            accessorKey: "cell_phone_number",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "accepted",
            header: "Invitation Status",
            enableColumnActions: false,
            accessorFn: (row) => `${row.accepted ? "ACCEPTED" : "PENDING"}`,
            accessorKey: "accepted",
            size: 30,
          },
          {
            accessorFn: (row) => `${row.send_count} times`,
            enableColumnActions: false,
            enableSorting: false,
            id: "send_count",
            header: "Send Count",
            accessorKey: "send_count",
            size: 30,
          },
          // {
          //   accessorFn: (row) => `${row.visit_count} times`,
          //   enableColumnActions: false,
          //   enableSorting: false,
          //   id: "visit_count",
          //   header: "Link Visited",
          //   accessorKey: "visit_count",
          //   size: 30,
          // },
        ],
      },
    ],
    []
  );

  const [rowSelection, setRowSelection] = useState({});
  const [bulkStatus, setBulkStatus] = useState(false);
  const [bulkInvitationLoaders, setBulkInvitationLoaders] = useState(false);

  useEffect(() => {
    const length = Object.keys(rowSelection).length;
    console.log("JSON object length: " + length);
    if (length >= 1) {
      setBulkStatus(true);
    } else {
      setBulkStatus(false);
    }
  }, [rowSelection]);

  const sendBulk = async () => {
    try {
      let ChoosenRows = [];
      let keys = Object.keys(rowSelection);
      keys.forEach((value, index) => {
        if (rowSelection[value] == true) {
          ChoosenRows.push(value);
        }
      });
      console.log(ChoosenRows);

      let url = "";
      if (ChoosenRows.length > 1) {
        url = `${domainUrl}invitations?ids=${ChoosenRows}`;
      } else {
        url = `${domainUrl}invitations?id=${ChoosenRows}`;
      }
      setBulkInvitationLoaders(true);
      const res = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setBulkInvitationLoaders(false);
        toast.success("Invitation Send", { id: 1 });
        // if(ChoosenRows.length > 1){
        setRowSelection({});
        // }
        setTableRefetch(!tableRefetch);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setBulkInvitationLoaders(false);
    }
  };


  return (
    <div className="w-full">
      <div>
        <main className="py-10">
          <div className="mx-5">
            <h2
              style={{
                color: "#1a3253",
              }}
            >
              Pending Invitations
            </h2>
            <div className="text-base text-[#9ca0b3] my-2">
              List of Attorneys with pending invitations
            </div>
            <div className="p-1 border rounded-lg">
              <MaterialReactTable
                enableTopToolbar={true}
                enableStickyHeader
                muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                // enableColumnResizing
                // columnResizeMode="onEnd"
                // ############################################################
                enableRowSelection
                renderTopToolbarCustomActions={() => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        {bulkInvitationLoaders ? (
                          <button
                            className={`rounded text-white p-2 text-sm bg-blue-800`}
                          >
                            <div className="w-full flex  items-center gap-2">
                              <div>Send Invitation</div>
                              <div>
                                <Oval
                                  height={20}
                                  width={20}
                                  color="#ffff"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#ffff"
                                  strokeWidth={2}
                                  strokeWidthSecondary={2}
                                />
                              </div>
                            </div>
                          </button>
                        ) : (
                          <button
                            onClick={sendBulk}
                            disabled={!bulkStatus}
                            className={`rounded text-white p-2 text-sm ${
                              bulkStatus
                                ? "bg-blue-600 hover:bg-blue-800"
                                : "bg-blue-300"
                            }`}
                          >
                            <div className="w-full flex  items-center gap-2">
                              <div>Send Invitation</div>
                            </div>
                          </button>
                        )}
                      </div>
                    </>
                  );
                }}
                // getRowId={(row) => row.userId} //give each row a more useful id
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                // state={{ rowSelection }} //pass our managed row selection state to the table to use
                // ######################################################################
                muiTableBodyProps={{
                  sx: () => ({
                    "& tr:nth-child(odd)": {
                      backgroundColor: "#f8f9fa",
                    },
                    "& tr:nth-child(even)": {
                      backgroundColor: "#ffffff", // Set the background color for even rows
                    },
                  }),
                }}
                muiTablePaperProps={{
                  sx: {
                    maxWidth: "full",
                    // m: 'auto'
                  },
                  elevation: 0,
                }}
                muiTableHeadCellProps={{
                  sx: (theme) => ({
                    // background: "rgba(52, 210, 235, 0.1)",
                    // borderRight: "1px solid rgba(224,224,224,1)",
                    color: theme.palette.text.primary,
                  }),
                }}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableColumnFilters={true}
                rowNumberMode="static"
                muiTablePaginationProps={{
                  rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25],
                  showFirstButton: false,
                  showLastButton: false,
                }}
                columns={columns}
                data={data}
                enableRowActions
                positionActionsColumn="last"
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "Resend", //change header text
                    // size: 300, //make actions column wider
                  },
                }}
                renderRowActions={({ row }) => (
                  <>
                    {invitationLoaders[row.id] ? (
                      <IconButton>
                        <Hourglass
                          visible={true}
                          height="25"
                          width="25"
                          ariaLabel="hourglass-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          colors={["#306cce", "#72a1ed"]}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          resendInvitation(row.original.id);
                        }}
                      >
                        <EmailIcon
                          sx={{
                            color: "black",
                          }}
                        />
                      </IconButton>
                    )}
                  </>
                )}
                muiTableBodyRowProps={({ row }) => ({
                  //implement row selection click events manually

                  // selected: rowSelection[row.id],
                  sx: {
                    cursor: "pointer",
                  },
                })}
                getRowId={(row) => row.id}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                }}
                // manualFiltering
                manualPagination
                manualSorting
                muiToolbaralertBannerProps={
                  isError
                    ? {
                        color: "error",
                        children: "Error loading data",
                      }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setSearch}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                // rowCount={Math.ceil(totalCount / pagination.pageSize)}
                state={{
                  // columnFilters,
                  // search,
                  isLoading,
                  rowSelection,
                  pagination,
                  showalertBanner: isError,
                  showProgressBars: isRefetching,
                  sorting,
                  columnVisibility: columnName,
                }}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AttorneyPendingInvitations;
