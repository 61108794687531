import { useEffect, useMemo, useState } from "react";
import ExportImg from "../../assets/img/excel-img.png";

import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Hourglass } from "react-loader-spinner";
import { Avatar, Button, IconButton } from "@mui/material";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { putArchiveClientPut } from "../../utils/redux/slice/putArchiveClientSlice";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ApprovedClientsTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //table state
  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([{ id: "approved_time", desc: true }]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [archiveLoaders, setArchiveLoaders] = useState({});
  const [refetchData, setRefetchData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const view_firm_id = localStorage.getItem("view_firm_id");

      const view_attorney_id = localStorage.getItem("view_attorney_id");
      console.log("VIEW_FIRM", view_firm_id);
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(
        // 'event-reports','http://191.96.1.252:8000/',
        "clients",
        `${domainUrl}`
      );
      url.searchParams.set("status", `approved`);
      // url.searchParams.set("role", `client`);
      if (view_attorney_id) {
        url.searchParams.set("attorney", `${view_attorney_id}`);
      } else {
        if (view_firm_id) {
          console.log("VIEW_FIRM DETAILS>>>>>>>>");
          url.searchParams.set("firm", `${view_firm_id}`);
        }
      }
      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("table", `true`);

      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;
        console.log("###", data);
        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);

        // setRowCount(Math.ceil(data.count / pagination.pageSize));
        // setRowCount(5);
        // Math.ceil(totalCount / pagination.pageSize)
        // setTotalCount(data.count);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refetchData,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "clients",
        header: "",
        columns: [
          {
            accessorFn: (row) => `${row.client_code} `,
            id: "client_code",
            header: "Client Code",
            accessorKey: "client_code",
            enableColumnActions: false,

            size: 30,
          },
          {
            accessorFn: (row) =>
              `${row.user.first_name} ${row.user.last_name} `,
            id: "user_model__first_name",
            header: "Client Name",
            accessorKey: "first_name",
            enableColumnActions: false,

            size: 30,
          },
          {
            id: "user_model__email",
            header: "Email",
            accessorFn: (row) => `${row.user.email}`,
            accessorKey: "email",
            enableColumnActions: false,

            size: 30,
          },
          {
            id: "user_model__cell_phone_number",
            header: "Primary Phone",
            accessorFn: (row) => `${formatUSCell(row.user.cell_phone_number)}`,
            accessorKey: "cell_phone_number",
            enableColumnActions: false,

            size: 30,
          },
          {
            id: "contracts__case_number",
            header: "Case Number",
            accessorFn: (row) => `${row.contracts[0].case_number}`,
            accessorKey: "case_number",
            enableColumnActions: false,

            size: 30,
          },
          {
            id: "approved_time",
            header: "Approved Date",
            accessorFn: (row) => `${formatDate(row.approved_time)}`,
            accessorKey: "approved_time",
            muiTableHeadCellFilterTextFieldProps: { placeholder: "YYYY-MM-DD" },
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "status",
            header: "Status",
            accessorFn: (row) => `${row.status.toUpperCase()}`,
            accessorKey: "status",
            enableColumnActions: false,

            size: 30,
          },
        ],
      },
    ],
    []
  );
  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  function formatUSCell(usPhoneNumber) {
    // Remove any non-digit characters
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    // Check if the number has the expected length of 10 digits
    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    // Format the number in the original format and add +1 in front
    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  const archiveClient = async (id) => {
    setArchiveLoaders({ ...archiveLoaders, [id]: true });

    const res = await dispatch(
      putArchiveClientPut({ status: "archive", id: id }, navigate)
    );
    if (res.payload) {
      console.log(res.payload);
      setArchiveLoaders({ ...archiveLoaders, [id]: false });
      setRefetchData(!refetchData);
      toast.success(res.payload.message, { id: 1 });
    }
    if (res.error) {
      console.log(res.error);
      setArchiveLoaders({ ...archiveLoaders, [id]: false });
      toast.error(res.error.message, { id: 1 });
    }
  };

  const exportFileHandler = async (id) => {
    const url = new URL(
      // 'event-reports','http://191.96.1.252:8000/',
      "clients",
      `${domainUrl}`
    );
    url.searchParams.set("status", "approved");
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        console.log(response);
        console.log(response.headers.get("content-type"));
        console.log(typeof response.headers.get("content-type"));
        if (response.headers.get("content-type") == "application/json") {
          // toast
          console.log("inside json if");
          console.log(response.data.scheduled);
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Approved Clients_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Approved Clients
              </h2>
              <div className="text-base text-[#9ca0b3] my-2"></div>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  renderTopToolbarCustomActions={({ table }) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        {role == "admin" ? (
                          <Button
                            disabled={false}
                            startIcon={<Avatar src={ExportImg} />}
                            onClick={() => {
                              // Implement export logic for the selected rows (10 or 30) here
                              console.log("Exporting as Excel");
                              exportFileHandler();
                            }}
                            variant="contained"
                            sx={{
                              bgcolor: "#127c44",
                              "&:hover": {
                                bgcolor: "#127c44",
                              },
                              height: "25px",
                            }}
                          >
                            Export
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  // enableColumnResizing
                  // columnResizeMode="onEnd"
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff", // Set the background color for even rows
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                      // m: 'auto'
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      // background: "rgba(52, 210, 235, 0.1)",
                      // borderRight: "1px solid rgba(224,224,224,1)",
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  enableRowActions
                  positionActionsColumn="last"
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: role == "admin" ? "Archive" : null, //change header text
                    },
                  }}
                  renderRowActions={({ row }) => (
                    <>
                      {role == "admin" ? (
                        <>
                          {archiveLoaders[row.original.id] ? (
                            <IconButton>
                              <Hourglass
                                visible={true}
                                height="25"
                                width="25"
                                ariaLabel="hourglass-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                colors={["#306cce", "#72a1ed"]}
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                archiveClient(row.original.id);
                              }}
                            >
                              <ArchiveIcon
                                sx={{
                                  color: "black",
                                }}
                              />
                            </IconButton>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  muiTableBodyRowProps={({ row }) => ({
                    //implement row selection click events manually
                    onClick: () => {
                      if (data.length) {
                        console.log(row.original.id);
                        console.log(data[row.id - 1]);
                        console.log("Clicked on ", row.id, data[row.id]);
                        //alert();
                        localStorage.setItem("view_client_id", row.original.id);
                        navigate(`/client-view`);
                      }
                    },
                    // selected: rowSelection[row.id],
                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  // manualFiltering
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  // rowCount={Math.ceil(totalCount / pagination.pageSize)}
                  state={{
                    // columnFilters,
                    // search,
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                    // columnVisibility: columnName,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
