import React from "react";
import PastDelinquentClientsTable from "./Tables/PastDelinquentClientsTable";

const PastDelinquentClients = () => {
  return (
    <>
      <PastDelinquentClientsTable />
    </>
  );
};

export default PastDelinquentClients;
