import React from "react";
import ExpectedPaymentsTable from "./Tables/ExpectedPaymentsTable";

const ExpectedPayments = () => {
  return (
    <>
      <ExpectedPaymentsTable />
    </>
  );
};

export default ExpectedPayments;
