import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./slice/loginSlice";
import otpSlice from "./slice/otpSlice";
import changePassSlice from "./slice/changePassSlice";
import setAuthGetSlice from "./slice/setAuthGetSlice";
import setAuthPostSlice from "./slice/setAuthPostSlice";
import setEmailSmsAuthPostSlice from "./slice/setEmailSmsAuthPostSlice";
import forgotEmailSlice from "./slice/forgotEmailSlice";
import forgotOtpSlice from "./slice/forgotOtpSlice";
import forgotPassSlice from "./slice/forgotPassSlice";
import forgotResetPassSlice from "./slice/forgotResetPassSlice";
import addFirmSlice from "./slice/addFirmSlice";
import editProfileSlice from "./slice/editProfileSlice";
import postFirmNameSlice from "./slice/postFirmNameSlice";
import getEditFirmSlice from "./slice/getEditFirmSlice";
import putEditFirmSlice from "./slice/putEditFirmSlice";
import postPassSetupSlice from "./slice/postPassSetup";
import getAttorneyProfileSlice from "./slice/getAttorneyProfileSlice";
import editAttorneyProfileSlice from "./slice/editAttorneyProfileSlice";
import getFirmProfileSlice from "./slice/getFirmProfileSlice";
import postCheckInvitationsSlice from "./slice/postCheckInvitationsSlice";
import postFNLNameSlice from "./slice/postFNLNameSlice";
import postFirmDataSlice from "./slice/postFirmDataSlice";
import addSingleAttorneySlice from "./slice/addSingleAttorneySlice";
import postMultipleFNLNameSlice from "./slice/postMultipleFNLNameSlice";
import addFirmEscrowAdjustmentSlice from "./slice/addFirmEscrowAdjustmentSlice";
import getAddFirmDraftSlice from "./slice/getAddFirmDraftSlice";
import deleteAddFirmDraftSlice from "./slice/deleteAddFirmDraftSlice";
import postFNLClientNameSlice from "./slice/postFNLClientNameSlice";
import deleteSingleMultipleAttorneySlice from "./slice/deleteSingleMultipleAttorneySlice";
import addClientSlice from "./slice/addClientSlice";
import putTwoFactorSlice from "./slice/putTwoFactorSlice";
import pauseClientSlice from "./slice/pauseClientSlice";
import putDelinquentClientSlice from "./slice/putDelinquentClientSlice";
import putMarkAsPaidSlice from "./slice/putMarkAsPaidSlice";
import postRefundSlice from "./slice/postRefundSlice";
import putArchiveClientSlice from "./slice/putArchiveClientSlice";
import putUnArchiveClientSlice from "./slice/putUnArchiveClientSlice";
import getCheckWebFormSlice from "./slice/getCheckWebFormSlice";
import updateClientSlice from "./slice/updateClientSlice";
import editClientProfileSlice from "./slice/editClientProfileSlice";

const store = configureStore({
  reducer: {
    loginStorage: loginSlice,
    otpStorage: otpSlice,
    changePassStorage: changePassSlice,
    setAuthGetStorage: setAuthGetSlice,
    setAuthPostStorage: setAuthPostSlice,
    setEmailSmsAuthPostStorage: setEmailSmsAuthPostSlice,
    forgotEmailStorage: forgotEmailSlice,
    forgotOtpStorage: forgotOtpSlice,
    forgotPassStorage: forgotPassSlice,
    forgotResetPassStorage: forgotResetPassSlice,
    addFirmStorage: addFirmSlice,
    editProfileStorage: editProfileSlice,
    postFirmNameStorage: postFirmNameSlice,
    getEditFirmStorage: getEditFirmSlice,
    putEditFirmStorage: putEditFirmSlice,
    postPassSetupStorage: postPassSetupSlice,
    getAttorneyProfileStorage: getAttorneyProfileSlice,
    editAttorneyProfileStorage: editAttorneyProfileSlice,
    getFirmProfileStorage: getFirmProfileSlice,
    postCheckInvitationsStorage: postCheckInvitationsSlice,
    postFNLNameStorage: postFNLNameSlice,
    postMultipleFNLNameStorage: postMultipleFNLNameSlice, // for mutiple attorneys
    postFirmDataStorage: postFirmDataSlice,
    addSingleAttorneyStorage: addSingleAttorneySlice,
    addFirmEscrowAdjustmentStorage: addFirmEscrowAdjustmentSlice,
    getAddFirmDraftStorage: getAddFirmDraftSlice,
    deleteAddFirmDraftStorage: deleteAddFirmDraftSlice,
    postFNLClientNameStorage: postFNLClientNameSlice,
    deleteSingleMultipleAttorneyStorage: deleteSingleMultipleAttorneySlice,
    addClientStorage: addClientSlice,
    putTwoFactorStorage: putTwoFactorSlice,
    pauseClientStorage: pauseClientSlice,
    putDelinquentClientStorage: putDelinquentClientSlice,
    putArchiveClientStorage: putArchiveClientSlice,
    putUnArchiveClientStorage: putUnArchiveClientSlice,
    putMarkAsPaidStorage: putMarkAsPaidSlice,
    postRefundStorage: postRefundSlice,
    getCheckWebFormStorage: getCheckWebFormSlice,
    updateClientStorage: updateClientSlice,
    editClientProfileStorage: editClientProfileSlice
  },
});

export default store;
