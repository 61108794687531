import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../constants";
import toast from "react-hot-toast";

export const forgotResetPassPost = createAsyncThunk(
  "forgotResetPassPost",
  async (data,thunkAPI) => {
    try {
      const access_token = localStorage.getItem("access_token");

      const res = await axios.put(
        `${domainUrl}reset-password-with-token`,
        data
      );
      console.log("this is login res", res.data);
      console.log(access_token);
      return res.data;
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        const navigate = thunkAPI.extra.navigateCallback;
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      } else {
        throw JSON.stringify(error.response.data);
      }
    }
  }
);

const forgotResetPassSlice = createSlice({
  name: "forgotResetPassStorage",
  initialState: { data: [], isLoading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgotResetPassPost.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(forgotResetPassPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(forgotResetPassPost.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const {} = forgotResetPassSlice.actions;
export default forgotResetPassSlice.reducer;
