import { useEffect, useMemo, useState } from "react";

import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import toast from "react-hot-toast";
import { Avatar, Button } from "@mui/material";
import ExportImg from "../../assets/img/excel-img.png";
import axios from "axios";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EscrowDashEscrowHistoryTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const access_token = localStorage.getItem("access_token");

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();
  //table state
  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };

  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(
        // 'event-reports','http://191.96.1.252:8000/',
        "escrow-history",
        `${domainUrl}`
      );

      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("table", `true`);
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;
        console.log("###", data);
        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);

        // setRowCount(Math.ceil(data.count / pagination.pageSize));
        // setRowCount(5);
        // Math.ceil(totalCount / pagination.pageSize)
        // setTotalCount(data.count);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "clients",
        header: "",
        columns: [
          {
            accessorFn: (row) => `${row.firm.firm_name} `,
            id: "contract__attorney__firm__firm_name",
            header: "Firm Name",
            accessorKey: "firm_name",
            enableColumnActions: false,
            // size: 30,
          },
          {
            accessorFn: (row) =>
              `${row.attorney.user.first_name} ${row.attorney.user.last_name} `,
            id: "contract__attorney__user_model__first_name",
            header: "Attorney Name",
            accessorKey: "first_name",
            enableColumnActions: false,
            // size: 30,
          },
          {
            accessorFn: (row) =>
              `${row.client.user.first_name} ${row.client.user.last_name} `,
            id: "contract__client__user_model__first_name",
            header: "Client Name",
            accessorKey: "first_name",
            enableColumnActions: false,
            // size: 30,
          },

          {
            accessorFn: (row) => `${formatUSD(row?.transaction?.amount_paid)} `,
            id: "transaction__amount_paid",
            header: "Amount Paid",
            accessorKey: "amount_paid",
            enableColumnActions: false,
            // size: 30,
          },
          {
            accessorFn: (row) => `${formatUSD(row?.transaction?.late_fees)} `,
            id: "transaction__late_fees",
            header: "Late Fee Deducted",
            accessorKey: "late_fees",
            enableColumnActions: false,
            // size: 30,
          },

          {
            accessorFn: (row) => `${formatUSD(row?.transaction?.escrow)} `,
            id: "transaction__escrow",
            header: "Escrow to Add",
            accessorKey: "escrow",
            enableColumnActions: false,
            // size: 30,
          },

          {
            id: "escrow_actual",
            header: "Current Escrow Balance",
            accessorFn: (row) => `${formatUSD(row?.escrow_actual)}`,
            accessorKey: "escrow_actual",
            enableColumnActions: false,
            // size: 30,
          },
          // {
          //   id: "escrow_withheld",
          //   header: "Current Escrow Withheld",
          //   accessorFn: (row) => `${formatUSD(row?.escrow_withheld)}`,
          //   accessorKey: "escrow_withheld",
          //   enableColumnActions: false,
          //   // size: 30,
          // },

          {
            id: "created_at",
            header: "Created Date",
            accessorFn: (row) => `${formatDate(row?.created_at)}`,
            accessorKey: "created_at",
            enableColumnActions: false,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "YYYY-MM-DD" },
            // size: 30,
          },
          {
            accessorFn: (row) =>
              `${
                row.transaction.transaction_id
                  ? row.transaction.transaction_id
                  : " -"
              } `,
            id: "transaction__transaction_id",
            header: "Transaction ID",
            accessorKey: "transaction_id",
            enableColumnActions: false,
            // muiTableHeadCellProps: {
            //   align: "center",
            // },
            // muiTableBodyCellProps: {
            //   align: "center",
            // },
            // size: 30,
          },
          {
            accessorFn: (row) => `${row.transaction.type.toUpperCase()} `,
            id: "transaction__type",
            header: "Transaction Type",
            accessorKey: "type",
            enableColumnActions: false,
            // size: 30,
          },
        ],
      },
    ],
    []
  );
  const exportFileHandler = async (id) => {
    const url = new URL(
      // 'event-reports','http://191.96.1.252:8000/',
      "escrow-history",
      `${domainUrl}`
    );
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        console.log(response);
        console.log(response.headers.get("content-type"));
        console.log(typeof response.headers.get("content-type"));
        if (response.headers.get("content-type") == "application/json") {
          // toast
          console.log("inside json if");
          console.log(response.data.scheduled);
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Escrow History_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Escrow History
              </h2>
              <div className="text-base text-[#9ca0b3] my-2"></div>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  renderTopToolbarCustomActions={({ table }) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          disabled={false}
                          startIcon={<Avatar src={ExportImg} />}
                          onClick={() => {
                            // Implement export logic for the selected rows (10 or 30) here
                            console.log("Exporting as Excel");
                            exportFileHandler();
                          }}
                          variant="contained"
                          sx={{
                            bgcolor: "#127c44",
                            "&:hover": {
                              bgcolor: "#127c44",
                            },
                            height: "25px",
                          }}
                        >
                          Export
                        </Button>
                      </div>
                    );
                  }}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  // enableColumnResizing
                  // columnResizeMode="onEnd"
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff", // Set the background color for even rows
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                      // m: 'auto'
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      // background: "rgba(52, 210, 235, 0.1)",
                      // borderRight: "1px solid rgba(224,224,224,1)",
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  muiTableBodyRowProps={({ row }) => ({
                    //implement row selection click events manually
                    onClick: () => {
                      if (data.length) {
                        console.log(row.original.client.id);
                        //   console.log("Clicked on ", row.id, data[row.id]);
                        //alert();
                        localStorage.setItem(
                          "view_client_id",
                          row.original.client.id
                        );
                        navigate(`/client-view`);
                      }
                    },
                    // selected: rowSelection[row.id],
                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  // manualFiltering
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  // rowCount={Math.ceil(totalCount / pagination.pageSize)}
                  state={{
                    // columnFilters,
                    // search,
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
