import React, { useCallback, useEffect, useState } from "react";
import ModalComponent from "../Components/ModalComponent";
import { RadioGroup, Combobox, Switch, Transition } from "@headlessui/react";
import { domainUrl, logOutHandler } from "../utils/constants";
import axios from "axios";
import amex from "../Images/cards/amex.png";
import bankImg from "../Images/cards/bank_img.jpeg";
import addcardImg from "../Images/cards/addcard.png";
import american_express from "../Images/cards/american_express.png";
import carteblanche from "../Images/cards/carteblanche.png";
import celo from "../Images/cards/celo.png";
import dankort from "../Images/cards/dankort.png";
import diners from "../Images/cards/diners.png";
import discover from "../Images/cards/discover.png";
import forbrugsforeningen from "../Images/cards/forbrugsforeningen.jpg";
import hipercard from "../Images/cards/hipercard.svg";
import instapayment from "../Images/cards/instapayment.webp";
import jcb from "../Images/cards/jcb.png";
import laser from "../Images/cards/laser.png";
import mastercard from "../Images/cards/mastercard.png";
import mnp from "../Images/cards/mnp.png";
import nocards from "../Images/cards/nocards.jpg";
import onpay from "../Images/cards/onpay.png";
import rupay from "../Images/cards/rupay.png";
import troy from "../Images/cards/troy.png";
import uatp from "../Images/cards/uatp.png";
import visa from "../Images/cards/visa.png";
import visa_electron from "../Images/cards/visa_electron.png";
import vpay from "../Images/cards/vpay.png";
import toast from "react-hot-toast";
import { Oval, RotatingLines, ThreeDots } from "react-loader-spinner";
import { PlaidLink } from "react-plaid-link";
import {
  ExclamationCircleIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

const HandleAddEditPayment = ({ clientData }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const client_data = clientData;
  const authorize_customer_id = client_data?.authorize_customer_id;
  const [selectedAddOption, setSelectedAddOption] = useState("");
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const [paymentSelectFlag, setPaymentSelectFlag] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [cardAchLoader, setCardAchLoader] = useState(false);
  const [paymentAddFlag, setPaymentAddFlag] = useState(false);
  const [payableAmount, setPayableAmount] = useState("");
  const [confirmPageFlag, setConfirmPageFlag] = useState(false);
  const [transactionFlag, setTransactionFlag] = useState(false);
  const selectedPay = localStorage.getItem("selectedPay");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedMethodId, setSelectedMethodId] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    selectedPay ? selectedPay : ""
  );

  const [selectedMethodLabel, setSelectedMethodLabel] = useState("");

  const [data, setData] = useState({
    cardholder: "",
    card_number: "",
    bank_account_number: "",
    bank_routing_number: "",
    expiration_date: "",
    cvv: "",
    zip_code: "",
    accountholder: "",
    bank_name: "",
  });
  const [errorFlag, setErrorFlag] = useState({
    cardholder: "",
    card_number: "",
    bank_account_number: "",
    bank_routing_number: "",
    expiration_date: "",
    cvv: "",
    zip_code: "",
    accountholder: "",
    bank_name: "",
  });
  const [cardDetails, setCardDetails] = useState({
    cardExpiryMonth: "",
    cardExpiryYear: "",
  });
  const [cardDetailsError, setCardDetailsError] = useState({
    cardExpiryMonth: "",
    cardExpiryYear: "",
  });
  const length_validate = {
    zip_code: 5,
    card_number: { min: 16, max: 16 },
    bank_account_number: { min: 5, max: 17 },
    bank_routing_number: 9,
    cvv: 4,
  };
  const errMsg = {
    zip_code: "Zipcode should be 5 digit",
    cvv: "CVV should be 3 or 4 digit",
    card_number: "Card number must be 16 digit.",
    bank_account_number:
      "Bank account number needs to be between 12 and 17 digits.",
    bank_routing_number: "Bank routing number should be 9 digit.",
  };
  const formatCardNumber = (value = "") => {
    // Remove any non-numeric characters
    const numericValue = value.replace(/[^0-9]+/g, "");

    // Ensure the total length does not exceed 18 characters
    if (numericValue.length > 20) {
      return numericValue.slice(0, 20);
    }

    // Format the number with spaces every 4 digits
    const formattedNumber = numericValue.replace(/(\d{4})/g, "$1 ");

    // Trim any leading or trailing spaces
    return formattedNumber.trim();
    // handleChange(formattedObject);
  };

  const [grantPlaidAccess, setGrantPlaidAccess] = useState(false);
  const [plaidToken, setPlaidToken] = useState(null);
  const [accountsDetail, setAccountsDetail] = useState([]);

  const getPaymentDueDetails = async () => {
    try {
      const res = await axios.get(
        `${domainUrl}payment-info?customer_authorize_id=${authorize_customer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        const creditCardData = res.data.credit_card;
        const bankAccountData = res.data.bank_account;

        // Map credit card data
        const creditCardDetails = creditCardData.map((card, index) => ({
          id: index,
          name: card.card_type,
          description: `${card.card_number}`,
          type: "card",
          customer_payment_method_id: card.payment_method_id,
          autopay: card.autopay,
          preferred: card.preferred,
        }));

        // Map bank account data
        const bankAccountDetails = bankAccountData.map((account, index) => ({
          id: index + creditCardData.length, // Start index from creditCardData.length to avoid id conflicts
          name: account.account_type,
          description: `${account.routing_number}`,
          type: "ach",
          customer_payment_method_id: account.payment_method_id,
          autopay: account.autopay,
          preferred: account.preferred,
        }));

        // Combine both credit card and bank account details
        const combinedDetails = [...creditCardDetails, ...bankAccountDetails];

        // Set the state with combined details
        setAccountsDetail(combinedDetails);

        console.log(accountsDetail);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      // alert("Got gateway error");
    }
  };
  useEffect(() => {
    if (clientData?.authorize_customer_id) {
      getPaymentDueDetails();
    }
  }, [paymentAddFlag, clientData]);

  const createLinkToken = async () => {
    const res = await axios.post(`${domainUrl}plaid/create_link_token`);

    console.log(res);
    setPlaidToken(res.data.link_token);
    setGrantPlaidAccess(true);
    console.log("Plaid access granted");
  };

  const onSuccess = useCallback(async (public_token, metadata) => {
    // send public_token to server
    console.log("Public Token Recieved", public_token);
    try {
      const res = await axios.post(
        `${domainUrl}plaid/set_access_token`,
        { public_token },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      console.log(res);
      setGrantPlaidAccess(false);
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  }, []);

  const handleAddPaymentChange = (e) => {
    console.log(e);
    setSelectedAddOption(e);
    setOpenAddPaymentModal(true);
    setPaymentAddFlag(false);
  };

  const deleteRecievedPaymentMethod = async (
    customer_payment_method_id,
    type
  ) => {
    try {
      const res = await axios.delete(
        `${domainUrl}payment-info?customer_authorize_id=${clientData?.authorize_customer_id}&customer_payment_method_id=${customer_payment_method_id}&type=${type}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        getPaymentDueDetails();
        toast.success("Successfully deleted");
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      toast.error(error.response.data.detail, { id: 1 });
    }
  };
  const handleSelectionPay = async (account) => {
    console.log(account);
    localStorage.setItem("selectedPay", account.customer_payment_method_id);
    setSelectedPaymentMethod(account.id);
    setSelectedMethod(account.type);
    setSelectedMethodId(account.customer_payment_method_id);
    setSelectedMethodLabel(account.description);
    if (
      client_data.preferred_payment_autopay &&
      client_data.preferred_payment_method == account.customer_payment_method_id
    ) {
      setSelectedCheckbox("yes");
    } else {
      setSelectedCheckbox("");
    }
  };

  const filterNumberLength = (value, max) => {
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length > max) {
      return numericValue.slice(0, max);
    }
    return numericValue;
  };
  function formatAlphabetsAndSpaces(input) {
    // Use a regular expression to match only alphabets and spaces
    const regex = /[^a-zA-Z ]/g;
    // Replace any characters that don't match the regex with an empty string
    const formattedString = input.replace(regex, "");

    return formattedString;
  }

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 as getMonth() returns 0-based index
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  const formHandler = async (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setErrorFlag((prevErrorFlag) => ({
      ...prevErrorFlag,
      [e.target.name]: "",
    }));
    if (
      e.target.name == "cardExpiryMonth" ||
      e.target.name == "cardExpiryYear"
    ) {
      if (e.target.name == "cardExpiryMonth") {
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiryMonth: "",
        });
      } else {
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiryYear: "",
        });
      }
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        ["expiration_date"]: "",
      }));
    }

    if (e.target.name == "cardholder") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: formatAlphabetsAndSpaces(e.target.value),
      }));
    } else if (e.target.name == "card_number") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: e.target.value,
      }));
    } else if (e.target.name == "cardExpiryMonth") {
      const currentYear = new Date().getFullYear() % 100;
      const currentMonth = new Date().getMonth() + 1;
      const monthValue = parseInt(e.target.value, 10);
      const yearValue = parseInt(cardDetails.cardExpiryYear, 10);
      if (
        monthValue == 0 ||
        monthValue > 12 ||
        (currentYear == cardDetails.cardExpiryYear && monthValue < currentMonth)
      ) {
        // toast.error("Invalid expiry Month",{id:1});
        // return;
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiryMonth: "*invalid month",
        });
      }
      if (
        String(yearValue).length == 2 &&
        yearValue <= currentYear % 100 &&
        parseInt(e.target.value, 10) <= currentMonth
      ) {
        setErrorFlag((prevValue) => ({
          ...prevValue,
          expiration_date: "*enter a valid expiry date",
        }));
      }
      setCardDetails({
        ...cardDetails,
        cardExpiryMonth: e.target.value,
      });

      setData((prevValue) => ({
        ...prevValue,
        expiration_date: `${e.target.value}/${cardDetails.cardExpiryYear}`,
      }));
    } else if (e.target.name == "cardExpiryYear") {
      const currentYear = new Date().getFullYear() % 100;
      const currentMonth = new Date().getMonth() + 1;
      const yearValue = parseInt(e.target.value, 10);
      if (
        e.target.value.length == 2 &&
        parseInt(e.target.value) <
          parseInt(new Date().getFullYear().toString().slice(-2))
      ) {
        // toast.error("Invalid expiry Year",{id:1});
        // return
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiryYear: "*invalid year",
        });
      }
      if (
        String(yearValue).length == 2 &&
        yearValue <= currentYear % 100 &&
        parseInt(cardDetails.cardExpiryMonth, 10) <= currentMonth
      ) {
        setErrorFlag((prevValue) => ({
          ...prevValue,
          expiration_date: "*enter a valid expiry date",
        }));
      }
      setCardDetails({
        ...cardDetails,
        cardExpiryYear: e.target.value,
      });
      setData((prevValue) => ({
        ...prevValue,
        expiration_date: `${cardDetails.cardExpiryMonth}/${e.target.value}`,
      }));
      console.log(
        parseInt(e.target.value) <
          parseInt(new Date().getFullYear().toString().slice(-2))
      );
    } else if (e.target.name == "bank_account_number") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: filterNumberLength(
          e.target.value,
          length_validate[e.target.name].max
        ),
      }));
    } else if (e.target.name == "bank_routing_number") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: filterNumberLength(
          e.target.value,
          length_validate[e.target.name]
        ),
      }));
    } else if (e.target.name == "zip_code") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: filterNumberLength(
          e.target.value,
          length_validate[e.target.name]
        ),
      }));
    } else if (e.target.name == "cvv") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: filterNumberLength(
          e.target.value,
          length_validate[e.target.name]
        ),
      }));
    } else if (e.target.name == "accountholder") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: formatAlphabetsAndSpaces(e.target.value),
      }));
    } else if (e.target.name == "bank_name") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: formatAlphabetsAndSpaces(e.target.value),
      }));
    }

    setTypingTimeout(setTimeout(async () => validateFormFields(e), 300));
  };

  const validateFormFields = async (e) => {
    if (e.target.name == "card_number") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length != 19 ? errMsg[e.target.name] : "",
      }));
    } else if (e.target.name == "bank_account_number") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length > 17 || e.target.value.length < 12
            ? errMsg[e.target.name]
            : "",
      }));
    } else if (e.target.name == "bank_routing_number") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length != 9 ? errMsg[e.target.name] : "",
      }));
    } else if (e.target.name == "zip_code") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length != 5 ? errMsg[e.target.name] : "",
      }));
    } else if (e.target.name == "cvv") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]: e.target.value.length < 3 ? errMsg[e.target.name] : "",
      }));
    }
  };

  const validateFormFieldsForCardSubmit = () => {
    for (const key in data) {
      if (
        key !== "bank_account_number" &&
        key !== "bank_routing_number" &&
        key !== "accountholder" &&
        key !== "bank_name" &&
        data[key] === ""
      ) {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [key]: `*${key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
        }));
      }
    }
    for (const key in cardDetails) {
      if (cardDetails[key] === "") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          expiration_date: "Expiry date is required",
        }));
      }
    }
    for (const key in data) {
      if (
        key !== "bank_account_number" &&
        key !== "bank_routing_number" &&
        key !== "accountholder" &&
        key !== "bank_name" &&
        data[key] === ""
      ) {
        return true;
      }
    }
    for (const key in cardDetails) {
      if (cardDetails[key] === "") {
        return true;
      }
    }
    const cardholderError = errorFlag.cardholder !== "";
    const cardNumberError = errorFlag.card_number !== "";
    const expiryMonthError = cardDetailsError.cardExpiryMonth !== "";
    const expiryYearError = cardDetailsError.cardExpiryYear !== "";
    const expiryError = errorFlag.expiration_date !== "";
    const cvvError = errorFlag.cvv !== "";
    const zipError = errorFlag.zip_code !== "";
    const expiryDateError = errorFlag.expiration_date !== "";

    console.log(cardDetailsError, errorFlag);

    // Return true if any of the fields have errors, otherwise return false
    return (
      cardholderError ||
      cardNumberError ||
      expiryMonthError ||
      expiryYearError ||
      expiryError ||
      cvvError ||
      zipError ||
      expiryDateError
    );
  };

  const postGatewayCardData = async () => {
    const hasErrors = validateFormFieldsForCardSubmit();

    // If there are errors, display an error message and return
    if (hasErrors) {
      toast.error("Complete the fields", { id: 1 });
      return;
    }
    console.log(data);
    try {
      setCardAchLoader(true);
      const res = await axios.post(
        `${domainUrl}payment-info`,
        {
          type: "card",
          customer_authorize_id: authorize_customer_id,
          data: {
            card_number: data.card_number.replace(/\s/g, ""),
            expiration_date: `20${cardDetails.cardExpiryYear}-${cardDetails.cardExpiryMonth}`,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        toast.success(res.data?.message, { id: 1 });
        setOpenAddPaymentModal(false);
        setPaymentAddFlag(false);
        getPaymentDueDetails();
        setData({
          cardholder: "",
          card_number: "",
          bank_account_number: "",
          bank_routing_number: "",
          expiration_date: "",
          cvv: "",
          zip_code: "",
          accountholder: "",
          bank_name: "",
        });
        setCardDetails({
          cardExpiryMonth: "",
          cardExpiryYear: "",
        });

        setSelectedAddOption("");
        setCardAchLoader(false);
        // alert("Got gateway response")
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      toast.error(error.response.data.detail, { id: 1 });
      setCardAchLoader(false);
      // alert("Got gateway error")
    }
  };

  const validateFormFieldsForAchSubmit = () => {
    for (const key in data) {
      if (
        key !== "cardholder" &&
        key !== "card_number" &&
        key !== "expiration_date" &&
        key !== "cvv" &&
        key !== "zip_code" &&
        data[key] === ""
      ) {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [key]: `*${key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
        }));
      }
    }
    for (const key in data) {
      if (
        key !== "cardholder" &&
        key !== "card_number" &&
        key !== "expiration_date" &&
        key !== "cvv" &&
        key !== "zip_code" &&
        data[key] === ""
      ) {
        return true;
      }
    }

    const bankAccountError = errorFlag.bank_account_number !== "";
    const bankRoutingError = errorFlag.bank_routing_number !== "";
    const holderError = errorFlag.accountholder !== "";
    const bankNameError = errorFlag.bank_name !== "";

    console.log(errorFlag);

    // Return true if any of the fields have errors, otherwise return false
    return bankAccountError || bankRoutingError || holderError || bankNameError;
  };

  const postGatewayAchData = async () => {
    const hasErrors = validateFormFieldsForAchSubmit();

    // If there are errors, display an error message and return
    if (hasErrors) {
      toast.error("Complete the fields", { id: 1 });
      return;
    }
    console.log(data);
    try {
      setCardAchLoader(true);
      const res = await axios.post(
        `${domainUrl}payment-info`,
        {
          type: "ach",
          customer_authorize_id: authorize_customer_id,
          data: {
            bank_routing_number: `${data.bank_routing_number}`,
            bank_account_number: `${data.bank_account_number}`,
            name: `${data.accountholder}`,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        if (res.data) {
          toast.success(res.data.message, { id: 1 });
          setOpenAddPaymentModal(false);
          setPaymentAddFlag(false);
          getPaymentDueDetails();
          setData({
            cardholder: "",
            card_number: "",
            bank_account_number: "",
            bank_routing_number: "",
            expiration_date: "",
            cvv: "",
            zip_code: "",
            accountholder: "",
            bank_name: "",
          });
          setSelectedAddOption("");
          setCardAchLoader(false);
        }
        // setCustomerProfileId(res.data.customerProfileId)
        // alert("Got gateway response")
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      toast.error(error.response.data.detail, { id: 1 });
      setCardAchLoader(false);
      // alert("Got gateway error")
    }
  };

  const postTransactionGateway = async () => {
    try {
      setTransactionFlag(true);
      const res = await axios.post(
        `${domainUrl}transactions`,
        {
          customer_authorize_id: authorize_customer_id,
          customer_payment_method_id: selectedMethodId,
          amount: payableAmount.replace(/[$,]/g, ""),
          autopay: selectedCheckbox == "yes" ? true : false,
          type: selectedMethod,
          label: selectedMethodLabel,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        // getNextScheduledDetails();
        // getClientProfileHandler();
        // getPaymentDueDetails()
        client_data.preferred_payment_method = selectedMethodId;
        client_data.preferred_payment_autopay =
          selectedCheckbox == "yes" ? true : false;
        localStorage.setItem("client_data", JSON.stringify(client_data));
        setTransactionFlag(false);
        toast.success(res.data.message, { id: 1 });
        localStorage.removeItem("selectedPay");
        setSelectedPaymentMethod("");
        setPaymentSelectFlag(false);
        setConfirmPageFlag(false);
        setPayableAmount("");
        setSelectedCheckbox(null);
        setSelectedMethod("");
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setTransactionFlag(false);
      toast.error(error.response.data.detail, { id: 1 });
      if (error.response.data.unverified) {
        createLinkToken();
      }
    }
  };

  const autoPayHandler = async (preferred_payment_method, auto_pay) => {
    try {
      const res = await axios.post(
        `${domainUrl}set-autopay?contract=${client_data?.contracts[0]?.id}`,
        {
          preferred_payment_method: preferred_payment_method,
          autopay: auto_pay,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        getPaymentDueDetails();
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      toast.error(error.response.data.detail, { id: 1 });
    }
  };
  return (
    <>
      <div className="border rounded-xl px-5 my-5">
        <div className="w-full flex justify-start items-center py-5">
          <h2 className="text-lg font-semibold text-gray-900">
            Added Payment Methods
          </h2>
        </div>
        <div className="flex justify-end my-3">
          <div
            onClick={() => {
              setPaymentAddFlag(true);
              setOpenAddPaymentModal(true);
            }}
            className="text-lg font-semibold text-[#344767] flex items-center gap-2 hover:cursor-pointer"
          >
            <div>
              <PlusIcon className="w-10" />
            </div>
            <div className="text-2xl">Add New</div>
          </div>
        </div>
        <div className="my-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {accountsDetail.map((account, accountIdx) => (
            <div
              key={accountIdx}
              className="border-indigo-500 ring-2 ring-indigo-600 relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
            >
              {account.preferred && (
                <span className="bg-amber-300 px-1 rounded-md text-sm absolute left-2 top-[-5px]">
                  Preferred
                </span>
              )}
              <>
                <span className="flex items-center mx-3">
                  {account.type == "ach" ? (
                    <img
                      src={bankImg}
                      alt="bankImg"
                      className=" w-10 mt-1  flex-shrink-0"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "Amex" ? (
                    <img
                      src={amex}
                      alt="amex"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "AmericanExpress" ? (
                    <img
                      src={american_express}
                      alt="american_express"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "Carteblanche" ? (
                    <img
                      src={carteblanche}
                      alt="carteblanche"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "Celo" ? (
                    <img
                      src={celo}
                      alt="celo"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "Dankort" ? (
                    <img
                      src={dankort}
                      alt="dankort"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "Diners Club" ? (
                    <img
                      src={diners}
                      alt="diners"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "Discover" ? (
                    <img
                      src={discover}
                      alt="discover"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}

                  {account.name == "Forbrugsforeningen" ? (
                    <img
                      src={forbrugsforeningen}
                      alt="forbrugsforeningen"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "Hipercard" ? (
                    <img
                      src={hipercard}
                      alt="hipercard"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "Instapayment" ? (
                    <img
                      src={instapayment}
                      alt="instapayment"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "JCB" ? (
                    <img
                      src={jcb}
                      alt="jcb"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "laser" ? (
                    <img
                      src={laser}
                      alt="laser"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "MasterCard" ? (
                    <img
                      src={mastercard}
                      alt="mastercard"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "mnp" ? (
                    <img
                      src={mnp}
                      alt="mnp"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "nocards" ? (
                    <img
                      src={nocards}
                      alt="nocards"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "onpay" ? (
                    <img
                      src={onpay}
                      alt="onpay"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "rupay" ? (
                    <img
                      src={rupay}
                      alt="rupay"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "troy" ? (
                    <img
                      src={troy}
                      alt="troy"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "uatp" ? (
                    <img
                      src={uatp}
                      alt="uatp"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "Visa" ? (
                    <img
                      src={visa}
                      alt="visa"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "visa_electron" ? (
                    <img
                      src={visa_electron}
                      alt="visa_electron"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                  {account.name == "vpay" ? (
                    <img
                      src={vpay}
                      alt="vpay"
                      className=" w-12  flex-shrink-0 rounded-full"
                    />
                  ) : (
                    ""
                  )}
                </span>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <span className="block text-base font-medium text-gray-500">
                      {account.name}
                    </span>
                    <span className="mt-1 flex items-center text-lg text-gray-900">
                      {account.description}
                    </span>
                    <span className="mt-6 text-sm font-medium text-gray-900">
                      {account.type.toUpperCase()}
                    </span>
                  </span>
                </span>
                
                  <span className="flex flex-col justify-between items-end">
                    {(role == "admin" && !account.preferred ) && (
                    <TrashIcon
                      onClick={(e) => {
                        console.log("Trash", account, accountIdx);
                        deleteRecievedPaymentMethod(
                          account.customer_payment_method_id,
                          account.type
                        );
                      }}
                      className="ml-4 h-5 w-5  font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                    />)}
                    {(role == "admin" || role == "primaryattorney") && (
                      <div className="inline-flex flex-col gap-2">
                        <span className="text-sm font-semibold italic">
                          Auto-pay:
                        </span>
                        <Switch
                          checked={account.autopay}
                          onChange={(e) => {
                            console.log(e);
                            autoPayHandler(
                              account.customer_payment_method_id,
                              e
                            );
                          }}
                          className={classNames(
                            account.autopay ? "bg-indigo-600" : "bg-gray-200",
                            "self-end relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              account.autopay
                                ? "translate-x-5"
                                : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>
                      </div>
                    )}
                  </span>
              </>
            </div>
          ))}
        </div>
      </div>

      <ModalComponent open={openAddPaymentModal} handleClose={() => {}}>
        <>
          {!paymentAddFlag ? (
            <div className="w-full sm:flex  justify-center items-center">
              {selectedAddOption == "card" ? (
                <div className="w-full p-2 bg-white rounded-lg sm:max-w-3xl">
                  <div className="w-full flex justify-between">
                    <span
                      className="hover:cursor-pointer"
                      onClick={() => {
                        setPaymentAddFlag(true);
                        setSelectedAddOption("");
                        setData({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setErrorFlag({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setCardDetails({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                        setCardDetailsError({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        class="bi bi-arrow-left-circle"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                        />{" "}
                      </svg>
                    </span>
                    <span
                      className="border hover:cursor-pointer"
                      onClick={() => {
                        setOpenAddPaymentModal(false);
                        setSelectedAddOption("");
                        setData({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setErrorFlag({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setCardDetails({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                        setCardDetailsError({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                      }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="48"
                          height="48"
                          fill="white"
                          fill-opacity="0.01"
                        />
                        <path
                          d="M14 14L34 34"
                          stroke="#333"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14 34L34 14"
                          stroke="#333"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="p-2">
                    <div className="text-xl font-semibold text-[#344767] flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="text-xl font-bold">Add New Card</div>
                      </div>
                    </div>
                    <div className="my-2">
                      <div className="shadow-lg  rounded-xl grid  px-3 mt-10 py-2">
                        <form>
                          <div className="border-gray-900/10 pb-5">
                            <div className="grid">
                              <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-5">
                                <div className="text-start lg:grid-cols-1 sm:col-span-1 mt-5">
                                  <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                                    Name
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      value={data.cardholder}
                                      name="cardholder"
                                      id="cardholder"
                                      className="border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767] uppercase"
                                      onChange={(e) => formHandler(e)}
                                    />
                                  </div>
                                  <span className="text-red-500 text-xs">
                                    {errorFlag.cardholder}
                                  </span>
                                </div>

                                <div className="text-start lg:grid-cols-1 sm:col-span-1 mt-5">
                                  <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                                    Credit / Debit Card Number
                                  </label>
                                  <div className="mt-1">
                                    <CurrencyFormat
                                      name="card_number"
                                      id="card_number"
                                      onChange={(e) => formHandler(e)}
                                      value={data.card_number}
                                      className=" border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2  "
                                      format="#### #### #### ####"
                                      mask="_"
                                    />
                                  </div>
                                  <span className="text-red-500 text-xs">
                                    {errorFlag.card_number}
                                  </span>
                                </div>
                              </div>
                              <div className="text-start grid lg:grid-cols-2 sm:grid-cols-1 gap-5 mt-5">
                                <div className="sm:col-span-4">
                                  <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                                    Card Expires:(MM/YY)
                                  </label>
                                  <div className="w-full flex mt-5">
                                    <div className="w-20 flex flex-col gap-1">
                                      <input
                                        type="text"
                                        name="cardExpiryMonth"
                                        onChange={(e) => {
                                          formHandler(e);
                                        }}
                                        maxLength={2}
                                        id="cardExpiryMonth"
                                        value={cardDetails.cardExpiryMonth}
                                        className="mr-2 border-b-1 focus:border-[#344767] block w-16  outline-none   border-b-2 "
                                      />
                                      <p className="text-red-600 text-xs">
                                        {cardDetailsError?.cardExpiryMonth}
                                      </p>
                                    </div>
                                    /
                                    <div className="w-20 flex flex-col gap-1">
                                      <input
                                        type="text"
                                        name="cardExpiryYear"
                                        onChange={(e) => formHandler(e)}
                                        id="cardExpiryYear"
                                        value={cardDetails.cardExpiryYear}
                                        maxLength={2}
                                        className="ml-2 border-b-1 focus:border-[#344767] block w-16 outline-none  border-b-2 "
                                      />
                                      <p className="text-red-600 text-xs">
                                        {cardDetailsError?.cardExpiryYear}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="text-red-600 text-xs">
                                    {errorFlag.expiration_date}
                                  </p>
                                </div>

                                <div className="text-start lg:grid-cols-1 sm:col-span-1 mt-5">
                                  <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                                    CVV / CVC
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      name="cvv"
                                      value={data.cvv}
                                      onChange={(e) => formHandler(e)}
                                      id="cvv"
                                      className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 "
                                    />
                                  </div>
                                  <span className="text-red-500 text-xs">
                                    {errorFlag.cvv}
                                  </span>
                                </div>

                                <div className="text-start lg:grid-cols-1 sm:col-span-1 mt-5">
                                  <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                                    Zip Code
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      name="zip_code"
                                      value={data.zip_code}
                                      onChange={(e) => formHandler(e)}
                                      id="zip_code"
                                      className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 "
                                    />
                                  </div>
                                  <span className="text-red-500 text-xs">
                                    {errorFlag.zip_code}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>

                        {/* </div> */}
                      </div>
                    </div>
                    <div className="">
                      <div className="sm:flex justify-end m-3">
                        <div className="sm:m-1">
                          <button
                            type="button"
                            onClick={(e) => {
                              console.log("Form Submitted");
                              postGatewayCardData();
                            }}
                            // disabled={!formValidity.isFormValid || !formValidity.allFieldsFilled}

                            className="inline-flex gap-3 justify-center items-center  rounded-md bg-green-700 bg-gradient-to-r from-[#439DEE] to-[#1E78E9]  text-lg md:text-lg w-full p-5 font-semibold text-white shadow-sm mt-1"
                          >
                            SAVE
                            {cardAchLoader && (
                              <Oval
                                height={20}
                                width={20}
                                color="#ffff"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#ffff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full p-2 bg-white rounded-lg sm:max-w-3xl">
                  <div className="w-full flex justify-between">
                    <span
                      className="hover:cursor-pointer"
                      onClick={() => {
                        setPaymentAddFlag(true);
                        setSelectedAddOption("");
                        setData({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setErrorFlag({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setCardDetailsError({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        class="bi bi-arrow-left-circle"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                        />{" "}
                      </svg>
                    </span>
                    <span
                      className="border hover:cursor-pointer"
                      onClick={() => {
                        setOpenAddPaymentModal(false);
                        setSelectedAddOption("");
                        setData({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setErrorFlag({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setCardDetailsError({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                      }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="48"
                          height="48"
                          fill="white"
                          fill-opacity="0.01"
                        />
                        <path
                          d="M14 14L34 34"
                          stroke="#333"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14 34L34 14"
                          stroke="#333"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="p-2">
                    <div className="text-xl font-semibold text-[#344767] flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="text-xl font-bold">
                          Add New ACH Method
                        </div>
                      </div>
                    </div>

                    <div className="w-full shadow-lg bg-white rounded-xl px-3 py-4">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 py-2">
                        <div className="text-start sm:col-span-2 mt-5">
                          <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                            Name
                          </label>
                          <div className="w-full mt-5">
                            <input
                              type="text"
                              name="accountholder"
                              value={data.accountholder}
                              onChange={(e) => {
                                formHandler(e);
                              }}
                              className="w-full mt-2 border-b-1 focus:border-[#344767]  outline-none  border-b-2 "
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.accountholder}
                          </span>
                        </div>
                        <div className="text-start sm:col-span-2 mt-5">
                          <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                            Account Number
                          </label>
                          <div className="w-full mt-5">
                            <input
                              type="text"
                              name="bank_account_number"
                              value={data.bank_account_number}
                              onChange={(e) => {
                                formHandler(e);
                              }}
                              className="w-full mt-2 border-b-1 focus:border-[#344767]  outline-none  border-b-2 "
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.bank_account_number}
                          </span>
                        </div>
                        <div className="text-start sm:col-span-2 mt-5">
                          <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                            Bank Routing Number
                          </label>
                          <div className="w-full mt-5">
                            <input
                              type="text"
                              name="bank_routing_number"
                              value={data.bank_routing_number}
                              onChange={(e) => {
                                formHandler(e);
                              }}
                              className="w-full mt-2 border-b-1 focus:border-[#344767] outline-none  border-b-2 "
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.bank_routing_number}
                          </span>
                        </div>
                        <div className="text-start sm:col-span-2 mt-5">
                          <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                            Bank Name
                          </label>
                          <div className="w-full mt-5">
                            <input
                              type="text"
                              name="bank_name"
                              value={data.bank_name}
                              onChange={(e) => {
                                formHandler(e);
                              }}
                              className="w-full mt-2 border-b-1 focus:border-[#344767] outline-none  border-b-2 "
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.bank_name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="sm:flex justify-end m-3">
                        <div className="sm:m-1">
                          <button
                            type="button"
                            onClick={(e) => {
                              console.log("Form Submitted");
                              postGatewayAchData();
                            }}
                            className="inline-flex gap-3 justify-center items-center  rounded-md bg-green-700 bg-gradient-to-r from-[#439DEE] to-[#1E78E9]  text-lg md:text-lg w-full p-5 font-semibold text-white shadow-sm mt-1"
                          >
                            SAVE
                            {cardAchLoader && (
                              <Oval
                                height={20}
                                width={20}
                                color="#ffff"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#ffff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="sm:flex justify-center items-center">
              <div className="p-2 bg-white rounded-lg">
                <div className="w-full flex justify-end">
                  <span
                    className="border hover:cursor-pointer"
                    onClick={() => {
                      setSelectedAddOption("");
                      setOpenAddPaymentModal(false);
                      setPaymentAddFlag(false);
                    }}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="48"
                        height="48"
                        fill="white"
                        fill-opacity="0.01"
                      />
                      <path
                        d="M14 14L34 34"
                        stroke="#333"
                        stroke-width="1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 34L34 14"
                        stroke="#333"
                        stroke-width="1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>

                <div className="p-2 rounded-lg sm:my-3">
                  <div className="my-2">
                    <div className="text-xl font-semibold text-[#344767] flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="text-xl font-bold">
                          Add New Payment Method
                        </div>
                      </div>
                    </div>
                    <div className="shadow-lg bg-white rounded-xl my-5">
                      <RadioGroup
                        value={selectedAddOption ? selectedAddOption : null}
                        onChange={handleAddPaymentChange}
                      >
                        <RadioGroup.Label className="sr-only">
                          Add New Payment Method
                        </RadioGroup.Label>
                        <div className="-space-y-px rounded-md bg-white">
                          <RadioGroup.Option
                            value={"card"}
                            className={({ checked }) =>
                              classNames(
                                checked
                                  ? "z-10 border-indigo-200 bg-indigo-50"
                                  : "border-gray-200",
                                "relative flex items-center cursor-pointer border p-4 focus:outline-none"
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <span
                                  className={classNames(
                                    selectedAddOption == "card"
                                      ? "bg-indigo-600 border-transparent"
                                      : "bg-white border-gray-300",
                                    active
                                      ? "ring-2 ring-offset-2 ring-indigo-600 bg-indigo-600"
                                      : "",
                                    "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <span className="inline-flex items-center mx-3">
                                  <img
                                    src={addcardImg}
                                    alt="addcardImg"
                                    className=" w-10 mt-1  flex-shrink-0"
                                  />
                                </span>
                                <span className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-indigo-900"
                                        : "text-gray-900",
                                      "block text-base font-bold"
                                    )}
                                  >
                                    Add Credit or Debit Card
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-indigo-700"
                                        : "text-gray-400",
                                      "block text-sm"
                                    )}
                                  >
                                    Add card
                                  </RadioGroup.Description>
                                </span>
                              </>
                            )}
                          </RadioGroup.Option>
                        </div>
                        <div className="-space-y-px rounded-md bg-white">
                          <RadioGroup.Option
                            value={"ach"}
                            className={({ checked }) =>
                              classNames(
                                checked
                                  ? "z-10 border-indigo-200 bg-indigo-50"
                                  : "border-gray-200",
                                "relative flex items-center cursor-pointer border p-4 focus:outline-none"
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <span
                                  className={classNames(
                                    selectedAddOption == "ach"
                                      ? "bg-indigo-600 border-transparent"
                                      : "bg-white border-gray-300",
                                    active
                                      ? "ring-2 ring-offset-2 ring-indigo-600 bg-indigo-600"
                                      : "",
                                    "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <span className="inline-flex items-center mx-3">
                                  <img
                                    src={bankImg}
                                    alt="bankImg"
                                    className=" w-10 mt-1  flex-shrink-0"
                                  />
                                </span>
                                <span className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-indigo-900"
                                        : "text-gray-900",
                                      "block text-base font-bold"
                                    )}
                                  >
                                    Add Bank ACH
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-indigo-700"
                                        : "text-gray-400",
                                      "block text-sm"
                                    )}
                                  >
                                    Add Ach
                                  </RadioGroup.Description>
                                </span>
                              </>
                            )}
                          </RadioGroup.Option>
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </ModalComponent>
      <ModalComponent open={grantPlaidAccess} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationCircleIcon
                    className="h-8 w-8 text-indigo-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    ALERT
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Note:{" "}
                      <span className="text-black font-bold">
                        The Bank Account you have choosen is not verified
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {plaidToken == null ? (
              <div className="w-full flex justify-center items-center">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#1a3253"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse gap-3 sm:px-6">
                <div className="mt-3 inline-flex w-full justify-center items-center rounded-md bg-blue-500 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-400 sm:mt-0 sm:w-auto">
                  <PlaidLink
                    token={plaidToken}
                    onSuccess={onSuccess}
                    // onExit={...}
                    // onEvent={...}
                    style={{
                      // marginTop: "0.75rem",
                      // display: "inline-flex",
                      // justifyContent: "center",
                      // borderRadius: "0.375rem",
                      // width: "100%",
                      backgroundColor: "#3B82F6",
                      padding: "0.75rem 1rem",
                      // fontSize: "0.875rem",
                      // fontWeight: "600",
                      color: "#ffff",
                      // boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                      border: "none",
                      // textDecoration: "none",
                      // textAlign: "center",
                      // transition: "background 0.3s ease",
                    }}
                  >
                    Verify
                  </PlaidLink>
                </div>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => setGrantPlaidAccess(false)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default HandleAddEditPayment;
