import React, { useState } from "react";
import styled from "styled-components";
import { skipped_stepper } from "../utils/constants";

const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  position: relative;
  :before {
    content: "";
    position: absolute;
    background: whitesmoke;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: #344767;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid
    ${({ step }) => (step === "completed" ? "#344767" : "#9ca3af")};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepCount = styled.span`
  font-size: 19px;
  color: #344767;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StepLabel = styled.span`
  font-size: 1rem;
  color: #344767;
  @media (max-width: 600px) {
    font-size: 10px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 100px;
`;

const ButtonStyle = styled.button`
  border-radius: 4px;
  border: 0;
  background: #344767;
  color: #ffffff;
  cursor: pointer;
  padding: 8px;
  width: 90px;
  :active {
    transform: scale(0.98);
  }
  :disabled {
    background: #9ca3af;
    color: #000000;
    cursor: not-allowed;
  }
`;

const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: #344767;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`;

const steps = [
  {
    label: "FIRM",
    step: 1,
    done: true,
  },
  {
    label: "ATTORNEYS",
    step: 2,
    done: false,
  },
  {
    label: "CONTRACT",
    step: 3,
    done: false,
  },
  {
    label: "PAYMENT",
    step: 4,
    done: false,
  },
];

const StepperComponent = ({ activeStep, nextStep, prevStep, steps,stepperStatus }) => {
  // const [activeStep, setActiveStep] = useState(1);

  // const nextStep = () => {
  //   setActiveStep(activeStep + 1);
  // };

  // const prevStep = () => {
  //   setActiveStep(activeStep - 1);
  // };

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <MainContainer>
      <StepContainer width={width}>
        {steps.map(({ step, label, done }) => (
          <StepWrapper key={step}>
            <StepStyle
              step={activeStep >= step ? "completed" : "incomplete"}
              style={{
                fontSize: !done ? "2rem" : "",
                color: !done ? "#FFA500" : "",
                borderColor: !done ? "#FFA500" : "",
              }}
            >
              {activeStep > step ? (
                !done ? (
                  <div>
                    {/* <svg
                      viewBox="0 0 21 21"
                      fill="currentColor"
                      height="2rem"
                      width="2rem"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        transform="translate(1 1)"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.5.5l9 16H.5zM9.5 10.5v-5"
                        />
                        <path
                          fill="currentColor"
                          d="M10.5 13.5 A1 1 0 0 1 9.5 14.5 A1 1 0 0 1 8.5 13.5 A1 1 0 0 1 10.5 13.5 z"
                        />
                      </g>
                    </svg> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2rem"
                      height="2rem"
                      viewBox="0 0 100 100"
                    >
                      <polygon points="50,10 90,90 10,90" fill="#FFA500" />

                      <rect x="45" y="30" width="10" height="30" fill="white" />
                      <circle cx="50" cy="70" r="5" fill="white" />
                    </svg>
                  </div>
                ) : (
                  <div>
                    <svg
                      width="1.5rem"
                      height="1.5rem"
                      viewBox="-2.4 -2.4 28.80 28.80"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#344767"
                      stroke="#344767"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke="#CCCCCC"
                        stroke-width="4.8"
                      >
                        {" "}
                        <title></title>{" "}
                        <g id="Complete">
                          {" "}
                          <g id="tick">
                            {" "}
                            <polyline
                              fill="none"
                              points="3.7 14.3 9.6 19 20.3 5"
                              stroke="#344767"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2.4"
                            ></polyline>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <title></title>{" "}
                        <g id="Complete">
                          {" "}
                          <g id="tick">
                            {" "}
                            <polyline
                              fill="none"
                              points="3.7 14.3 9.6 19 20.3 5"
                              stroke="#344767"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2.4"
                            ></polyline>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </div>
                )
              ) : (
                <StepCount>{step}</StepCount>
              )}
            </StepStyle>
            <StepsLabelContainer>
              <StepLabel key={step}>{label}</StepLabel>
            </StepsLabelContainer>
          </StepWrapper>
        ))}
      </StepContainer>
      <ButtonsContainer>
        {/* <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>
          Previous
        </ButtonStyle>
        <ButtonStyle onClick={nextStep} disabled={activeStep === totalSteps}>
          Next
        </ButtonStyle> */}
      </ButtonsContainer>
    </MainContainer>
 
  );
};

export default StepperComponent;
