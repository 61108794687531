import React from "react";
import ListClientsTable from "./Tables/ListClientsTable";

const ListClients = () => {
  return (
    <div>
      <ListClientsTable />
    </div>
  );
};

export default ListClients;
