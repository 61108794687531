import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Dialog, Transition } from "@headlessui/react";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
// };

const containerStyle = {
  position: "fixed",
  inset: 0,
  zIndex: 10,
  overflowY: "auto",
};

const contentWrapperStyle = {
  width: "100%",
  display: "flex",
  minHeight: "100vh",
  alignItems: "center",
  justifyContent: "center",
  padding: "1rem",
  textAlign: "center",
};

export default function ModalComponent({ open, handleClose, children }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}>{children}</Box> */}
        <div style={containerStyle}>
          <div style={contentWrapperStyle}>{children}</div>
        </div>
      </Modal>
    </div>
  );
}
