import React from 'react'
import PendingInvitationsTable from './Tables/PendingInvitationsTable'

const PendingInvitations = () => {
  return (
    <div>
      <PendingInvitationsTable/>
    </div>
  )
}

export default PendingInvitations
