import React from "react";
import ListAttorneysTable from "./Tables/ListAttorneysTable";

const ListAttorneys = () => {
  return (
    <div>
      <ListAttorneysTable />
    </div>
  );
};

export default ListAttorneys;
