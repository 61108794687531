import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../constants";
import toast from "react-hot-toast";

export const setAuthGet = createAsyncThunk("setAuthGet", async (override,thunkAPI) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const res = await axios.get(
      override ? `${domainUrl}setup-totp?override=1` : `${domainUrl}setup-totp`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    console.log("this is  res", res.data);
    console.log(access_token);
    return res.data;
  } catch (error) {
    if (error.response?.data.code == "token_not_valid") {
      logOutHandler();
      const navigate = thunkAPI.extra.navigateCallback;
      navigate("/login");
      toast.error("Session Expired", { id: 1 });
      return;
    } else {
      throw (error.response.data.detail);
    }
  }
});

const setAuthGetSlice = createSlice({
  name: "setAuthGetStorage",
  initialState: { data: [], isLoading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAuthGet.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setAuthGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(setAuthGet.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const {} = setAuthGetSlice.actions;
export default setAuthGetSlice.reducer;
