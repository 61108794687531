import { useNavigate } from "react-router-dom";
import PendingClientsTable from "./Tables/PendingClientsTable";
import ApprovedClientsTable from "./Tables/ApprovedClientsTable";
import PendingInvitationsTable from "./Tables/PendingInvitationsTable";
export default function ClientOverView() {
  return (
    <>
      <div>
        <main className="py-10">
          <PendingInvitationsTable />
          <PendingClientsTable />
          <ApprovedClientsTable />
        </main>
      </div>
    </>
  );
}
