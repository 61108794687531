import {
  DocumentArrowUpIcon,
  DocumentIcon,
  InformationCircleIcon,
  PencilSquareIcon,
  PaperClipIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { domainUrl, listedStates, logOutHandler } from "../utils/constants";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import RejectedIMG from "../assets/img/rejected-img.jpg";
import ModalComponent from "../Components/ModalComponent";
import { Visibility } from "@mui/icons-material";

const ViewRejectedClient = () => {
  const [data, setData] = useState({});
  const [attorneyDetails, setAttorneyDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const view_client_id = localStorage.getItem("view_client_id");
  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");

  const navigate = useNavigate();

  const [decodeState, setDecodeState] = useState("");
  const [contractDetails, setContractDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowPerpage, setRowPerpage] = useState(10);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [actionModalFlag, setActionModalFlag] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [contractDoc, setContractDoc] = useState([]);

  const getStateByCode = (code) => {
    return listedStates.find((state) => state.code === code);
  };
  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  function formatUSCell(usPhoneNumber) {
    // Remove any non-digit characters
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    // Check if the number has the expected length of 10 digits
    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    // Format the number in the original format and add +1 in front
    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  const getAttorneyDetails = async (attorney_id) => {
    try {
      setLoading(true);
      const res = await axios.get(`${domainUrl}attorney?id=${attorney_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        setAttorneyDetails(res.data);
        getAttorneyDetails(res.data.contracts[0].attorney);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setLoading(false);
    }
  };

  const getClientDetailsHandler = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${domainUrl}client-approval?id=${view_client_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setData(res.data);
        console.log(res.data.contracts[0].id);
        getPaymentSchedule(res.data.contracts[0].id);
        setContractDetails(res.data.contracts[0]);
        let state = getStateByCode(res.data.state);
        setDecodeState(state.name);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setLoading(false);
    }
  };
  const getPaymentSchedule = async (contract_id) => {
    console.log("Debug: fetching payment events");
    if (contract_id) {
      try {
        // console.log("requesting..")
        const res = await axios.get(
          `${domainUrl}payment-schedule?contract=${contract_id}&limit=${rowPerpage}&offset=${page}&sorting=[{"id":"id","desc":false}]`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        // console.log("called", res);
        if (res.data) {
          console.log("))))))))))))(((((((((", res.data);
          setPaymentDetails(res.data);
          setCount(res.data.count);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (view_client_id) {
      getClientDetailsHandler();
    } else {
      navigate(-1);
    }
    // return () => {
    //   localStorage.removeItem("view_client_id");
    // };
  }, []);

  useEffect(() => {
    if (contractDetails?.id) {
      getPaymentSchedule(contractDetails.id);
    }
  }, [page]);

  function extractPath(inputString) {
    const uploadsIndex = inputString.indexOf("/uploads/");

    if (uploadsIndex !== -1) {
      return inputString.substring(uploadsIndex + "/uploads/".length);
    } else {
      return inputString; // If "/uploads/" is not found, return the original string
    }
  }
  function removeFirstSlash(inputString) {
    // Check if the string starts with "/"
    if (inputString.startsWith("/")) {
      // Remove the first character (i.e., the "/")
      return inputString.substring(1);
    } else {
      // If the string doesn't start with "/", return the original string
      return inputString;
    }
  }

  const handleContractDocChange = (event) => {
    const files = event.target.files;
    let newFiles = [];

    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/webp",
      "application/pdf",
    ];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (allowedFileTypes.includes(file.type)) {
        // console.log("fine type");
        let maxsize = 5 * 1024 * 1024;
        if (file.size < maxsize) {
          newFiles = [...newFiles, file];
          // toast.success(`File ${file.name} Uploaded successfully`)
        } else {
          toast.error("Maximum allowed size is 5 MB.");
        }
      } else {
        // console.log("not allowed file", file.type);
        toast.error(
          "Invalid File Type! Please upload a file of type: [Supported File Types, e.g., .jpg, .png, .jpeg, .pdf]."
        );
      }
    }
    setContractDoc((prevFiles) => [...prevFiles, ...newFiles]);

    console.log("files", contractDoc);
  };

  const handleContractDocRemove = (index) => {
    setContractDoc((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const actionPendingHandler = async (type) => {
    const contractPromises = contractDoc.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const base64Data = event.target.result.split(",")[1];
          resolve({ name: file.name, file: base64Data });
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    });

    const base64FilesContract = await Promise.all(contractPromises);
    if (type == "rejected") {
      if (noteText.length == 0) {
        toast.error("Enter the Note and submit", { id: 1 });
        return false;
      }
      try {
        setActionLoading(true);
        const res = await axios.post(
          `${domainUrl}client-approval?id=${view_client_id}`,
          {
            id: view_client_id,
            status: type,
            note: noteText,
            file: base64FilesContract,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          toast.success(res.data.message, { id: 1 });
          setActionModalFlag("");
          setOpenActionModal(false);
          if (type == "approved") {
            navigate("/approved-clients");
          } else {
            navigate("/list-clients");
          }
          setActionLoading(false);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setActionLoading(false);
        toast.error(error.response.data.detail, { id: 1 });
      }
    } else {
      try {
        setActionLoading(true);
        const res = await axios.post(
          `${domainUrl}client-approval?id=${view_client_id}`,
          {
            id: view_client_id,
            status: type,
            note: noteText,
            file: base64FilesContract,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          toast.success(res.data.message, { id: 1 });
          setActionModalFlag("");
          setOpenActionModal(false);
          if (type == "approved") {
            navigate("/approved-clients");
          } else {
            navigate("/list-clients");
          }
          setActionLoading(false);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setActionLoading(false);
        toast.error(error.response.data.detail, { id: 1 });
      }
    }
  };

  const paymentPlanString = {
    weekly: "Weekly Payments",
    bi_weekly: "Bi-Weekly Payments",
    semi_monthly_dates: "Semi-Monthly By Date",
    semi_monthly_days: "Semi-Monthly By Day",
    monthly: "Monthly Payments",
  };
  return (
    <>
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <motion.div
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.1, type: "spring", stiffness: 120 }}
        >
          <div className="p-5">
            <div className="my-5">
              <div className="my-5  flex justify-between items-center">
                <div className="">
                  <h1>
                    {data.user &&
                      `${data.user.first_name} ${data.user.last_name}`}
                  </h1>
                  <div className="text-md">
                    Client Code: ({data.client_code})
                  </div>
                </div>
                <div className="flex flex-col gap-3 items-start sm:flex-row items-end">
                  <div>
                    <img
                      className="h-24"
                      src={RejectedIMG}
                      alt="rejected-img"
                    />
                  </div>
                  <div className="flex flex-col gap-3 items-end">
                    {role == "primaryattorney" || role == "attorney" ? (
                      <button
                        onClick={() => {
                          navigate("/update-clients");
                        }}
                        type="button"
                        className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <PencilSquareIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        Edit
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      onClick={() => {
                        setOpenActionModal(true);
                      }}
                      type="button"
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <Visibility
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      View Note
                    </button>
                  </div>
                </div>

                {/* <div className="flex items-center justify-center gap-2">
                <div className="text-md font-bold">DocSign:</div>
                <button
                  type="button"
                  className=" gap-x-1.5 rounded-md bg-gray-500 px-5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-300"
                >
                  <DocumentIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div> */}
              </div>

              <div className="w-full grid grid-cols-1 md:grid-cols-2 md:gap-6">
                <div className="my-2">
                  <div className="px-4 py-5 sm:px-0">
                    <div className="text-xl font-semibold text-gray-900">
                      Basic Information
                    </div>
                    <p className="max-w-2xl text-sm  text-gray-500">
                      Personal Details and Application.
                    </p>
                  </div>
                  <div className="border p-3 rounded-lg shadow-md">
                    <div className="border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Full name
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.user &&
                              `${data.user.first_name} ${data.user.last_name}`}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Email address
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.user && data.user.email}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Primary Phone
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.user &&
                              formatUSCell(data.user.cell_phone_number)}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Address
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data &&
                              `${data.address_line1}  ${data.address_line2}`}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            City
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data && data.city}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            State
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data && decodeState}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Zip code
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data && data.zip_code}
                          </dd>
                        </div>
                        <>
                          {data?.contracts && (
                            <>
                              {data?.contracts[0]?.co_debtor_data.name && (
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                                    Co-debtor name
                                  </dt>
                                  <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {data?.contracts[0]?.co_debtor_data.name
                                      ? data?.contracts[0].co_debtor_data.name
                                      : ""}
                                  </dd>
                                </div>
                              )}
                              {data?.contracts[0]?.co_debtor_data.email && (
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                                    Co-debtor email
                                  </dt>
                                  <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {data?.contracts[0]?.co_debtor_data?.email
                                      ? data?.contracts[0]?.co_debtor_data
                                          ?.email
                                      : ""}
                                  </dd>
                                </div>
                              )}
                              {data?.contracts[0]?.co_debtor_data
                                .cell_phone_number && (
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                                    Co-debtor Phone
                                  </dt>
                                  <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {data?.contracts[0]?.co_debtor_data
                                      ?.cell_phone_number
                                      ? formatUSCell(
                                          data?.contracts[0]?.co_debtor_data
                                            ?.cell_phone_number
                                        )
                                      : ""}
                                  </dd>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      </dl>
                    </div>
                  </div>
                </div>

                <div className="my-2">
                  <div className="px-4 py-5 sm:px-0">
                    <div className="text-xl font-semibold text-gray-900">
                      Contract Information
                    </div>
                    <p className="max-w-2xl text-sm text-gray-500">
                      Contract Details and Informations
                    </p>
                  </div>

                  <div className="border p-3 rounded-lg shadow-md">
                    <div className="border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Firm Name
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.contracts &&
                              `${data.contracts[0].attorney.firm.firm_name}   [ ${data.contracts[0].attorney.firm.firm_code} ]`}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Attorney
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.contracts &&
                              `${data.contracts[0].attorney.user.first_name}  ${data.contracts[0].attorney.user.last_name}`}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Case Number
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.contracts && data.contracts[0].case_number}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Case Type
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.contracts && data.contracts[0].case_type}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Contract Amount
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.contracts &&
                              formatUSD(data.contracts[0].contract_amount)}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Down Payment
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.contracts &&
                              formatUSD(
                                data.contracts[0].down_payment == null
                                  ? 0
                                  : data.contracts[0].down_payment
                              )}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                            Monthly Income
                          </dt>
                          <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.contracts &&
                              formatUSD(
                                data.contracts[0].client_monthly_income
                              )}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>

                <div className="my-2">
                  <div className="px-4 py-5 sm:px-0">
                    <div className="text-xl font-semibold text-gray-900">
                      Payment Schedule
                    </div>
                    <p className="max-w-2xl text-sm  text-gray-500">
                      Payment Details and Informations
                    </p>
                  </div>

                  <div className="border p-3 rounded-lg shadow-md">
                    <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          No: of Payments
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {paymentDetails.count && paymentDetails.count}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          Schedule Type
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {data.contracts &&
                            `${
                              paymentPlanString[data.contracts[0].payment_plan]
                            }`}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          Tenure
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {data.contracts &&
                            `${data.contracts[0].tenure} months`}
                        </dd>
                      </div>
                      {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                        Every
                      </dt>
                      <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {data.contracts &&
                          `${data.contracts[0].scheduled_time.date}`}
                      </dd>
                    </div> */}
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          Start Date
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {data.contracts &&
                            `${formatDate(data.contracts[0].start_date)}`}
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          End Date
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {data.contracts &&
                            `${formatDate(data.contracts[0].end_date)}`}
                        </dd>
                      </div>
                    </dl>

                    <div className="relative mt-2 overflow-x-auto shadow-md sm:rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase">
                          <tr>
                            <th scope="col" className="px-6 py-3 bg-gray-50 ">
                              No
                            </th>
                            <th scope="col" className="px-6 py-3 bg-gray-50 ">
                              PAYMENT DUE DATE
                            </th>
                            <th scope="col" className="px-6 py-3">
                              PAYMENT AMOUNT
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentDetails.data &&
                            paymentDetails.data.map((emi, index) => (
                              // <div key={index}>1</div>
                              <tr
                                key={index}
                                className="border-b border-gray-200"
                              >
                                <td className="px-6 py-4">{emi.serial_id}</td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50">
                                  {formatDate(emi.date)}
                                </td>
                                <td className="px-6 py-4">
                                  {formatUSD(emi.amount)}
                                </td>
                              </tr>
                            ))}

                          {/* <tr  className="border-b border-gray-200">
                            <td className="px-6 py-4">{role}</td>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50">
                              fg
                            </td>
                            <td className="px-6 py-4">gg</td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex flex-1 mt-2 justify-between sm:justify-end">
                      <div
                        onClick={() => {
                          if (page !== 0) {
                            setPage(page - 1);
                          }
                        }}
                        className={`relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                          page === 0
                            ? "cursor-not-allowed opacity-50"
                            : "hover:cursor-pointer"
                        }`}
                        disabled={page === 0}
                      >
                        Previous
                      </div>
                      <div
                        onClick={() => {
                          if (Math.ceil(count / rowPerpage) - 1 > page) {
                            setPage(page + 1);
                          }
                        }}
                        className={`relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                          Math.ceil(count / rowPerpage) - 1 <= page
                            ? "cursor-not-allowed opacity-50"
                            : "hover:cursor-pointer"
                        }`}
                        disabled={Math.ceil(count / rowPerpage) - 1 <= page}
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-2">
                  <div className="px-4 py-5 sm:px-0">
                    <div className="text-xl font-semibold text-gray-900">
                      Uploaded Files
                    </div>
                    <p className="max-w-2xl text-sm text-gray-500">
                      Files and Documents uploaded
                    </p>
                  </div>

                  <div className="border p-3 rounded-lg shadow-md">
                    <div className="px-4 py-5 sm:px-0">
                      <div className="text-xl font-semibold text-gray-900">
                        Income Document
                      </div>
                    </div>
                    {data.files && data.files.length ? (
                      <div className="border-gray-100">
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {data.files && (
                            <ul
                              role="list"
                              className={`divide-y divide-gray-100 rounded-md ${
                                data.files.length
                                  ? `border border-gray-200`
                                  : ""
                              }`}
                            >
                              {data?.files.map((file, index) => (
                                <li
                                  key={index}
                                  className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 "
                                >
                                  <div className="flex w-0 flex-1 items-center">
                                    <PaperClipIcon
                                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                      <span className="truncate font-medium">
                                        {/* {extractPath(file)} */}
                                        {file.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex items-center">
                                    <div
                                      onClick={() => {
                                        window.open(
                                          // URL.createObjectURL(file),
                                          `${domainUrl}${removeFirstSlash(
                                            file.path
                                          )}`,
                                          "_blank"
                                        );
                                      }}
                                      className="flex text-indigo-700  cursor-pointer border-indigo-900 border-2 pl-3 pr-3 rounded"
                                    >
                                      View
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </dd>
                      </div>
                    ) : (
                      <div className="italic text-base text-center">
                        No files uploaded
                      </div>
                    )}
                  </div>
                  <div className="border p-3 rounded-lg shadow-md mt-4">
                    <div className="px-4 py-5 sm:px-0">
                      <div className="text-xl font-semibold text-gray-900">
                        Additional Files
                      </div>
                    </div>
                    {data.other_files && data?.other_files.length ? (
                      <div className="border-gray-100">
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {data.other_files && (
                            <ul
                              role="list"
                              className={`divide-y divide-gray-100 rounded-md ${
                                data.other_files.length
                                  ? `border border-gray-200`
                                  : ""
                              }`}
                            >
                              {data?.other_files.map((file, index) => (
                                <li
                                  key={index}
                                  className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 "
                                >
                                  <div className="flex w-0 flex-1 items-center">
                                    <PaperClipIcon
                                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                      <span className="truncate font-medium">
                                        {/* {extractPath(file)} */}
                                        {file.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex items-center">
                                    <div
                                      onClick={() => {
                                        window.open(
                                          // URL.createObjectURL(file),
                                          `${domainUrl}${removeFirstSlash(
                                            file.path
                                          )}`,
                                          "_blank"
                                        );
                                      }}
                                      className="flex text-indigo-700  cursor-pointer border-indigo-900 border-2 pl-3 pr-3 rounded"
                                    >
                                      View
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </dd>
                      </div>
                    ) : (
                      <div className="italic text-base text-center">
                        No files uploaded
                      </div>
                    )}
                  </div>
                  <div className="border p-3 rounded-lg shadow-md mt-4">
                    <div className="px-4 py-5 sm:px-0">
                      <div className="text-xl font-semibold text-gray-900">
                        Contract Files
                      </div>
                    </div>
                    {data?.uploaded_contracts &&
                    data?.uploaded_contracts.length ? (
                      <div className="border-gray-100">
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.uploaded_contracts && (
                            <ul
                              role="list"
                              className={`divide-y divide-gray-100 rounded-md ${
                                data?.uploaded_contracts.length
                                  ? `border border-gray-200`
                                  : ""
                              }`}
                            >
                              {data?.uploaded_contracts.map((file, index) => (
                                <li
                                  key={index}
                                  className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 "
                                >
                                  <div className="flex w-0 flex-1 items-center">
                                    <PaperClipIcon
                                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                      <span className="truncate font-medium">
                                        {/* {extractPath(file)} */}
                                        {file.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex items-center">
                                    <div
                                      onClick={() => {
                                        window.open(
                                          // URL.createObjectURL(file),
                                          `${domainUrl}${removeFirstSlash(
                                            file.path
                                          )}`,
                                          "_blank"
                                        );
                                      }}
                                      className="flex text-indigo-700  cursor-pointer border-indigo-900 border-2 pl-3 pr-3 rounded"
                                    >
                                      View
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </dd>
                      </div>
                    ) : (
                      <div className="italic text-base text-center">
                        No files uploaded
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="my-2">
                <div className="px-4 py-5 sm:px-0">
                  <div className="text-xl font-semibold text-gray-900">
                    Account Details
                  </div>
                  <p className="max-w-2xl text-sm text-gray-500">
                    Bank Account and Card Informations
                  </p>
                </div>

                <div className="border p-3 rounded-lg shadow-md">
                  <div className="border-gray-100">
                    <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          Name on Debit Card/Account
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          --
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          Card No
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          --
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          Card Expires (MM/YY)
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          --
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          CVV/CVC
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          --
                        </dd>
                      </div>

                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          Account Number
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          --
                        </dd>
                      </div>
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-[#b6a0b3]">
                          Routing Number
                        </dt>
                        <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          --
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </motion.div>
      )}
      <ModalComponent open={openActionModal} handleClose={() => {}}>
        <div className="w-full flex justify-center items-center sm:min-w-[600px]">
          <div className="w-full relative transform rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
            <div className="w-full flex justify-end">
              <span
                className="border hover:cursor-pointer"
                onClick={() => {
                  setOpenActionModal(false);
                }}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="48"
                    height="48"
                    fill="white"
                    fill-opacity="0.01"
                  />
                  <path
                    d="M14 14L34 34"
                    stroke="#333"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 34L34 14"
                    stroke="#333"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="p-2">
              <h4 className="text-2xl font-bold border-b-2 border-gray-400">
                Notes
              </h4>
              <div className="overflow-hidden">
                <p className="text-base text-gray-500 w-full break-all min-h-[100px] max-h-[300px] overflow-x-auto">
                  {data.contracts
                    ? data.contracts[0].remarks
                    : "No remark were added"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default ViewRejectedClient;
