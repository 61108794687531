import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../constants";
import toast from "react-hot-toast";

export const getAddFirmDraftGet = createAsyncThunk(
  "getAddFirmDraftGet",
  async (thunkAPI) => {
    const access_token = localStorage.getItem("access_token");

    try {
      const res = await axios.get(`${domainUrl}draft?type=add-firm`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      console.log("this is  res", res.data);
      console.log(access_token);
      return res.data;
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        const navigate = thunkAPI.extra.navigateCallback;
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      } else {
        throw error.response.data.detail;
      }
    }
  }
);

const getAddFirmDraftSlice = createSlice({
  name: "getAddFirmDraftStorage",
  initialState: { data: [], isLoading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddFirmDraftGet.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAddFirmDraftGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getAddFirmDraftGet.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const {} = getAddFirmDraftSlice.actions;
export default getAddFirmDraftSlice.reducer;
