import React, { useEffect, useState, useMemo } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import {
  domainUrl,
  logOutHandler,
  tokenExpiryValidate,
} from "../utils/constants";
import toast from "react-hot-toast";
import {
  Hourglass,
  MagnifyingGlass,
  Oval,
  ThreeDots,
} from "react-loader-spinner";
import { IconButton, InputAdornment } from "@mui/material";
import ModalComponent from "../Components/ModalComponent";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function FirmPayouts() {
  const [loader, setLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);

  const access_token = localStorage.getItem("access_token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [payoutLoaders, setPayoutLoaders] = useState({});
  const [submitPayoutLoaders, setSubmitPayoutLoaders] = useState(false);
  const [prevPaymentLoader, setPrevPaymentLoader] = useState({});
  const [prevPaymentData, setPrevPaymentData] = useState([]);
  const [batchPayoutLoaders, setBatchPayoutLoaders] = useState(false);
  const [submitBatchPayoutLoader, setSubmitBatchPayoutLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openEscrowAdjustModal, setOpenEscrowAdjustModal] = useState(false);
  const [openBatchEscrowAdjustModal, setOpenBatchEscrowAdjustModal] =
    useState(false);
  const [openPrevPaymentModal, setOpenPrevPaymentModal] = useState(false);
  const [escrowAdjust, setEscrowAdjust] = useState({
    contract_amount: 0,
    total_payout_advanced: "",
    escrow_withheld: "",
    manual_escrow_offset: "",
    na_fee:"",
    status: "",
    transaction_id: "",
    remarks: "",
  });
  const [escrowAdjustDetails, setEscrowAdjustDetails] = useState({});
  const [escrowIndividualErrors, setEscrowIndividualErrors] = useState({
    totalPayoutAdvanced: "",
    escrowWithheld: "",
    manualEscrowOffset: "",
  });
  const [payoutStatusFlag, setPayoutStatusFlag] = useState("");
  const [batchEscrowAdjustData, setBatchEscrowAdjustData] = useState([]);
  const[escrowBatchErrors,setEscrowBatchErrors]=useState([])
  const [batchEscrowAdjust, setBatchEscrowAdjust] = useState({
    total_payout_advanced: 0,
    escrow_withheld: 0,
    manual_escrow_offset: 0,
    status: "",
    transaction_id: "",
    remarks: "",
  });

  const getInitialData = async (value) => {
    try {
      setLoader(true);
      const res = await axios.get(`${domainUrl}firm-payout`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        console.log(res.data);
        setData(res.data);

        let tempChecked = res.data;
        res.data.forEach((firm, firm_index) => {
          tempChecked[firm_index].checked = false;
          tempChecked[firm_index].expanded = false;

          firm.attorneys.forEach((attorney, attorney_index) => {
            tempChecked[firm_index].attorneys[attorney_index].checked = false;
            tempChecked[firm_index].attorneys[attorney_index].expanded = false;

            attorney.clients.forEach((client, client_index) => {
              tempChecked[firm_index].attorneys[attorney_index].clients[
                client_index
              ].checked = false;
            });
          });
        });
        console.log(tempChecked);
        setData(tempChecked);
        setLoader(false);

        // set escrowAdjust spread

        let tempEscrowDetail = res.data;

        res.data.forEach((firm, firmIndex) => {
          console.log(firm);
          console.log(tempEscrowDetail[firmIndex].escrow_recovered);
        });

        // setEscrowAdjustDetails

        // toast.success("Data Loaded", { id: 1 });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setLoader(false);
      toast.error("Error Occured", { id: 1 });
    }
  };

  const getPreviousPaymentHandler = async (client_id) => {
    try {
      setPrevPaymentLoader({ ...prevPaymentLoader, [client_id]: true });

      const res = await axios.get(
        `${domainUrl}client-payment-history?id=${client_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setPrevPaymentData(res.data);
        setPrevPaymentLoader({ ...prevPaymentLoader, [client_id]: false });
        setOpenPrevPaymentModal(true);

        // toast.success(res.data.message, { id: 1 });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setPrevPaymentLoader({ ...prevPaymentLoader, [client_id]: false });
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const expandedFirms = () => {
    let firms = [];
    data.forEach((firm) => {
      if (firm.expanded) {
        firms.push(firm.id);
      }
    });
    return firms;
  };

  const expandedAttorneys = () => {
    let attorneys = [];
    data.forEach((firm) => {
      firm.attorneys.forEach((attorney) => {
        if (attorney.expanded) attorneys.push(attorney.id);
      });
    });
    return attorneys;
  };

  const searchDataHandler = async (value) => {
    setSearchValue(value);

    let selectedAttorney = expandedAttorneys();
    let selectedFirm = expandedFirms();
    try {
      setSearchLoader(true);
      const res = await axios.get(`${domainUrl}firm-payout?search=${value}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        console.log(res.data);
        setData(res.data);
        if (res.data.length == 0 && value != "") {
          toast.error("No such firm is available", { id: 1 });
        } else {
          // toast.success("Data Loaded", { id: 1 });
        }

        let tempChecked = res.data;
        res.data.forEach((firm, firm_index) => {
          tempChecked[firm_index].checked = false;
          tempChecked[firm_index].expanded = selectedFirm.includes(firm.id)
            ? true
            : false;

          firm.attorneys.forEach((attorney, attorney_index) => {
            tempChecked[firm_index].attorneys[attorney_index].checked = false;
            tempChecked[firm_index].attorneys[attorney_index].expanded =
              selectedAttorney.includes(attorney.id) ? true : false;

            attorney.clients.forEach((client, client_index) => {
              tempChecked[firm_index].attorneys[attorney_index].clients[
                client_index
              ].checked = false;
            });
          });
        });
        console.log(tempChecked);
        setData(tempChecked);
        setSearchLoader(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setSearchLoader(false);
      toast.error("Error Occured", { id: 1 });
    }
  };
  const handleClearSearch = () => {
    searchDataHandler("");
  };

  const handleCheckboxChange = (
    firmIndex,
    attorneyIndex,
    clientIndex,
    isChecked
  ) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[firmIndex].attorneys[attorneyIndex].clients[clientIndex].checked =
        isChecked;

      // Check if all clients are checked, then update attorney
      const allClientsChecked = newData[firmIndex].attorneys[
        attorneyIndex
      ].clients.every((client) => client.checked);
      newData[firmIndex].attorneys[attorneyIndex].checked = allClientsChecked;

      // Check if all attorneys are checked, then update firm
      const allAttorneysChecked = newData[firmIndex].attorneys.every(
        (attorney) => attorney.checked
      );
      newData[firmIndex].checked = allAttorneysChecked;

      return newData;
    });
  };
  const handleFirmExpanded = (firmIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[firmIndex].expanded = !newData[firmIndex].expanded;
      return newData;
    });
  };

  const handleFirmCheckboxChange = (firmIndex, isChecked) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[firmIndex].checked = isChecked;

      // Update all attorneys and clients under the firm
      newData[firmIndex].attorneys.forEach((attorney) => {
        attorney.checked = isChecked;
        attorney.clients.forEach((client) => {
          client.checked = isChecked;
        });
      });

      return newData;
    });
  };
  const handleAttorneyExpanded = (firmIndex, attorneyIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[firmIndex].attorneys[attorneyIndex].expanded =
        !newData[firmIndex].attorneys[attorneyIndex].expanded;
      return newData;
    });
  };

  const handleAttorneyCheckboxChange = (
    firmIndex,
    attorneyIndex,
    isChecked
  ) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[firmIndex].attorneys[attorneyIndex].checked = isChecked;

      // Update all clients under the attorney
      newData[firmIndex].attorneys[attorneyIndex].clients.forEach((client) => {
        client.checked = isChecked;
      });

      // Check if all attorneys are checked, then update firm
      const allAttorneysChecked = newData[firmIndex].attorneys.every(
        (attorney) => attorney.checked
      );
      newData[firmIndex].checked = allAttorneysChecked;

      return newData;
    });
  };

  const isClientSelected = () => {
    let flag = false;
    data.forEach((firm) => {
      firm.attorneys.forEach((attorney) => {
        attorney.clients.forEach((client) => {
          if (client.checked) {
            flag = true;
          }
        });
      });
    });
    return flag;
  };

  const filterPositiveDecimal = (value) => {
    const validPositiveDecimalRegex = /^\d+(\.\d*)?$/;
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts.slice(0, 2).join(".") + parts.slice(2).join("");
    }
    const match = value.match(validPositiveDecimalRegex);
    return match ? match[0] : value.slice(0, -1);
  };
  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    // console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      // console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  // const handleEscrowAdjustChange = (e) => {
  //   if (["transaction_id", "remarks"].includes(e.target.name)) {
  //     setEscrowAdjust({
  //       ...escrowAdjust,
  //       [e.target.name]: e.target.value,
  //     });
  //   } else {
     

  //     setEscrowAdjust({
  //       ...escrowAdjust,
  //       // [e.target.name]: parseFloat(filterPositiveDecimal(e.target.value)),
  //       [e.target.name]: e.target.value,
  //     });
  //     console.log(e.target.value);
  //   }

  //   console.log(
  //     escrowAdjust.total_payout_advanced,
  //     escrowAdjust.escrow_withheld,
  //     escrowAdjust.manual_escrow_offset
  //   );
  // };


  const handleEscrowAdjustChange = (e) => {
    const { name, value } = e.target;
    const updatedEscrowAdjust = { ...escrowAdjust, [name]: value };
  
    // Update the state
    setEscrowAdjust(updatedEscrowAdjust);
  
    // Log the updated value and current state
    console.log(value);
    console.log(
      updatedEscrowAdjust.total_payout_advanced,
      updatedEscrowAdjust.escrow_withheld,
      updatedEscrowAdjust.manual_escrow_offset
    );
  
    // Only perform validation for fields other than "transaction_id" and "remarks"
    if (!["transaction_id", "remarks"].includes(name)) {
      const totalAdvanced = parseFloatNumber(String(updatedEscrowAdjust.total_payout_advanced).replace(/[$,]/g, ""));
      const naFees = parseFloatNumber(String(updatedEscrowAdjust.na_fee).replace(/[$,]/g, ""));
      const escrowContribution = parseFloatNumber(String(updatedEscrowAdjust.escrow_withheld).replace(/[$,]/g, ""));
      const escrowOffset = parseFloatNumber(String(updatedEscrowAdjust.manual_escrow_offset).replace(/[$,]/g, ""));
      const sumOfFees = naFees + escrowContribution + escrowOffset;
  
      let newErrors = {
        totalPayoutAdvanced: "",
        escrowWithheld: "",
        manualEscrowOffset: "",
      };
  
      if (totalAdvanced > parseFloatNumber(escrowAdjust.contract_amount)) {
        newErrors.totalPayoutAdvanced = "Greater than contract amount";
      }
  
      if (escrowContribution + escrowOffset > totalAdvanced) {
        newErrors.escrowWithheld = "Enter Valid Escrow Contribution";
        newErrors.manualEscrowOffset = "Enter Valid Escrow Offset";
      }
  
      if (totalAdvanced <= sumOfFees) {
        newErrors.totalPayoutAdvanced = "Must exceed NA Fees, Escrow Contribution, and Escrow Offset together";
      }
  
      setEscrowIndividualErrors(newErrors);
    }
  };
  









  const escrowAdjustHandler = (client) => {
    console.log(client);
    const firmData = data.find((firm) => {
      return firm.attorneys.some((attorney) => {
        return attorney.clients.some((c) => c.client_id === client.client_id);
      });
    });

    // Extract escrow details from the found firm data
    const escrowWithheld = firmData ? firmData.escrow_withheld : 0;
    const escrowRecovered = firmData ? firmData.escrow_recovered : 0;

    setEscrowAdjust({
      ...escrowAdjust,
      total_payout_advanced: String(client.total_payout_advanced),
      escrow_withheld: String(client.escrow_withheld),
      manual_escrow_offset: String(client.escrow_offset),
      contract_amount: client.contract_amount,
      na_fee:client.na_fee
    });
    setPayoutLoaders({ ...payoutLoaders, [client.id]: true });
    setOpenEscrowAdjustModal(true);
    setEscrowAdjustDetails(client);
    setEscrowAdjustDetails({
      ...client,
      firm_escrow_withheld: escrowWithheld,
      firm_escrow_recovered: escrowRecovered,
    });
  };

  const handleIndividualSubmit = () => {
    

    if (!Object.values(escrowIndividualErrors).some((msg) => msg !== "")) {
      sendIndividual(escrowAdjustDetails.id);
    }
  };

  const sendIndividual = async (client_id) => {
    console.log(client_id);
    // setOpenEscrowAdjustModal(false);

    setPayoutLoaders({ ...payoutLoaders, [client_id]: true });
    setSubmitPayoutLoaders(true);

    if (payoutStatusFlag == "processing") {
      try {
        const res = await axios.put(
          `${domainUrl}firm-payout?id=${client_id}`,
          {
            total_payout_advanced: parseFloatNumber(
              String(escrowAdjust.total_payout_advanced).replace(/[$,]/g, "")
            ),
            escrow_withheld: parseFloatNumber(
              String(escrowAdjust.escrow_withheld).replace(/[$,]/g, "")
            ),
            manual_escrow_offset: parseFloatNumber(
              String(escrowAdjust.manual_escrow_offset).replace(/[$,]/g, "")
            ),
            status: escrowAdjust.status,
            transaction_id: escrowAdjust.transaction_id,
            remarks: escrowAdjust.remarks,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          searchDataHandler("");
          setOpenEscrowAdjustModal(false);
          setEscrowAdjust({
            ...escrowAdjust,
            total_payout_advanced: 0,
            escrow_withheld: 0,
            manual_escrow_offset: 0,
            na_fee: "",
            status: "",
            transaction_id: "",
            remarks: "",
          });
          setPayoutLoaders({ ...payoutLoaders, [client_id]: false });
          setSubmitPayoutLoaders(false);
          toast.success(res.data.message, { id: 1 });
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setPayoutLoaders({ ...payoutLoaders, [client_id]: false });
        setSubmitPayoutLoaders(false);
        setPayoutStatusFlag("");
      }
    } else {
      try {
        const res = await axios.post(
          `${domainUrl}firm-payout?id=${client_id}`,
          {
            total_payout_advanced: parseFloatNumber(
              String(escrowAdjust.total_payout_advanced).replace(/[$,]/g, "")
            ),
            escrow_withheld: parseFloatNumber(
              String(escrowAdjust.escrow_withheld).replace(/[$,]/g, "")
            ),
            manual_escrow_offset: parseFloatNumber(
              String(escrowAdjust.manual_escrow_offset).replace(/[$,]/g, "")
            ),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          const wellsFargoUrl = "https://www.wellsfargo.com/";
          window.open(wellsFargoUrl, "_blank");

          console.log(res.data);
          setPayoutStatusFlag("processing");
          // setEscrowAdjust({
          //   ...escrowAdjust,
          //   total_payout_advanced: 0,
          //   escrow_withheld: 0,
          //   manual_escrow_offset: 0,
          // });
          setPayoutLoaders({ ...payoutLoaders, [client_id]: false });
          setSubmitPayoutLoaders(false);
          toast.success(res.data.message, { id: 1 });
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setPayoutLoaders({ ...payoutLoaders, [client_id]: false });
        setSubmitPayoutLoaders(false);
        setPayoutStatusFlag("");
      }
    }
  };

  const escrowBatchAdjustHandler = () => {
    let ids = [];

    let tempData = [];

    data.forEach((firm) => {
      const firmData = {
        firm_name: firm.firm_name,
        firm_code: firm.firm_code,
      };

      firm.attorneys.forEach((attorney) => {
        const attorneyData = {
          ...firmData,
          attorney_name: attorney.attorney_name,
          attorney_code: attorney.attorney_code,
        };

        attorney.clients.forEach((client) => {
          if (client.checked) {
            const clientData = {
              ...attorneyData,
              id: client.id,
              client_name: client.client_name,
              client_code: client.client_code,
              contract_amount: client.contract_amount,
              total_payout_advanced: String(client.total_payout_advanced),
              escrow_withheld: String(client.escrow_withheld),
              status: client.status,
              na_fee_percentage: client.na_fee_percentage,
              na_fee: client.na_fee,
              manual_escrow_offset: String(0),
              total_payable_amount:
                client.total_payout_advanced - client.escrow_withheld,
            };

            tempData.push(clientData);
            ids.push(client.id);
          }
        });
      });
    });

    console.log("Temp Data", tempData);
    setBatchEscrowAdjustData(tempData);
    console.log(ids);
    setBatchPayoutLoaders(true);
    setOpenBatchEscrowAdjustModal(true);
  };

  const parseFloatNumber = (value) => {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  };
  const sumTotalPayableAmount = () => {
    console.log(batchEscrowAdjustData);
    let amount = 0;
    batchEscrowAdjustData.forEach((value) => {
      amount = amount + parseFloatNumber(value.total_payable_amount);
    });
    return amount;
  };
  const handleBatchEscrowAdjustChange = (e, index) => {
    const { name, value } = e.target;

    setBatchEscrowAdjustData((prevData) => {
        const newData = [...prevData];
        let total_payable_amount =
        e.target.name === "total_payout_advanced"
          ? parseFloatNumber(String(e.target.value).replace(/[$,]/g, "")) -
            (parseFloatNumber(
              String(newData[index].escrow_withheld).replace(/[$,]/g, "")
            ) +
              parseFloatNumber(
                String(newData[index].manual_escrow_offset).replace(/[$,]/g, "")
              ))
          : e.target.name === "escrow_withheld"
          ? parseFloatNumber(
              String(newData[index].total_payout_advanced).replace(/[$,]/g, "")
            ) -
            (parseFloatNumber(String(e.target.value).replace(/[$,]/g, "")) +
              parseFloatNumber(
                String(newData[index].manual_escrow_offset).replace(/[$,]/g, "")
              ))
          : parseFloatNumber(
              String(newData[index].total_payout_advanced).replace(/[$,]/g, "")
            ) -
            (parseFloatNumber(String(e.target.value).replace(/[$,]/g, "")) +
              parseFloatNumber(
                String(newData[index].escrow_withheld).replace(/[$,]/g, "")
              ));


        // Update newData[index] based on the changed input
        newData[index] = {
          ...newData[index],
          [name]: value,
        ["total_payable_amount"]: total_payable_amount,
      };

        // Calculate relevant values for validation
        const totalAdvanced = parseFloatNumber(String(newData[index].total_payout_advanced).replace(/[$,]/g, ""));
        const naFees = parseFloatNumber(String(newData[index].na_fee).replace(/[$,]/g, ""));
        const escrowContribution = parseFloatNumber(String(newData[index].escrow_withheld).replace(/[$,]/g, ""));
        const escrowOffset = parseFloatNumber(String(newData[index].manual_escrow_offset).replace(/[$,]/g, ""));
        const sumOfFees = naFees + escrowContribution + escrowOffset;
        const contractAmount = parseFloatNumber(newData[index].contract_amount);

        // Initialize newErrors array
        let newErrors = [...escrowBatchErrors];

        // Ensure newErrors array has enough slots for each element
        while (newErrors.length <= index) {
            newErrors.push({
                totalPayoutAdvanced: "",
                escrowWithheld: "",
                manualEscrowOffset: "",
            });
        }

        // Perform validations
        if (totalAdvanced > contractAmount) {
            newErrors[index].totalPayoutAdvanced = "Greater than contract amount";
        } else {
            newErrors[index].totalPayoutAdvanced = "";
        }

        if (escrowContribution + escrowOffset > totalAdvanced) {
            newErrors[index].escrowWithheld = "Enter Valid Escrow Contribution";
            newErrors[index].manualEscrowOffset = "Enter Valid Escrow Offset";
        } else {
            newErrors[index].escrowWithheld = "";
            newErrors[index].manualEscrowOffset = "";
        }

        if (totalAdvanced <= sumOfFees) {
            newErrors[index].totalPayoutAdvanced = "Must exceed NA Fees, Escrow Contribution,and Escrow Offset together";
        }

        // Set errors state
        setEscrowBatchErrors(newErrors);

        return newData;
    });
};


  const sendBatch = async () => {
    try {
      let ids = [];

      data.forEach((firm) => {
        firm.attorneys.forEach((attorney) => {
          attorney.clients.forEach((client) => {
            if (client.checked) {
              ids.push(client.id);
            }
          });
        });
      });

      console.log(ids);
      setOpenBatchEscrowAdjustModal(true);
      setSubmitBatchPayoutLoader(true);
      let submitData = [];
      let convert_keys = [
        "total_payout_advanced",
        "manual_escrow_offset",
        "escrow_withheld",
      ];
      batchEscrowAdjustData.forEach((value) => {
        let singleData = {};
        Object.keys(value).forEach((key) => {
          console.log(key);
          if (convert_keys.includes(key)) {
            singleData[key] = parseFloatNumber(
              String(value[key]).replace(/[$,]/g, "")
            );
          } else {
            singleData[key] = value[key];
          }
        });
        submitData.push(singleData);
      });
      console.log(submitData);
      console.log(batchEscrowAdjustData);

      const res = await axios.post(
        `${domainUrl}firm-payout?ids=${ids}`,
        { data: submitData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setBatchPayoutLoaders(false);
        setSubmitBatchPayoutLoader(false);
        searchDataHandler("");
        const wellsFargoUrl = "https://www.wellsfargo.com/";
        window.open(wellsFargoUrl, "_blank");
        toast.success("Batch Payout has been initated", { id: 1 });
        setOpenBatchEscrowAdjustModal(false);
        ids = [];
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setBatchPayoutLoaders(false);
      setOpenBatchEscrowAdjustModal(false);
      setSubmitBatchPayoutLoader(false);
    }
  };

  return (
    <div>
      {loader ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Firm Payouts
              </h2>
              <div className="border p-2 rounded-lg shadow-md">
                <div className="w-full py-2 flex justify-between items-center gap-3">
                  <div>
                    {batchPayoutLoaders ? (
                      <button
                        className={`rounded text-white p-2 text-sm bg-blue-800`}
                      >
                        <div className="w-full flex  items-center gap-2">
                          <div className="inline-flex items-center gap-2">
                            Batch Payout
                            <ThreeDots
                              height={20}
                              width={20}
                              radius="9"
                              color="#ffff"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        </div>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          escrowBatchAdjustHandler();
                        }}
                        disabled={!isClientSelected()}
                        className={`rounded text-white p-2 text-sm ${
                          isClientSelected()
                            ? "bg-blue-600 hover:bg-blue-800"
                            : "bg-blue-300"
                        }`}
                      >
                        <div className="w-full flex  items-center gap-2">
                          <div className="inline-flex items-center gap-2">
                            Batch Payout <CurrencyExchangeIcon />
                          </div>
                        </div>
                      </button>
                    )}
                  </div>
                  <div>
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        id="input-with-sx"
                        label="Search firm"
                        variant="standard"
                        value={searchValue}
                        onChange={(e) => searchDataHandler(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {searchValue && (
                                <IconButton
                                  size="small"
                                  onClick={handleClearSearch}
                                  edge="end"
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </div>
                </div>

                {searchLoader ? (
                  <div className="w-full  flex justify-center items-center">
                    <MagnifyingGlass
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="MagnifyingGlass-loading"
                      wrapperStyle={{}}
                      wrapperClass="MagnifyingGlass-wrapper"
                      glassColor="#c0efff"
                      color="#e15b64"
                    />
                  </div>
                ) : (
                  <>
                    {data?.length ? (
                      data?.map((firm, firm_index) => (
                        <Accordion
                          key={firm_index}
                          expanded={firm.expanded}
                          onChange={(e) => {
                            if (!e.target.name) {
                              handleFirmExpanded(firm_index);
                              console.log("++", e.target.name);
                            }
                          }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <input
                              id={`checked-firm-${firm_index}`}
                              name={`checked-firm-${firm_index}`}
                              type="checkbox"
                              checked={firm.checked}
                              onChange={(e) => {
                                handleFirmCheckboxChange(
                                  firm_index,
                                  e.target.checked
                                );
                                console.log("##", e.target.name);
                              }}
                              className="h-4 w-4 mx-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 hover:cursor-pointer"
                            />
                            <Typography>{`${firm.firm_name} - (${firm.firm_code})`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="w-full">
                              <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-black sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                                <div className="flex flex-col gap-y-3 border-l border-black/10 pl-6">
                                  <dt className="text-sm leading-6">
                                    Firm Name
                                  </dt>
                                  <dd className="order-first text-3xl font-semibold tracking-tight">
                                    {firm?.firm_name}
                                  </dd>
                                </div>
                                <div className="flex flex-col gap-y-3 border-l border-black/10 pl-6">
                                  <dt className="text-sm leading-6">
                                    Total Contract Value
                                  </dt>
                                  <dd className="order-first text-3xl font-semibold tracking-tight">
                                    {formatUSD(firm?.total_contract_value)}
                                  </dd>
                                </div>
                                <div className="flex flex-col gap-y-3 border-l border-black/10 pl-6">
                                  <dt className="text-sm leading-6">
                                    Escrow Withheld
                                  </dt>
                                  <dd className="order-first text-3xl font-semibold tracking-tight">
                                    {formatUSD(firm?.escrow_withheld)}
                                  </dd>
                                </div>
                                <div className="flex flex-col gap-y-3 border-l border-black/10 pl-6">
                                  <dt className="text-sm leading-6">
                                    Escrow Recovered
                                  </dt>
                                  <dd className="order-first text-3xl font-semibold tracking-tight">
                                    {formatUSD(firm?.escrow_recovered)}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                          </AccordionDetails>
                          <AccordionDetails>
                            <div className="my-2 font-bold">Attorneys:</div>

                            {firm.attorneys.length &&
                              firm.attorneys.map((attorney, attorney_index) => (
                                <Accordion
                                  key={attorney_index}
                                  expanded={attorney.expanded}
                                  onChange={(e) => {
                                    if (!e.target.name) {
                                      handleAttorneyExpanded(
                                        firm_index,
                                        attorney_index
                                      );
                                      console.log("++", e.target.name);
                                    }
                                  }}
                                >
                                  <AccordionSummary
                                    aria-controls="panel2d-content"
                                    id="panel2d-header"
                                  >
                                    <input
                                      id={`checked-attorney-${firm_index}-${attorney_index}`}
                                      name={`checked-attorney-${firm_index}-${attorney_index}`}
                                      type="checkbox"
                                      checked={attorney.checked}
                                      onChange={(e) =>
                                        handleAttorneyCheckboxChange(
                                          firm_index,
                                          attorney_index,
                                          e.target.checked
                                        )
                                      }
                                      className="h-4 w-4 mx-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 hover:cursor-pointer"
                                    />
                                    <Typography>{`${attorney.attorney_name} - (${attorney.attorney_code})`}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      p: "0px",
                                      backgroundColor: "lightgray",
                                    }}
                                  >
                                    {attorney.clients.length &&
                                      attorney.clients.map(
                                        (client, client_index) => (
                                          <Accordion
                                            key={client_index}
                                            expanded={true}
                                          >
                                            <div className="w-full my-2">
                                              <div className="w-full border border-gray-500 rounded-md shadow-lg sm:p-2">
                                                <div className="mt-2">
                                                  <div className="overflow-x-auto ">
                                                    <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                      <input
                                                        id={`checked-client-${firm_index}-${attorney_index}-${client_index}`}
                                                        name={`checked-client-${firm_index}-${attorney_index}-${client_index}`}
                                                        type="checkbox"
                                                        checked={client.checked}
                                                        onChange={(e) =>
                                                          handleCheckboxChange(
                                                            firm_index,
                                                            attorney_index,
                                                            client_index,
                                                            e.target.checked
                                                          )
                                                        }
                                                        className="h-4 w-4 mx-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 hover:cursor-pointer sm:mx-0"
                                                      />
                                                      <table className="min-w-full divide-y divide-gray-300">
                                                        <thead>
                                                          <tr>
                                                            <th
                                                              scope="col"
                                                              className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0"
                                                            >
                                                              Client Name
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                                            >
                                                              Case Type
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                                            >
                                                              Contact Start Date
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                                            >
                                                              Total Payout
                                                              Advanced
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                                            >
                                                              Portal
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                                            >
                                                              Previous Payment
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                                            >
                                                              Payout
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200">
                                                          <tr
                                                            key={client_index}
                                                          >
                                                            <td className="text-center whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-0">
                                                              {
                                                                client.client_name
                                                              }
                                                            </td>
                                                            <td className="text-center whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                              {client.case_type}
                                                            </td>
                                                            <td className="text-center whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                              {formatDate(
                                                                client.contract_start_date
                                                              )}
                                                            </td>
                                                            <td className="text-center whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                              {formatUSD(
                                                                client.total_payout_advanced
                                                              )}
                                                            </td>
                                                            <td className="text-center whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                              {client.status}
                                                            </td>
                                                            <td className="text-center whitespace-nowrap py-4 text-base text-gray-500">
                                                              <div>
                                                                {prevPaymentLoader[
                                                                  client.id
                                                                ] ? (
                                                                  <IconButton>
                                                                    <Hourglass
                                                                      visible={
                                                                        true
                                                                      }
                                                                      height="25"
                                                                      width="25"
                                                                      ariaLabel="hourglass-loading"
                                                                      wrapperStyle={{}}
                                                                      wrapperClass=""
                                                                      colors={[
                                                                        "#306cce",
                                                                        "#72a1ed",
                                                                      ]}
                                                                    />
                                                                  </IconButton>
                                                                ) : (
                                                                  <IconButton
                                                                    onClick={() => {
                                                                      getPreviousPaymentHandler(
                                                                        client.client_id
                                                                      );
                                                                    }}
                                                                  >
                                                                    <Visibility
                                                                      sx={{
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    />
                                                                  </IconButton>
                                                                )}
                                                              </div>
                                                            </td>
                                                            <td className="text-center whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                                              <div>
                                                                {payoutLoaders[
                                                                  client.id
                                                                ] ? (
                                                                  <IconButton>
                                                                    <Hourglass
                                                                      visible={
                                                                        true
                                                                      }
                                                                      height="25"
                                                                      width="25"
                                                                      ariaLabel="hourglass-loading"
                                                                      wrapperStyle={{}}
                                                                      wrapperClass=""
                                                                      colors={[
                                                                        "#306cce",
                                                                        "#72a1ed",
                                                                      ]}
                                                                    />
                                                                  </IconButton>
                                                                ) : (
                                                                  <IconButton
                                                                    onClick={() => {
                                                                      setPayoutStatusFlag(
                                                                        client.payout_status
                                                                      );
                                                                      escrowAdjustHandler(
                                                                        client
                                                                      );
                                                                    }}
                                                                  >
                                                                    <CurrencyExchangeIcon
                                                                      sx={{
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    />
                                                                  </IconButton>
                                                                )}
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion>
                                        )
                                      )}
                                  </AccordionDetails>
                                </Accordion>
                              ))}
                          </AccordionDetails>
                        </Accordion>
                      ))
                    ) : (
                      <div className="p-2 text-center text-lg italic">
                        <div>No data</div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </main>
        </main>
      )}
      <ModalComponent open={openPrevPaymentModal} handleClose={() => {}}>
        <div className="w-full rounded-md bg-white md:max-w-5xl mx-auto">
          <div className="w-full flex justify-end items-center">
            <span
              className="border hover:cursor-pointer"
              onClick={() => {
                setOpenPrevPaymentModal(false);
              }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" fill="white" fill-opacity="0.01" />
                <path
                  d="M14 14L34 34"
                  stroke="#333"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14 34L34 14"
                  stroke="#333"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div className="w-full my-4 overflow-x-auto flow-root">
            <div className="">
              <div className="inline-block w-full py-2">
                <table className="w-full divide-y divide-gray-300">
                  <thead>
                    <tr className="w-full">
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-center text-base font-semibold text-gray-900 sm:pl-0"
                      >
                        Transaction Id
                      </th>
                      {/* <th
                        scope="col"
                        className="px-3 py-3.5 text-center text-base font-semibold text-gray-900"
                      >
                        Type
                      </th> */}
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-center text-base font-semibold text-gray-900"
                      >
                        Total Contract Value
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-center text-base font-semibold text-gray-900"
                      >
                        Escrow Withheld
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-center text-base font-semibold text-gray-900"
                      >
                        Total Payout Advanced
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-center text-base font-semibold text-gray-900"
                      >
                        Amount Paid
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-center text-base font-semibold text-gray-900"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-center text-base font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-center text-base font-semibold text-gray-900"
                      >
                        Notes
                      </th>
                    </tr>
                  </thead>
                  
                  <tbody className="divide-y divide-gray-200">
                    {prevPaymentData &&
                      prevPaymentData.map((payment, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 px-3 text-center text-base font-medium text-gray-900 sm:pl-0">
                            {payment?.transaction_id
                              ? payment?.transaction_id
                              : "--"}
                          </td>
                          {/* <td className="whitespace-nowrap py-4 px-3 text-center text-base font-medium text-gray-900 sm:pl-0">
                            {payment?.type
                              ? payment?.type.toUpperCase()
                              : "--"}
                          </td> */}
                          <td className="whitespace-nowrap py-4 px-3 text-center text-base font-medium text-gray-900 sm:pl-0">
                            {payment?.current_total_contract_value
                              ? formatUSD(
                                  payment?.current_total_contract_value
                                )
                              : "--"}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-center text-base font-medium text-gray-900 sm:pl-0">
                            {payment?.escrow_withheld
                              ? formatUSD(
                                  payment?.escrow_withheld
                                )
                              : "--"}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-center text-base font-medium text-gray-900 sm:pl-0">
                            {payment?.total_payout_advanced
                              ? formatUSD(
                                  payment?.total_payout_advanced
                                )
                              : "--"}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-center text-base font-medium text-gray-900 sm:pl-0">
                            {payment?.amount_paid
                              ? formatUSD(
                                  payment?.amount_paid
                                )
                              : "--"}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-center text-base font-medium text-gray-900 sm:pl-0">
                            {payment?.paid_at
                              ? payment?.paid_at
                              : "--"}
                          </td>
                          <td
                            className={`whitespace-nowrap py-4 px-3 text-center text-base font-bold  sm:pl-0 ${
                              payment?.status == "failed"
                                ? "text-red-900"
                                : payment?.status == "paid"
                                ? "text-green-900"
                                : "text-gray-900"
                            }`}
                          >
                            {payment?.status.toUpperCase()}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-center text-base font-medium text-gray-900 sm:pl-0">
                            {payment?.remarks
                              ? payment?.remarks
                              : "--"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {!prevPaymentData.length && (
                  <div className="w-full my-4 text-center border-t italic">
                    No transactions
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent open={openEscrowAdjustModal} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-3xl">
            <div className="w-full p-5">
              <div className="w-full rounded-lg ">
                <div className="w-full">
                  <div className="w-full my-2">
                    <div className="text-2xl font-bold">Payout</div>
                    <div className="text-xs text-[#9ca0b3]"></div>
                  </div>
                  <div className="w-full rounded-md shadow-md border m-1 p-1 sm:flex justify-center items-center">
                    <div className="w-full">
                      <dl className="divide-y divide-gray-100 sm:grid sm:grid-cols-3">
                        <div className="px-2 py-6">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Client Name
                          </dt>
                          <dd className="mt-1 text-base font-bold text-gray-700">
                            {escrowAdjustDetails?.client_name}
                          </dd>
                        </div>
                        <div className="px-2 py-6">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Case Type
                          </dt>
                          <dd className="mt-1 text-base font-bold text-gray-700">
                            {escrowAdjustDetails?.case_type}
                          </dd>
                        </div>
                        <div className="px-2 py-6">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Contract Amount
                          </dt>
                          <dd className="mt-1 text-base font-bold text-gray-700">
                            {formatUSD(escrowAdjustDetails?.contract_amount)}
                          </dd>
                        </div>
                        <div className="px-2 py-6">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            NA Fee Percentage
                          </dt>
                          <dd className="mt-1 text-base font-bold text-gray-700">
                            {`${escrowAdjustDetails?.na_fee_percentage}%`}
                            <span className="text-gray-400 ml-4">
                              {`(${formatUSD(escrowAdjustDetails?.na_fee)})`}
                            </span>
                          </dd>
                        </div>
                        <div className="px-2 py-6">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Escrow Withheld
                          </dt>
                          <dd className="mt-1 text-base font-bold text-gray-700">
                            {formatUSD(
                              escrowAdjustDetails?.firm_escrow_withheld
                            )}
                          </dd>
                        </div>
                        <div className="px-2 py-6">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Escrow Recovered
                          </dt>
                          <dd className="mt-1 text-base font-bold text-gray-700">
                            {formatUSD(
                              escrowAdjustDetails?.firm_escrow_recovered
                            )}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <div className="w-full my-2 mx-1">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        console.log(escrowAdjust);
                        // sendIndividual(escrowAdjustDetails.id);
                        handleIndividualSubmit();
                      }}
                    >
                      <div className="w-full">
                        <div className="w-full  border-gray-900/10 mt-5 ">
                          <div className="w-full mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="w-full sm:col-span-3">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Total Payout Advanced
                              </label>
                              <div className="w-full mt-2">
                                <CurrencyFormat
                                  name="total_payout_advanced"
                                  id="total_payout_advanced"
                                  placeholder="$0.00"
                                  value={escrowAdjust.total_payout_advanced}
                                  onChange={handleEscrowAdjustChange}
                                  displayType={"input"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  className="w-full border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                />
                              </div>
                              <span className="text-red-500 text-xs">
                                {escrowIndividualErrors.totalPayoutAdvanced}
                              </span>
                            </div>

                            <div className="w-full sm:col-span-3">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Escrow Contribution
                              </label>
                              <div className="w-full mt-2">
                                <CurrencyFormat
                                  name="escrow_withheld"
                                  id="escrow_withheld"
                                  placeholder="$0.00"
                                  value={escrowAdjust.escrow_withheld}
                                  onChange={handleEscrowAdjustChange}

                                  displayType={"input"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  className="w-full border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                />
                              </div>
                              <span className="text-red-500 text-xs">
                                {escrowIndividualErrors.escrowWithheld}
                              </span>
                            </div>

                            <div className="w-full sm:col-span-3">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Manual Escrow Offset
                              </label>
                              <div className="w-full mt-2">
                                <CurrencyFormat
                                  name="manual_escrow_offset"
                                  id="manual_escrow_offset"
                                  placeholder="$0.00"
                                  value={escrowAdjust.manual_escrow_offset}
                                  onChange={handleEscrowAdjustChange}

                                  displayType={"input"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  className="w-full border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                />
                              </div>
                              <span className="text-red-500 text-xs">
                                {escrowIndividualErrors.manualEscrowOffset}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex justify-start gap-2 mt-6">
                          <div className="inline-flex items-center justify-center gap-2 text-base font-semibold">
                            Total Amount Payable:
                            <span className="text-2xl font-bold">
                              {/* {formatUSD(
                                parseFloat(
                                  String(
                                    escrowAdjust.total_payout_advanced
                                  ).replace(/[$,]/g, "")
                                ) -
                                  (parseFloat(
                                    String(
                                      escrowAdjust.manual_escrow_offset
                                    ).replace(/[$,]/g, "")
                                  ) +
                                    parseFloat(
                                      String(
                                        escrowAdjust.escrow_withheld
                                      ).replace(/[$,]/g, "")
                                    ))
                              )} */}
                              {formatUSD(
                                (escrowAdjust.total_payout_advanced !== ""
                                  ? parseFloatNumber(
                                      String(
                                        escrowAdjust.total_payout_advanced
                                      ).replace(/[$,]/g, "")
                                    )
                                  : 0) -
                                  ((escrowAdjust.escrow_withheld !== ""
                                    ? parseFloatNumber(
                                        String(
                                          escrowAdjust.escrow_withheld
                                        ).replace(/[$,]/g, "")
                                      )
                                    : 0) +
                                    (escrowAdjust.manual_escrow_offset !== ""
                                      ? parseFloatNumber(
                                          String(
                                            escrowAdjust.manual_escrow_offset
                                          ).replace(/[$,]/g, "")
                                        )
                                      : 0))
                              )}
                            </span>
                          </div>
                        </div>

                        {payoutStatusFlag == "processing" && (
                          <div className="w-full border border-gray-300 shadow-md rounded-md mt-5 py-4 px-1">
                            <div className="w-full grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              {/* <div className="w-full sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Transaction Status
                                </label>
                                <div className="w-full mt-2">
                                  <fieldset className="py-0">
                                    <div className="sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                      <div className="flex items-center">
                                        <input
                                          id="success"
                                          name="status"
                                          type="radio"
                                          value="success"
                                          checked={
                                            escrowAdjust.status === "success"
                                          }
                                          onChange={() =>
                                            setEscrowAdjust({
                                              ...escrowAdjust,
                                              status: "success",
                                            })
                                          }
                                          className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-600 hover:cursor-pointer"
                                        />
                                        <label
                                          htmlFor="success"
                                          className={`ml-3 block text-base font-bold leading-6 hover:cursor-pointer ${
                                            escrowAdjust.status === "success"
                                              ? "text-green-600"
                                              : "text-gray-900"
                                          }`}
                                        >
                                          Success
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="failed"
                                          name="status"
                                          type="radio"
                                          value="failed"
                                          checked={
                                            escrowAdjust.status === "failed"
                                          }
                                          onChange={() =>
                                            setEscrowAdjust({
                                              ...escrowAdjust,
                                              status: "failed",
                                            })
                                          }
                                          className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 hover:cursor-pointer"
                                        />
                                        <label
                                          htmlFor="failed"
                                          className={`ml-3 block text-base font-bold leading-6 hover:cursor-pointer ${
                                            escrowAdjust.status === "failed"
                                              ? "text-red-600"
                                              : "text-gray-900"
                                          }`}
                                        >
                                          Failed
                                        </label>
                                      </div>
                                    </div>
                                  </fieldset>
                                </div>
                              </div> */}

                              <div className="w-full sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Transaction ID
                                </label>
                                <div className="w-full mt-2">
                                  <input
                                    className="w-full mt-2 border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                    id="transaction_id"
                                    name="transaction_id"
                                    onChange={(e) =>
                                      handleEscrowAdjustChange(e)
                                    }
                                    value={escrowAdjust.transaction_id}
                                  />
                                </div>
                              </div>

                              <div className="w-full sm:col-span-6">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Notes
                                </label>
                                <div className="w-full mt-2">
                                  <textarea
                                    className="w-full mt-2 border-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                    id="remarks"
                                    name="remarks"
                                    onChange={(e) =>
                                      handleEscrowAdjustChange(e)
                                    }
                                    value={escrowAdjust.remarks}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="w-full flex justify-end gap-2">
                          <button
                            disabled={
                              (payoutStatusFlag == "processing" &&
                                escrowAdjust.transaction_id.length == 0) ||
                              escrowIndividualErrors.totalPayoutAdvanced !==
                                "" ||
                              escrowIndividualErrors.escrowWithheld !== "" ||
                              escrowIndividualErrors.manualEscrowOffset !==
                                "" ||
                              submitPayoutLoaders
                            }
                            type="submit"
                            className={`inline-flex gap-3 justify-center items-center  mt-5 rounded-md  px-3 py-2 text-sm font-semibold  shadow-sm text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                              (payoutStatusFlag === "processing" &&
                                escrowAdjust.transaction_id.length === 0) ||
                              escrowIndividualErrors.totalPayoutAdvanced !==
                                "" ||
                              escrowIndividualErrors.escrowWithheld !== "" ||
                              escrowIndividualErrors.manualEscrowOffset !== ""
                                ? "bg-gray-200 cursor-default"
                                : "bg-[#2991e0] hover:bg-[#1991e0] text-white"
                            }`}
                          >
                            Submit
                            <div>
                              {submitPayoutLoaders && (
                                <Oval
                                  height={20}
                                  width={20}
                                  color="#ffff"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#ffff"
                                  strokeWidth={2}
                                  strokeWidthSecondary={2}
                                />
                              )}
                            </div>
                          </button>

                          <button
                            type="button"
                            onClick={() => {
                              setOpenEscrowAdjustModal(false);
                              setEscrowAdjust({
                                ...escrowAdjust,
                                total_payout_advanced: "",
                                escrow_withheld: "",
                                manual_escrow_offset: "",
                                status: "",
                                transaction_id: "",
                                remarks: "",
                              });
                              setPayoutLoaders({
                                ...payoutLoaders,
                                [escrowAdjustDetails.id]: false,
                              });
                              setEscrowIndividualErrors({
                                totalPayoutAdvanced: "",
                                escrowWithheld: "",
                                manualEscrowOffset: "",
                              });
                            }}
                            className="mt-5 rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent open={openBatchEscrowAdjustModal} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  md:w-auto">
            <div className="w-full p-5 ">
              <div className="w-full my-2">
                <div className="text-2xl font-bold">Batch Payout</div>
                <div className="text-xs text-[#9ca0b3]"></div>
              </div>
              <div className="w-full rounded-md shadow-md border m-1 p-1">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                              >
                                Firm Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Attorney Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Client Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Invitation Status
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Contract Amount
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Total Payout Advanced
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                NA Fee Percentage
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Escrow Withheld
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Manual Escrow Offset
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Total Payable Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {batchEscrowAdjustData.map((data, index) => (
                              <tr key={index}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                  {data.firm_name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                  {data.attorney_name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                  {`${data.client_name}`}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                  <span
                                    className={`ml-1 ${
                                      data.status == "Pending"
                                        ? "text-amber-500"
                                        : "text-green-500"
                                    }`}
                                  >
                                    {`(${data.status})`}
                                  </span>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-base font-bold text-gray-500">
                                  {formatUSD(data.contract_amount)}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                  <div className="inline-flex items-center">
                                    <CurrencyFormat
                                      name="total_payout_advanced"
                                      id="total_payout_advanced"
                                      placeholder="$0.00"
                                      value={data.total_payout_advanced}
                                      onChange={(e) => {
                                        console.log(e);
                                        handleBatchEscrowAdjustChange(e, index);
                                      }}
                                      displayType={"input"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      className="w-full border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                    />
                                  </div>
                                  {escrowBatchErrors[index]?.totalPayoutAdvanced && (
                    <div className="text-red-500 text-sm">{escrowBatchErrors[index].totalPayoutAdvanced}</div>
                )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base font-bold text-gray-500">
                                  {data.na_fee_percentage}%
                                  <span className="text-gray-400 ml-4">
                                    {`(${formatUSD(data?.na_fee)})`}
                                  </span>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                  <div className="inline-flex items-center">
                                    <CurrencyFormat
                                      name="escrow_withheld"
                                      id="escrow_withheld"
                                      placeholder="$0.00"
                                      value={data.escrow_withheld}
                                      onChange={(e) => {
                                        console.log(e);
                                        handleBatchEscrowAdjustChange(e, index);
                                      }}
                                      displayType={"input"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      className="w-full border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                    />
                                  </div>
                                  {escrowBatchErrors[index]?.escrowWithheld && (
                    <div className="text-red-500 text-sm">{escrowBatchErrors[index].escrowWithheld}</div>
                )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                  <div className="inline-flex items-center">
                                    <CurrencyFormat
                                      name="manual_escrow_offset"
                                      id="manual_escrow_offset"
                                      placeholder="$0.00"
                                      value={data.manual_escrow_offset}
                                      onChange={(e) => {
                                        console.log(e);
                                        handleBatchEscrowAdjustChange(e, index);
                                      }}
                                      displayType={"input"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      className="w-full border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                    />
                                  </div>
                                  {escrowBatchErrors[index]?.manualEscrowOffset && (
                    <div className="text-red-500 text-sm">{escrowBatchErrors[index].manualEscrowOffset}</div>
                )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-lg font-bold text-gray-900">
                                  {formatUSD(data.total_payable_amount)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center">
                <div className="w-full flex flex-col justify-start items-center gap-3 md:flex-row">
                  <span className="text-base">Overall Payable Amount:</span>
                  <span className="text-2xl font-bold">
                    {formatUSD(sumTotalPayableAmount())}
                  </span>
                </div>
                <div className="w-full my-2 mx-2">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      console.log(batchEscrowAdjustData);
                      sendBatch();
                    }}
                  >
                    <div className="w-full flex justify-end gap-2">
                      {/* <button
                        disabled={
                          !batchEscrowAdjustData.every(
                            (value) =>
                              (parseFloatNumber(
                                String(value.total_payout_advanced).replace(
                                  /[$,]/g,
                                  ""
                                )
                              ) <= parseFloatNumber(value.contract_amount) &&
                                parseFloatNumber(
                                  String(value.manual_escrow_offset).replace(
                                    /[$,]/g,
                                    ""
                                  )
                                ) +
                                  parseFloatNumber(
                                    String(value.escrow_withheld).replace(
                                      /[$,]/g,
                                      ""
                                    )
                                  ) <=
                                  parseFloatNumber(
                                    String(value.total_payout_advanced).replace(
                                      /[$,]/g,
                                      ""
                                    )
                                  )) ||
                              parseFloatNumber(
                                String(value.total_payout_advanced).replace(
                                  /[$,]/g,
                                  ""
                                )
                              ) < 0
                          ) || submitBatchPayoutLoader
                        }
                        type="submit"
                        className={`inline-flex gap-3 justify-center items-center  mt-5 rounded-md  px-3 py-2 text-sm font-semibold  shadow-sm text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                          !batchEscrowAdjustData.every(
                            (value) =>
                              (parseFloatNumber(
                                String(value.total_payout_advanced).replace(
                                  /[$,]/g,
                                  ""
                                )
                              ) <= parseFloatNumber(value.contract_amount) &&
                                parseFloatNumber(
                                  String(value.manual_escrow_offset).replace(
                                    /[$,]/g,
                                    ""
                                  )
                                ) +
                                  parseFloatNumber(
                                    String(value.escrow_withheld).replace(
                                      /[$,]/g,
                                      ""
                                    )
                                  ) <=
                                  parseFloatNumber(
                                    String(value.total_payout_advanced).replace(
                                      /[$,]/g,
                                      ""
                                    )
                                  )) ||
                              parseFloatNumber(
                                String(value.total_payout_advanced).replace(
                                  /[$,]/g,
                                  ""
                                )
                              ) < 0
                          )
                            ? "bg-gray-200 cursor-default"
                            : "bg-[#2991e0] hover:bg-[#1991e0] text-white"
                        }`}
                      >
                        Submit
                        <div>
                          {submitBatchPayoutLoader && (
                            <Oval
                              height={20}
                              width={20}
                              color="#ffff"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#ffff"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                            />
                          )}
                        </div>
                      </button> */}
<button
    disabled={
        escrowBatchErrors.some(
            (errors) =>
                errors.totalPayoutAdvanced !== "" ||
                errors.escrowWithheld !== "" ||
                errors.manualEscrowOffset !== ""
        ) || submitBatchPayoutLoader
    }
    type="submit"
    className={`inline-flex gap-3 justify-center items-center mt-5 rounded-md px-3 py-2 text-sm font-semibold shadow-sm text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
        escrowBatchErrors.some(
            (errors) =>
                errors.totalPayoutAdvanced !== "" ||
                errors.escrowWithheld !== "" ||
                errors.manualEscrowOffset !== ""
        ) || submitBatchPayoutLoader
            ? "bg-gray-200 cursor-default"
            : "bg-[#2991e0] hover:bg-[#1991e0] text-white"
    }`}
>
    Submit
    <div>
        {submitBatchPayoutLoader && (
            <Oval
                height={20}
                width={20}
                color="#ffff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#ffff"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        )}
    </div>
</button>
                      <button
                        type="button"
                        onClick={() => {
                          setOpenBatchEscrowAdjustModal(false);
                          setBatchPayoutLoaders(false);
                        }}
                        className="mt-5 rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
