import { useEffect, useMemo, useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import ProgressBar from "@ramonak/react-progress-bar";
import toast from "react-hot-toast";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FirmDataVersionTable({ firmData, refetchVersion }) {
  console.log(firmData, refetchVersion);
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const access_token = localStorage.getItem("access_token");

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();
  //table state
  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 as getMonth() returns 0-based index
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function extractTimeFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const hour = dateObject.getHours().toString().padStart(2, "0");
    const minute = dateObject.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
  }
  function formatUSCell(usPhoneNumber) {
    // Remove any non-digit characters
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    // Check if the number has the expected length of 10 digits
    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    // Format the number in the original format and add +1 in front
    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(
        // 'event-reports','http://191.96.1.252:8000/',
        "firm-contract-versions",
        `${domainUrl}`
      );
      url.searchParams.set("id", firmData?.id);

      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("table", `true`);
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;
        console.log("###", data);
        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
        // setRowCount(Math.ceil(data.count / pagination.pageSize));
        // setRowCount(5);
        // Math.ceil(totalCount / pagination.pageSize)
        // setTotalCount(data.count);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    if (firmData?.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    firmData?.id,
    refetchVersion,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "id",
        header: "",
        columns: [
          {
            accessorFn: (row) =>
              `${row.edited_by.first_name} ${row.edited_by.last_name}`,
            id: "first_name",
            header: "Edited By",
            accessorKey: "first_name",
            enableColumnActions: false,
            enableSorting: false,
            size: 30,
          },
          {
            accessorFn: (row) => `${row.edited_by.role.toUpperCase()}`,
            id: "role",
            header: "Role",
            accessorKey: "role",
            enableColumnActions: false,
            enableSorting: false,
            size: 30,
          },
          {
            accessorFn: (row) =>
              `${extractDateFromTimestamp(
                row.edit_timestamp
              )}  -  (${extractTimeFromTimestamp(row.edit_timestamp)})`,
            id: "edit_timestamp",
            header: "Modified At",
            accessorKey: "edit_timestamp",
            enableColumnActions: false,
            enableSorting: false,
            size: 30,
          },
        ],
      },
    ],

    []
  );

  return (
    <>
      <div>
        <main className="pb-20">
          <main className="w-full py-10">
            <div className="mx-5 px-4 py-2 rounded-lg border border-gray-300 shadow-lg">
              <div className="pb-4 border-b border-gray-300 ">
                <div className="text-lg font-bold leading-7 text-gray-700">
                  Firm Contract Version
                </div>
              </div>
              <div className="border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={false}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  // enableColumnResizing
                  // columnResizeMode="onEnd"
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff", // Set the background color for even rows
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                      // m: 'auto'
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      // background: "rgba(52, 210, 235, 0.1)",
                      // borderRight: "1px solid rgba(224,224,224,1)",
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  renderDetailPanel={({ row }) => {
                    return (
                      <div className="bg-white">
                        <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
                          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-4 lg:gap-x-8">
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                NA Fee Percentage
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original.na_fee_percentage}%
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Escrow Limit
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {formatUSD(row.original.escrow_limit)}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Escrow Percentage
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original.escrow_percentage}%
                              </dd>
                            </div>
                            {/* <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Escrow Balance
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {formatUSD(row.original.escrow_actual)}
                              </dd>
                            </div> */}
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">LOC</dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {formatUSD(row.original.loc)}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Late Fees
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {formatUSD(row.original.late_fees)}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    );
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  muiTableBodyRowProps={({ row }) => ({
                    //implement row selection click events manually
                    onClick: () => {
                      if (data.length) {
                        console.log(row.original.id);
                      }
                    },
                    // selected: rowSelection[row.id],
                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  // manualFiltering
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  // rowCount={Math.ceil(totalCount / pagination.pageSize)}
                  state={{
                    // columnFilters,
                    // search,
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
