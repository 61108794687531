import React, { useEffect } from "react";
import ListFirmsTable from "./Tables/ListFirmsTable";

const ListFirms = () => {
  const role = localStorage.getItem("role");

  const checkDefaultFirm = () => {
    if (role == "admin") {
      localStorage.removeItem("firm_id");
    }
  };
  useEffect(() => {
    checkDefaultFirm();
  }, []);
  return (
    <div>
      <ListFirmsTable />
    </div>
  );
};

export default ListFirms;
