import React from 'react'
import PreDelinquentClientsTable from './Tables/PreDelinquentClientsTable'

const PreDelinquentClients = () => {
  return (
    <>
     <PreDelinquentClientsTable/> 
    </>
  )
}

export default PreDelinquentClients
