import React, { useEffect, useState, useMemo } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import {
  domainUrl,
  logOutHandler,
  tokenExpiryValidate,
} from "../utils/constants";
import toast from "react-hot-toast";
import {
  Hourglass,
  MagnifyingGlass,
  Oval,
  ThreeDots,
} from "react-loader-spinner";
import { IconButton, InputAdornment } from "@mui/material";
import ModalComponent from "../Components/ModalComponent";
import { useNavigate } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function AttorneyPayoutHistory({ attorneyData }) {
  const [loader, setLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);

  const access_token = localStorage.getItem("access_token");
  const attorney_id = localStorage.getItem("attorney_id");

  const role = localStorage.getItem("role");
  const query_param = localStorage.getItem("query_param/total_payout");

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [payoutLoaders, setPayoutLoaders] = useState({});
  const [batchPayoutLoaders, setBatchPayoutLoaders] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openEscrowAdjustModal, setOpenEscrowAdjustModal] = useState(false);
  const [openBatchEscrowAdjustModal, setOpenBatchEscrowAdjustModal] =
    useState(false);
  const [escrowAdjust, setEscrowAdjust] = useState({
    total_payout_advanced: 0,
    escrow_withheld: 0,
    manual_escrow_offset: 0,
    status: "",
    transaction_id: "",
    remarks: "",
  });
  const [escrowAdjustDetails, setEscrowAdjustDetails] = useState({});
  const [payoutStatusFlag, setPayoutStatusFlag] = useState("");
  const [batchEscrowAdjustData, setBatchEscrowAdjustData] = useState([]);
  const [batchEscrowAdjust, setBatchEscrowAdjust] = useState({
    total_payout_advanced: 0,
    escrow_withheld: 0,
    manual_escrow_offset: 0,
    status: "",
    transaction_id: "",
    remarks: "",
  });

  const getInitialData = async (value) => {
    try {
      setLoader(true);
      const res = await axios.get(
        `${domainUrl}firm-payout?status=paid&attorney=${
          role == "admin" ? attorneyData?.attorney_id : attorney_id
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setData(res.data);

        let tempChecked = res.data;
        res.data.forEach((firm, firm_index) => {
          tempChecked[firm_index].checked = false;
          tempChecked[firm_index].expanded = true;

          firm.attorneys.forEach((attorney, attorney_index) => {
            tempChecked[firm_index].attorneys[attorney_index].checked = false;
            tempChecked[firm_index].attorneys[attorney_index].expanded = true;

            attorney.clients.forEach((client, client_index) => {
              tempChecked[firm_index].attorneys[attorney_index].clients[
                client_index
              ].checked = false;
            });
          });
        });
        console.log(tempChecked);
        setData(tempChecked);
        setLoader(false);

        // toast.success("Data Loaded", { id: 1 });
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      toast.error("Error Occured", { id: 1 });
    }
  };

  useEffect(() => {
    if (role == "attorney") {
      if (!query_param) {
        navigate(-1);
      }
    }
    getInitialData();
    return () => {
      localStorage.removeItem("query_param/total_payout");
    };
  }, []);

  const expandedFirms = () => {
    let firms = [];
    data.forEach((firm) => {
      if (firm.expanded) {
        firms.push(firm.id);
      }
    });
    return firms;
  };

  const expandedAttorneys = () => {
    let attorneys = [];
    data.forEach((firm) => {
      firm.attorneys.forEach((attorney) => {
        if (attorney.expanded) attorneys.push(attorney.id);
      });
    });
    return attorneys;
  };

  const searchDataHandler = async (value) => {
    setSearchValue(value);

    let selectedAttorney = expandedAttorneys();
    let selectedFirm = expandedFirms();
    try {
      setSearchLoader(true);
      const res = await axios.get(
        `${domainUrl}firm-payout?status=paid&search=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setData(res.data);
        if (res.data.length == 0 && value != "") {
          toast.error("No such firm is available", { id: 1 });
        } else {
          // toast.success("Data Loaded", { id: 1 });
        }

        let tempChecked = res.data;
        res.data.forEach((firm, firm_index) => {
          tempChecked[firm_index].checked = false;
          tempChecked[firm_index].expanded = selectedFirm.includes(firm.id)
            ? true
            : false;

          firm.attorneys.forEach((attorney, attorney_index) => {
            tempChecked[firm_index].attorneys[attorney_index].checked = false;
            tempChecked[firm_index].attorneys[attorney_index].expanded =
              selectedAttorney.includes(attorney.id) ? true : false;

            attorney.clients.forEach((client, client_index) => {
              tempChecked[firm_index].attorneys[attorney_index].clients[
                client_index
              ].checked = false;
            });
          });
        });
        console.log(tempChecked);
        setData(tempChecked);
        setSearchLoader(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setSearchLoader(false);
      toast.error("Error Occured", { id: 1 });
    }
  };
  const handleClearSearch = () => {
    searchDataHandler("");
  };

  const handleCheckboxChange = (
    firmIndex,
    attorneyIndex,
    clientIndex,
    isChecked
  ) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[firmIndex].attorneys[attorneyIndex].clients[clientIndex].checked =
        isChecked;

      // Check if all clients are checked, then update attorney
      const allClientsChecked = newData[firmIndex].attorneys[
        attorneyIndex
      ].clients.every((client) => client.checked);
      newData[firmIndex].attorneys[attorneyIndex].checked = allClientsChecked;

      // Check if all attorneys are checked, then update firm
      const allAttorneysChecked = newData[firmIndex].attorneys.every(
        (attorney) => attorney.checked
      );
      newData[firmIndex].checked = allAttorneysChecked;

      return newData;
    });
  };
  const handleFirmExpanded = (firmIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[firmIndex].expanded = !newData[firmIndex].expanded;
      return newData;
    });
  };

  const handleFirmCheckboxChange = (firmIndex, isChecked) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[firmIndex].checked = isChecked;

      // Update all attorneys and clients under the firm
      newData[firmIndex].attorneys.forEach((attorney) => {
        attorney.checked = isChecked;
        attorney.clients.forEach((client) => {
          client.checked = isChecked;
        });
      });

      return newData;
    });
  };
  const handleAttorneyExpanded = (firmIndex, attorneyIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[firmIndex].attorneys[attorneyIndex].expanded =
        !newData[firmIndex].attorneys[attorneyIndex].expanded;
      return newData;
    });
  };

  const handleAttorneyCheckboxChange = (
    firmIndex,
    attorneyIndex,
    isChecked
  ) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[firmIndex].attorneys[attorneyIndex].checked = isChecked;

      // Update all clients under the attorney
      newData[firmIndex].attorneys[attorneyIndex].clients.forEach((client) => {
        client.checked = isChecked;
      });

      // Check if all attorneys are checked, then update firm
      const allAttorneysChecked = newData[firmIndex].attorneys.every(
        (attorney) => attorney.checked
      );
      newData[firmIndex].checked = allAttorneysChecked;

      return newData;
    });
  };

  const isClientSelected = () => {
    let flag = false;
    data.forEach((firm) => {
      firm.attorneys.forEach((attorney) => {
        attorney.clients.forEach((client) => {
          if (client.checked) {
            flag = true;
          }
        });
      });
    });
    return flag;
  };

  const filterPositiveDecimal = (value) => {
    const validPositiveDecimalRegex = /^\d+(\.\d*)?$/;
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts.slice(0, 2).join(".") + parts.slice(2).join("");
    }
    const match = value.match(validPositiveDecimalRegex);
    return match ? match[0] : value.slice(0, -1);
  };
  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  const handleEscrowAdjustChange = (e) => {
    if (["transaction_id", "remarks"].includes(e.target.name)) {
      setEscrowAdjust({
        ...escrowAdjust,
        [e.target.name]: e.target.value,
      });
    } else {
      setEscrowAdjust({
        ...escrowAdjust,
        // [e.target.name]: parseFloat(filterPositiveDecimal(e.target.value)),
        [e.target.name]: filterPositiveDecimal(e.target.value),
      });
    }

    console.log(
      escrowAdjust.total_payout_advanced -
        (escrowAdjust.escrow_withheld + escrowAdjust.manual_escrow_offset)
    );
    console.log(
      escrowAdjust.total_payout_advanced,
      escrowAdjust.escrow_withheld,
      escrowAdjust.manual_escrow_offset
    );
  };

  const escrowAdjustHandler = (client) => {
    setEscrowAdjust({
      ...escrowAdjust,
      total_payout_advanced: client.total_payout_advanced,
      escrow_withheld: client.escrow_withheld,
    });
    setPayoutLoaders({ ...payoutLoaders, [client.id]: true });
    setOpenEscrowAdjustModal(true);
    setEscrowAdjustDetails(client);
  };

  const sendIndividual = async (client_id) => {
    console.log(client_id);
    // setOpenEscrowAdjustModal(false);

    setPayoutLoaders({ ...payoutLoaders, [client_id]: true });

    if (payoutStatusFlag == "processing") {
      try {
        const res = await axios.post(
          `${domainUrl}firm-payout?id=${client_id}`,
          {
            total_payout_advanced: escrowAdjust.total_payout_advanced,
            escrow_withheld: escrowAdjust.escrow_withheld,
            manual_escrow_offset: escrowAdjust.manual_escrow_offset,
            status: escrowAdjust.status,
            transaction_id: escrowAdjust.transaction_id,
            remarks: escrowAdjust.remarks,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          searchDataHandler("");
          setOpenEscrowAdjustModal(false);
          setEscrowAdjust({
            ...escrowAdjust,
            total_payout_advanced: 0,
            escrow_withheld: 0,
            manual_escrow_offset: 0,
            status: "",
            transaction_id: "",
            remarks: "",
          });
          setPayoutLoaders({ ...payoutLoaders, [client_id]: false });
          toast.success(res.data.message, { id: 1 });
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setPayoutLoaders({ ...payoutLoaders, [client_id]: false });
        setPayoutStatusFlag("");
      }
    } else {
      try {
        const res = await axios.post(
          `${domainUrl}firm-payout?id=${client_id}`,
          {
            total_payout_advanced: escrowAdjust.total_payout_advanced,
            escrow_withheld: escrowAdjust.escrow_withheld,
            manual_escrow_offset: escrowAdjust.manual_escrow_offset,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          const wellsFargoUrl = "https://www.wellsfargo.com/";
          window.open(wellsFargoUrl, "_blank");

          console.log(res.data);
          setPayoutStatusFlag("processing");
          // setEscrowAdjust({
          //   ...escrowAdjust,
          //   total_payout_advanced: 0,
          //   escrow_withheld: 0,
          //   manual_escrow_offset: 0,
          // });
          setPayoutLoaders({ ...payoutLoaders, [client_id]: false });
          toast.success(res.data.message, { id: 1 });
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setPayoutLoaders({ ...payoutLoaders, [client_id]: false });
        setPayoutStatusFlag("");
      }
    }
  };

  const escrowBatchAdjustHandler = () => {
    let ids = [];

    let tempData = [];

    data.forEach((firm) => {
      const firmData = {
        firm_name: firm.firm_name,
        firm_code: firm.firm_code,
      };

      firm.attorneys.forEach((attorney) => {
        const attorneyData = {
          ...firmData,
          attorney_name: attorney.attorney_name,
          attorney_code: attorney.attorney_code,
        };

        attorney.clients.forEach((client) => {
          if (client.checked) {
            const clientData = {
              ...attorneyData,
              id: client.id,
              client_name: client.client_name,
              client_code: client.client_code,
              contract_amount: client.contract_amount,
              total_payout_advanced: client.total_payout_advanced,
              escrow_withheld: client.escrow_withheld,
              na_fee_percentage: client.na_fee_percentage,
              manual_escrow_offset: 0,
              total_payable_amount:
                client.total_payout_advanced - client.escrow_withheld,
            };

            tempData.push(clientData);
            ids.push(client.id);
          }
        });
      });
    });

    console.log("Temp Data", tempData);
    setBatchEscrowAdjustData(tempData);
    console.log(ids);
    setBatchPayoutLoaders(true);
    setOpenBatchEscrowAdjustModal(true);
  };

  const parseFloatNumber = (value) => {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  };
  const sumTotalPayableAmount = () => {
    let amount = 0;
    batchEscrowAdjustData.forEach((value) => {
      amount = amount + parseFloatNumber(value.total_payable_amount);
    });
    return amount;
  };
  const handleBatchEscrowAdjustChange = (e, index) => {
    setBatchEscrowAdjustData((prevData) => {
      const newData = [...prevData];
      const total_payable_amount =
        e.target.name === "total_payout_advanced"
          ? parseFloatNumber(filterPositiveDecimal(e.target.value)) -
            (parseFloatNumber(newData[index].escrow_withheld) +
              parseFloatNumber(newData[index].manual_escrow_offset))
          : e.target.name === "escrow_withheld"
          ? parseFloatNumber(newData[index].total_payout_advanced) -
            (parseFloatNumber(filterPositiveDecimal(e.target.value)) +
              parseFloatNumber(newData[index].manual_escrow_offset))
          : parseFloatNumber(newData[index].total_payout_advanced) -
            (parseFloatNumber(filterPositiveDecimal(e.target.value)) +
              parseFloatNumber(newData[index].escrow_withheld));
      newData[index] = {
        ...newData[index],
        [e.target.name]: filterPositiveDecimal(e.target.value),
        ["total_payable_amount"]: total_payable_amount,
      };
      console.log(newData);
      return newData;
    });
  };

  const sendBatch = async () => {
    try {
      let ids = [];

      data.forEach((firm) => {
        firm.attorneys.forEach((attorney) => {
          attorney.clients.forEach((client) => {
            if (client.checked) {
              ids.push(client.id);
            }
          });
        });
      });

      console.log(ids);
      setOpenBatchEscrowAdjustModal(true);

      const res = await axios.post(
        `${domainUrl}firm-payout?ids=${ids}`,
        { data: batchEscrowAdjustData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setBatchPayoutLoaders(false);
        searchDataHandler("");
        const wellsFargoUrl = "https://www.wellsfargo.com/";
        window.open(wellsFargoUrl, "_blank");
        toast.success("Batch Payout has been initated", { id: 1 });
        setOpenBatchEscrowAdjustModal(false);
        ids = [];
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setBatchPayoutLoaders(false);
      setOpenBatchEscrowAdjustModal(false);
    }
  };

  return (
    <div>
      {loader ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Payout History
              </h2>
              <div className="border p-2 rounded-lg shadow-md">
                {/* <div className="w-full py-2 flex justify-end items-center gap-3">
                  <div>
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <TextField
                        id="input-with-sx"
                        label="Search firm"
                        variant="standard"
                        value={searchValue}
                        onChange={(e) => searchDataHandler(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {searchValue && (
                                <IconButton
                                  size="small"
                                  onClick={handleClearSearch}
                                  edge="end"
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </div>
                </div> */}

                {searchLoader ? (
                  <div className="w-full  flex justify-center items-center">
                    <MagnifyingGlass
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="MagnifyingGlass-loading"
                      wrapperStyle={{}}
                      wrapperClass="MagnifyingGlass-wrapper"
                      glassColor="#c0efff"
                      color="#e15b64"
                    />
                  </div>
                ) : (
                  <>
                    {data?.length ? (
                      data?.map((firm, firm_index) => (
                        <>
                          {firm.attorneys.length &&
                            firm.attorneys.map((attorney, attorney_index) => (
                              <Accordion
                                key={attorney_index}
                                expanded={attorney.expanded}
                                onChange={(e) => {
                                  if (!e.target.name) {
                                    handleAttorneyExpanded(
                                      firm_index,
                                      attorney_index
                                    );
                                    console.log("++", e.target.name);
                                  }
                                }}
                              >
                                <AccordionSummary
                                  aria-controls="panel2d-content"
                                  id="panel2d-header"
                                >
                                  <Typography>{`${attorney.attorney_name} - (${attorney.attorney_code})`}</Typography>
                                </AccordionSummary>

                                <AccordionDetails
                                  sx={{
                                    p: "0px",
                                    backgroundColor: "lightgray",
                                  }}
                                >
                                  {attorney.clients.length &&
                                    attorney.clients.map(
                                      (client, client_index) => (
                                        <Accordion
                                          key={client_index}
                                          expanded={true}
                                        >
                                          {/* <div className="bg-white">
                                              <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-3 sm:pb-24 lg:px-4">
                                                <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-3 sm:gap-y-16 lg:max-w-none lg:grid-cols-6 lg:gap-x-8">
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      Client Name
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {client.client_name}
                                                    </dd>
                                                  </div>
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      Case Type
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {client.case_type}
                                                    </dd>
                                                  </div>
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      Contact Start Date
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {
                                                        client.contract_start_date
                                                      }
                                                    </dd>
                                                  </div>
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      Total Contract Amount
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {formatUSD(
                                                        client.contract_amount
                                                      )}
                                                    </dd>
                                                  </div>
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      Total Payout Advanced
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {formatUSD(
                                                        client.total_payout_advanced
                                                      )}
                                                    </dd>
                                                  </div>
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      Amount Paid
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {formatUSD(client.amount)}
                                                    </dd>
                                                  </div>
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      Escrow Withheld
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {formatUSD(
                                                        client.escrow_withheld
                                                      )}
                                                    </dd>
                                                  </div>
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      NA Fee Percentage
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {client.na_fee_percentage}
                                                    </dd>
                                                  </div>
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      NA Fee
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {formatUSD(client.na_fee)}
                                                    </dd>
                                                  </div>
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      Transaction Id
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {client.transaction_id}
                                                    </dd>
                                                  </div>
                                                  <div className="border-t border-gray-200 pt-2">
                                                    <dt className="text-sm text-gray-500">
                                                      Remarks
                                                    </dt>
                                                    <dd className="mt-2 text-lg font-bold text-gray-900">
                                                      {client.remarks}
                                                    </dd>
                                                  </div>
                                                </dl>
                                              </div>
                                            </div> */}
                                          <div className="mt-2">
                                            <div className="overflow-x-auto ">
                                              <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                  <thead className="bg-gray-100">
                                                    <tr>
                                                      <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
                                                      >
                                                        Transaction Id
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
                                                      >
                                                        Attorney Name
                                                      </th>

                                                      <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
                                                      >
                                                        Contact Start Date
                                                      </th>

                                                      <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
                                                      >
                                                        Remarks
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="divide-y divide-gray-200">
                                                    <tr key={client_index}>
                                                      <td className="text-start whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                                        {client.transaction_id}
                                                      </td>
                                                      <td className="text-start whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                                        {attorney.attorney_name}
                                                      </td>

                                                      <td className="text-start whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                                        {formatDate(
                                                          client.contract_start_date
                                                        )}
                                                      </td>
                                                      <td className="text-start whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                                        {client.remarks}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="px-4 py-5 sm:px-6 lg:px-8">
                                            <div className="my-2">
                                              <div className="bg-blue-500 py-5 sm:flex sm:items-center">
                                                <div className="sm:flex-auto">
                                                  <div className="text-lg text-white font-normal leading-6 px-3">
                                                    Paid for Client Cases
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="mt-1 flow-root">
                                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                      <table className="min-w-full divide-y divide-gray-300">
                                                        <thead className="bg-gray-50">
                                                          <tr>
                                                            <th
                                                              scope="col"
                                                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                            >
                                                              Debtor
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                              Amount
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                              NA Fees
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                              Escrow Withholding
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                              Client Advance
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                          <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                                                              {`${client.client_name}
                                                                (${client.client_code})'s
                                                                ${client.case_type} case`}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                                              {formatUSD(
                                                                client.contract_amount
                                                              )}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                                              {formatUSD(
                                                                client.na_fee
                                                              )}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                                              {formatUSD(
                                                                client.escrow_withheld
                                                              )}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-base text-gray-900">
                                                              {formatUSD(
                                                                client.total_payout_advanced
                                                              )}
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                                                              {`(A)Total Client
                                                                Advance Amount: 
                                                                ${formatUSD(
                                                                  client.total_payout_advanced
                                                                )}`}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="px-4 py-5 sm:px-6 lg:px-8">
                                            <div className="my-2">
                                              <div className="bg-blue-500 py-5 sm:flex sm:items-center">
                                                <div className="sm:flex-auto">
                                                  <div className="text-lg text-white font-normal leading-6 px-3">
                                                    Offsetted Delinquent Debtors
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="mt-1 flow-root">
                                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                      <table className="min-w-full divide-y divide-gray-300">
                                                        <thead className="bg-gray-50">
                                                          <tr>
                                                            <th
                                                              scope="col"
                                                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                            >
                                                              Delinquent Debtor
                                                            </th>
                                                            <th
                                                              scope="col"
                                                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                              Amount
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                          <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                                                              (B)Total
                                                              Delinquent Offset:
                                                              $0.00
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="px-4 py-5 sm:px-6 lg:px-8">
                                            <div className="my-2">
                                              <div className="bg-blue-500 py-5 sm:flex sm:items-center">
                                                <div className="sm:flex-auto">
                                                  <div className="text-lg text-white font-normal leading-6 px-3">
                                                    Final Settlement
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="mt-1 flow-root">
                                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                      <table className="min-w-full divide-y divide-gray-300">
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                          <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                                                              (C)Manual Escrow
                                                              Adjustment: $0.00
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-gray-900 sm:pl-6">
                                                              {`(D)Total value
                                                                of Batch 0003 = 
                                                                ${formatUSD(
                                                                  client.total_payout_advanced
                                                                )} (A - (B +
                                                                C))`}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Accordion>
                                      )
                                    )}
                                </AccordionDetails>
                              </Accordion>
                            ))}
                        </>
                      ))
                    ) : (
                      <div className="p-2 text-center text-lg italic">
                        <div>No data</div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </main>
        </main>
      )}

      <ModalComponent open={openEscrowAdjustModal} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
            <div className="w-full p-5">
              <div className="w-full rounded-lg ">
                <div className="w-full">
                  <div className="w-full my-2">
                    <div className="text-2xl font-bold">Payout</div>
                    <div className="text-xs text-[#9ca0b3]"></div>
                  </div>
                  <div className="w-full rounded-md shadow-md border m-1 p-1 sm:flex justify-center items-center">
                    <div className="">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-2 py-6 sm:grid sm:grid-cols-3 sm:gap-8 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Client Name
                          </dt>
                          <dd className="mt-1 text-base font-bold text-gray-700 sm:col-span-2 sm:mt-0">
                            {escrowAdjustDetails?.client_name}
                          </dd>
                        </div>
                        <div className="px-2 py-6 sm:grid sm:grid-cols-3 sm:gap-8 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Case Type
                          </dt>
                          <dd className="mt-1 text-base font-bold text-gray-700 sm:col-span-2 sm:mt-0">
                            {escrowAdjustDetails?.case_type}
                          </dd>
                        </div>
                        <div className="px-2 py-6 sm:grid sm:grid-cols-3 sm:gap-8 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Contract Amount
                          </dt>
                          <dd className="mt-1 text-base font-bold text-gray-700 sm:col-span-2 sm:mt-0">
                            {formatUSD(escrowAdjustDetails?.contract_amount)}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <div className="w-full my-2 mx-1">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        console.log(escrowAdjust);
                        sendIndividual(escrowAdjustDetails.id);
                      }}
                    >
                      <div className="w-full">
                        <div className="w-full  border-gray-900/10 mt-5 ">
                          <div className="w-full mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="w-full sm:col-span-3">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Total Payout Advanced
                              </label>
                              <div className="w-full mt-2">
                                <input
                                  className="w-full mt-2 border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                  id="total_payout_advanced"
                                  name="total_payout_advanced"
                                  onChange={(e) => handleEscrowAdjustChange(e)}
                                  value={escrowAdjust.total_payout_advanced}
                                />
                              </div>
                            </div>

                            <div className="w-full sm:col-span-3">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Escrow Contribution
                              </label>
                              <div className="w-full mt-2">
                                <input
                                  className="w-full mt-2 border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                  id="escrow_withheld"
                                  name="escrow_withheld"
                                  onChange={(e) => handleEscrowAdjustChange(e)}
                                  value={escrowAdjust.escrow_withheld}
                                />
                              </div>
                            </div>

                            <div className="w-full sm:col-span-3">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Manual Escrow Offset
                              </label>
                              <div className="w-full mt-2">
                                <input
                                  className="w-full mt-2 border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                  id="manual_escrow_offset"
                                  name="manual_escrow_offset"
                                  onChange={(e) => handleEscrowAdjustChange(e)}
                                  value={escrowAdjust.manual_escrow_offset}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex justify-start gap-2 mt-6">
                          <div className="inline-flex items-center justify-center gap-2 text-base font-semibold">
                            Total Amount Payable:
                            <span className="text-2xl font-bold">
                              {formatUSD(
                                escrowAdjust?.total_payout_advanced -
                                  (escrowAdjust?.escrow_withheld +
                                    escrowAdjust?.manual_escrow_offset)
                              )}
                            </span>
                          </div>
                        </div>

                        {payoutStatusFlag == "processing" && (
                          <div className="w-full border border-gray-900 rounded-md mt-5 py-4 px-1">
                            <div className="w-full grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="w-full sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Transaction Status
                                </label>
                                <div className="w-full mt-2">
                                  <fieldset className="py-0">
                                    <div className="sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                      <div className="flex items-center">
                                        <input
                                          id="success"
                                          name="status"
                                          type="radio"
                                          value="success"
                                          checked={
                                            escrowAdjust.status === "success"
                                          }
                                          onChange={() =>
                                            setEscrowAdjust({
                                              ...escrowAdjust,
                                              status: "success",
                                            })
                                          }
                                          className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-600 hover:cursor-pointer"
                                        />
                                        <label
                                          htmlFor="success"
                                          className={`ml-3 block text-base font-bold leading-6 hover:cursor-pointer ${
                                            escrowAdjust.status === "success"
                                              ? "text-green-600"
                                              : "text-gray-900"
                                          }`}
                                        >
                                          Success
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="failed"
                                          name="status"
                                          type="radio"
                                          value="failed"
                                          checked={
                                            escrowAdjust.status === "failed"
                                          }
                                          onChange={() =>
                                            setEscrowAdjust({
                                              ...escrowAdjust,
                                              status: "failed",
                                            })
                                          }
                                          className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 hover:cursor-pointer"
                                        />
                                        <label
                                          htmlFor="failed"
                                          className={`ml-3 block text-base font-bold leading-6 hover:cursor-pointer ${
                                            escrowAdjust.status === "failed"
                                              ? "text-red-600"
                                              : "text-gray-900"
                                          }`}
                                        >
                                          Failed
                                        </label>
                                      </div>
                                    </div>
                                  </fieldset>
                                </div>
                              </div>

                              <div className="w-full sm:col-span-3">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Transaction ID
                                </label>
                                <div className="w-full mt-2">
                                  <input
                                    className="w-full mt-2 border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                    id="transaction_id"
                                    name="transaction_id"
                                    onChange={(e) =>
                                      handleEscrowAdjustChange(e)
                                    }
                                    value={escrowAdjust.transaction_id}
                                  />
                                </div>
                              </div>

                              <div className="w-full sm:col-span-6">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Notes
                                </label>
                                <div className="w-full mt-2">
                                  <textarea
                                    className="w-full mt-2 border-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                    id="remarks"
                                    name="remarks"
                                    onChange={(e) =>
                                      handleEscrowAdjustChange(e)
                                    }
                                    value={escrowAdjust.remarks}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="w-full flex justify-end gap-2">
                          <button
                            disabled={
                              payoutStatusFlag == "processing" &&
                              escrowAdjust.status.length == 0
                            }
                            type="submit"
                            className={
                              payoutStatusFlag == "processing"
                                ? `mt-5 rounded-md  px-3 py-2 text-sm font-semibold  shadow-sm text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 
                            ${
                              payoutStatusFlag === "processing" &&
                              escrowAdjust.status.length === 0
                                ? "bg-gray-200 cursor-default"
                                : "bg-[#2991e0] hover:bg-[#1991e0] text-white"
                            }`
                                : `mt-5 rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 `
                            }
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setOpenEscrowAdjustModal(false);
                              setEscrowAdjust({
                                ...escrowAdjust,
                                total_payout_advanced: 0,
                                escrow_withheld: 0,
                                manual_escrow_offset: 0,
                                status: "",
                                transaction_id: "",
                                remarks: "",
                              });
                              setPayoutLoaders({
                                ...payoutLoaders,
                                [escrowAdjustDetails.id]: false,
                              });
                            }}
                            className="mt-5 rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent open={openBatchEscrowAdjustModal} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  md:w-auto">
            <div className="w-full p-5 ">
              <div className="w-full my-2">
                <div className="text-2xl font-bold">Batch Payout</div>
                <div className="text-xs text-[#9ca0b3]"></div>
              </div>
              <div className="w-full rounded-md shadow-md border m-1 p-1">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                              >
                                Firm Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Attorney Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Client Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Contract Amount
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Total Payout Advanced
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Escrow Withheld
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Manual Escrow Offset
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-base font-semibold text-gray-900"
                              >
                                Total Payable Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {batchEscrowAdjustData.map((data, index) => (
                              <tr key={index}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-500 sm:pl-6 lg:pl-8">
                                  {data.firm_name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                  {data.attorney_name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                  {data.client_name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base font-bold text-gray-500">
                                  {formatUSD(data.contract_amount)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                  <span className="inline-flex items-center">
                                    <span className="mb-1">$</span>
                                    <input
                                      className="w-full border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                      type="text"
                                      name="total_payout_advanced"
                                      value={data.total_payout_advanced}
                                      onChange={(e) =>
                                        handleBatchEscrowAdjustChange(e, index)
                                      }
                                    />
                                  </span>
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                  <span className="inline-flex items-center">
                                    <span className="mb-1">$</span>
                                    <input
                                      className="w-full border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                      type="text"
                                      name="escrow_withheld"
                                      value={data.escrow_withheld}
                                      onChange={(e) =>
                                        handleBatchEscrowAdjustChange(e, index)
                                      }
                                    />
                                  </span>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-base text-gray-500">
                                  <span className="inline-flex items-center">
                                    <span className="mb-1">$</span>
                                    <input
                                      className="w-full border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767]"
                                      type="text"
                                      name="manual_escrow_offset"
                                      value={data.manual_escrow_offset}
                                      onChange={(e) =>
                                        handleBatchEscrowAdjustChange(e, index)
                                      }
                                    />
                                  </span>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-lg font-bold text-gray-900">
                                  {formatUSD(data.total_payable_amount)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center">
                <div className="w-full flex flex-col justify-start items-center gap-3 md:flex-row">
                  <span className="text-base">Overall Payable Amount:</span>
                  <span className="text-2xl font-bold">
                    {formatUSD(sumTotalPayableAmount())}
                  </span>
                </div>
                <div className="w-full my-2 mx-2">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      console.log(batchEscrowAdjustData);
                      sendBatch();
                    }}
                  >
                    <div className="w-full">
                      <div className="w-full flex justify-end gap-2">
                        <button
                          type="submit"
                          className={`mt-5 rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 `}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setOpenBatchEscrowAdjustModal(false);
                            setBatchPayoutLoaders(false);
                          }}
                          className="mt-5 rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
}
