import { useEffect, useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { motion } from "framer-motion";
import Chart from "react-google-charts";
import { domainUrl, logOutHandler } from "../utils/constants";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import TotalEscrowBalanceTable from "./Tables/TotalEscrowBalanceTable";
import EscrowDashEscrowHistoryTable from "./Tables/EscrowDashEscrowHistoryTable";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EscrowOverView() {
  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const firm_id = localStorage.getItem("firm_id");

  const [data, setData] = useState([]);

  const [chartData, setChartData] = useState([]);

  const [globalLoader, setGlobalLoader] = useState(false);
  const navigate = useNavigate();

  const optionsPie = {
    title: "Escrow Overview",
    is3D: true,
    colors: ["#ff0000", "#FFBF00", "#008000"],
    titleTextStyle: {
      color: "#1a3253",
      fontSize: 24,
    },
    legend: {
      position: "right",
      alignment: "end",
      textStyle: {
        fontSize: 14,
      },
    },
    chartArea: {
      width: "100%",
      height: "70%",
      responsive: true, // Make the chart area responsive
    },
    is3D: true,
    width: "100%",
    height: "100%",
    responsive: {
      breakpoints: {
        600: {
          legend: {
            position: "bottom",
            alignment: "start",
          },
          title: "", // Empty title for mobile view
        },
        // Add more breakpoints if needed
      },
    },
  };

  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  useEffect(() => {
    const getInitialData = async () => {
      // setGlobalLoader(true);
      let url = `${domainUrl}escrow-data`;

      try {
        const res = await axios.get(
          role == "admin" ? url : `${url}?firm=${firm_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        if (res.data) {
          console.log(res.data);
          setData(res.data);
          const transformedData = res.data.map((item) => [
            item.title,
            item.value,
          ]);

          setChartData([["Title", "Amount"], ...transformedData]);
          setGlobalLoader(false);
          // toast.success("Data Loaded", { id: 1 });
        }
      } catch (error) {
        console.error("Error fetching data from backend:", error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setGlobalLoader(false);
        // toast.error("Error Loading Data", { id: 2 });
      }
    };

    getInitialData();
  }, []);

  return (
    <>
      <div>
        {globalLoader ? (
          <div className="w-full h-screen flex justify-center items-center">
            <Oval
              height={80}
              width={80}
              color="#1a3253"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#396181"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <div>
            <motion.div
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.1, type: "spring", stiffness: 120 }}
            >
              <main className="py-10">
                <div className="w-full">
                  <div className="mt-5 flex flex-col gap-5">
                    <div className="w-full shadow-md  border border-gray-200 rounded-xl leading-normal p-2">
                      <Chart
                        chartType="PieChart"
                        data={chartData}
                        options={optionsPie}
                        width={"100%"}
                        height={"400px"}
                      />
                    </div>
                    <div className="w-full  shadow-md  border border-gray-200 rounded-xl leading-normal p-2">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Since Last week
                      </h3>
                      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
                        {data.length
                          ? data.map((item, index) => (
                              <div key={index} className="px-4 py-5 sm:p-6">
                                <dt className="text-base font-normal text-gray-900">
                                  {item.title}
                                </dt>
                                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                    {formatUSD(item.value)}
                                  </div>
                                </dd>
                              </div>
                            ))
                          : null}
                      </dl>
                    </div>
                  </div>
                </div>
                {role == "admin" ? <TotalEscrowBalanceTable /> : ""}
                {role == "admin" ? <EscrowDashEscrowHistoryTable /> : ""}
              </main>
            </motion.div>
          </div>
        )}
      </div>
    </>
  );
}
