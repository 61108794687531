import React from "react";
import PausedClientsTable from "./Tables/PausedClientsTable";

const PausedClients = () => {
  return (
    <div>
      <PausedClientsTable />
    </div>
  );
};

export default PausedClients;
