import { useEffect, useMemo, useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import ExportImg from "../../assets/img/excel-img.png";
import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import { Avatar, Button } from "@mui/material";
import toast from "react-hot-toast";
import axios from "axios";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AttorneyAllPaymentsTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const access_token = localStorage.getItem("access_token");
  const query_param = localStorage.getItem("query_param/total_contract");

  useEffect(() => {
    if (!query_param) {
      navigate(-1);
    }

    return () => {
      localStorage.removeItem("query_param/total_contract");
    };
  }, []);

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 as getMonth() returns 0-based index
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }
      const url = new URL(
        // 'event-reports','http://191.96.1.252:8000/',
        "attorney-all-payments",
        `${domainUrl}`
      );

      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;
        console.log("###", data);
        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);

        // setRowCount(Math.ceil(data.count / pagination.pageSize));
        // setRowCount(5);
        // Math.ceil(totalCount / pagination.pageSize)
        // setTotalCount(data.count);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "clients",
        header: "",
        columns: [
          {
            id: "contract__id",
            header: "Contract Id",
            enableColumnActions: false,
            // enableSorting: false,
            accessorFn: (row) => `${row.contracts[0].id}`,
            accessorKey: "contract__id",
            size: 30,
          },
          {
            id: "contract__case_type",
            header: "Case Name",
            enableColumnActions: false,
            // enableSorting: false,
            accessorFn: (row) => `${row.contracts[0].case_type}`,
            accessorKey: "contract__case_type",
            size: 30,
          },
          {
            id: "contract__client__user_model__first_name",
            header: "Client Name",
            enableColumnActions: false,
            // enableSorting: false,
            accessorFn: (row) =>
              `${row.client.user.first_name} ${row.client.user.last_name}`,
            accessorKey: "contract__client__user_model__first_name",
            size: 30,
          },
          {
            id: "contract__payment_schedules__date",
            header: "Schedule Date",
            enableColumnActions: false,
            // enableSorting: false,
            accessorFn: (row) => `${formatDate(row.date)}`,
            accessorKey: "contract__payment_schedules__date",
            muiTableHeadCellFilterTextFieldProps: { placeholder: "YYYY-MM-DD" },
            size: 30,
          },
          {
            id: "contract__payment_schedules__amount",
            header: "Amount",
            accessorFn: (row) => {
              console.log(
                row.amount,
                typeof row.amount,
                formatUSD(row?.amount)
              );

              return formatUSD(row?.amount);
            },
            enableColumnActions: false,
            // enableSorting: false,
            accessorKey: "contract__payment_schedules__amount",
            size: 30,
          },
        ],
      },
    ],

    []
  );

  const exportFileHandler = async (id) => {
    const url = new URL(
      // 'event-reports','http://191.96.1.252:8000/',
      "transactions",
      `${domainUrl}`
    );
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        console.log(response);
        console.log(response.headers.get("content-type"));
        console.log(typeof response.headers.get("content-type"));
        if (response.headers.get("content-type") == "application/json") {
          // toast
          console.log("inside json if");
          console.log(response.data.scheduled);
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Transactions_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                All Payments
              </h2>
              <div className="text-base text-[#9ca0b3] my-2">
                List of all payments
              </div>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar
                  enableStickyHeader
                  muiTableContainerProps={{
                    sx: { maxHeight: "500px" },
                  }}
                  // enableColumnResizing
                  // columnResizeMode="onEnd"
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff", // Set the background color for even rows
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                      // m: 'auto'
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      textAlign: "center",
                      // background: "rgba(52, 210, 235, 0.1)",
                      // borderRight: "1px solid rgba(224,224,224,1)",
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  // enableRowActions
                  positionActionsColumn="last"
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: "Action", //change header text
                      size: 30, //make actions column wider
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    //implement row selection click events manually
                    onClick: () => {
                      if (data.length) {
                        console.log(row.original.id);
                        // console.log(data[row.id - 1].id);
                        console.log("Clicked on ", row.id);
                        console.log("Value Check ", row);

                        // localStorage.setItem("view_client_id", row.id);
                      }
                    },
                    // selected: rowSelection[row.id],
                    sx: {
                      cursor: "pointer",
                      color: "black",
                      backgroundColor: "#ffffff",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  // manualFiltering
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  // rowCount={Math.ceil(totalCount / pagination.pageSize)}
                  state={{
                    // columnFilters,
                    // search,
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                    // columnVisibility: columnName,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
