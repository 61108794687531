import React, { Fragment, useState, useEffect } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import "../App.css";
import writting from "../Images/icons/writing.png";
import { Transition, Listbox } from "@headlessui/react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { domainUrl, logOutHandler } from "../utils/constants";
import { Oval, TailSpin } from "react-loader-spinner";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { addClientPost } from "../utils/redux/slice/addClientSlice";
import ModalComponent from "../Components/ModalComponent";
import "../../src/assets/styles/react_calendar.css";
import ReactCalendar from "react-calendar";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import toast from "react-hot-toast";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CalendarMonth } from "@mui/icons-material";
import CurrencyFormat from "react-currency-format";

const AdminRescheduleComponent = ({
  refetchReschedule,
  setRefetchReschedule,
  contract_id,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const [openReschedule, setOpenReschedule] = useState(false);
  const localizer = momentLocalizer(moment);
  const access_token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [holidayEvent, setHolidayEvent] = useState([]);
  const [data, setData] = useState({
    contract: {
      // contract_amount: "",
      tenure: "",
      // down_payment: "",
      // client_monthly_income: "",
      // installment_amount: "",
      payment_plan: "",
      scheduled_time: [],
      start_date: "",
    },
  });

  const [errorFlag, setErrorFlag] = useState({
    contract: {
      contract_amount: "",
      tenure: "",
      down_payment: "",
      client_monthly_income: "",
      installment_amount: "",
      payment_plan: "",
      scheduled_time: "",
      start_date: "",
    },
  });

  const [errorFlagPayField, setErrorFlagPayField] = useState({
    day: "",
    date: "",
    weeks: "",
  });
  const [submitClientLoader, setSubmitClientLoader] = useState(false);
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState();
  const [displayWeekDay, setDisplayWeekDay] = useState();
  const [displayDate, setDisplayDate] = useState();
  const [displayWeeks, setDisplayWeeks] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [calendarShowState, setCalendarShowState] = useState(false);
  const [previewSchedule, setPreviewSchedule] = useState("");

  const errMsg = {
    tenure: "Tenure period is greater than usual.",
  };
  const paymentPlanString = {
    weekly: "Weekly Payments",
    bi_weekly: "Bi-Weekly Payments",
    semi_monthly_dates: "Semi-Monthly By Date",
    semi_monthly_days: "Semi-Monthly By Day",
    monthly: "Monthly Payments",
  };
  const [paymentType, setPaymentType] = useState([
    { id: 1, name: "weekly" },
    { id: 2, name: "bi_weekly" },
    { id: 3, name: "semi_monthly_dates" },
    { id: 3, name: "semi_monthly_days" },
    { id: 4, name: "monthly" },
  ]);

  const dateNumber = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const [selectedDateNumber, setSelectedDateNumber] = useState(dateNumber);

  const [weeklyDayField, setWeekDayField] = useState([
    { id: 0, name: "MONDAY" },
    { id: 1, name: "TUESDAY" },
    { id: 2, name: "WEDNESDAY" },
    { id: 3, name: "THURSDAY" },
    { id: 4, name: "FRIDAY" },
  ]);

  const [week, setWeek] = useState([
    { id: 1, name: "Week One" },
    { id: 2, name: "Week Two" },
    { id: 3, name: "Week Three" },
    { id: 4, name: "Week Four" },
    // { id: 5, name: "Week Five" },
  ]);

  const length_validate = {
    contract_amount: 10,
    tenure: 2,
    client_monthly_income: 10,
    down_payment: 10,
    semi_monthly_dates: 2,
    semi_monthly_days: 2,
  };

  const calendarViews = {
    month: true, // Show the month view
    // week: true, // Show the week view
    // day: false, // Remove the day view
    // agenda: false, // Remove the agenda view
  };
  const formats = {
    monthHeaderFormat: "MMMM yyyy", // Format for month and year (e.g., "October 2023")
  };

  function getDayFromDayName(day) {
    let id = 0;
    weeklyDayField.forEach((item, index) => {
      if (item.name == day) {
        id = item.id;
      }
    });
    return id;
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  function CustomEvent({ event }) {
    return (
      <div>
        {event.type === "payment_missed" && (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{event.title}</Typography>
                <em>{event.description}</em>
              </React.Fragment>
            }
          >
            <div className="sm:text-sm m-0 lg:text-base">
              <div>{event.title}</div>
              {/* <div>{event.description.substring(0, 20) + "..."}</div> */}
            </div>
          </HtmlTooltip>
        )}
        {event.type === "upcoming_payment" && (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">$ {event.title}</Typography>
                <em>{event.description}</em>
              </React.Fragment>
            }
          >
            <div className="sm:text-sm m-0 lg:text-base">
              <div>$ {event.title}</div>
              {/* <div>{event.description.substring(0, 5) + "..."}</div> */}
            </div>
          </HtmlTooltip>
        )}
        {event.type === "holiday" && (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{event.title}</Typography>
                <em>{event.description}</em>
              </React.Fragment>
            }
          >
            <div className="sm:text-sm m-0 lg:text-base">
              <div>{event.title}</div>
              {/* <div>{event.description.substring(0, 5) + "..."}</div> */}
            </div>
          </HtmlTooltip>
        )}
        {event.type === "note" && (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{event.title}</Typography>
                <em>{event.description}</em>
              </React.Fragment>
            }
          >
            <div className="p-2 flex justify-end">
              <img src={writting} className="w-4"></img>
            </div>
          </HtmlTooltip>
        )}
      </div>
    );
  }

  const assignHoliday = async () => {
    try {
      const res = await axios.get(`${domainUrl}public-holidays`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        console.log("Holidays Loaded", res.data);
        let holidays = res.data;
        let selectedHoliday = [];
        holidays.map((value, index) => {
          let temp = {
            id: "",
            title: "",
            start: "",
            end: "",
            type: "",
            description: "",
          };
          temp.id = value.id;
          temp.title = value.title;
          // temp.start = new Date(value.date);
          // temp.end = new Date(value.date);
          let dateObject = new Date(`${value.date}T00:00:00`);
          temp.start = dateObject.toLocaleDateString("en-CA");
          temp.end = dateObject.toLocaleDateString("en-CA");
          temp.type = "holiday";
          selectedHoliday.push(temp);
        });
        console.log("selected holidays : ", selectedHoliday);
        setHolidayEvent(selectedHoliday);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  const getClientScheduleDetailsHandler = async () => {
    try {
      let calenderData;

      switch (data.contract.payment_plan) {
        case "weekly":
        case "bi_weekly":
          calenderData = {
            amount: parseInt(
              data.contract.contract_amount.replace(/[$,]/g, "")
            ),
            frequency: data.contract.payment_plan,
            day_of_week: getDayFromDayName(
              data.contract.scheduled_time[0].name
            ),
            tenure: parseInt(data.contract.tenure),
            start_date: data.contract.start_date,
          };
          break;

        case "semi_monthly_dates":
          calenderData = {
            amount: parseInt(
              data.contract.contract_amount.replace(/[$,]/g, "")
            ),
            frequency: data.contract.payment_plan,
            dates: data.contract.scheduled_time,
            tenure: parseInt(data.contract.tenure),
            start_date: data.contract.start_date,
          };
          break;

        case "semi_monthly_days":
          calenderData = {
            amount: parseInt(
              data.contract.contract_amount.replace(/[$,]/g, "")
            ),
            frequency: data.contract.payment_plan,
            weeks: data.contract.scheduled_time[0].weeks,
            day: getDayFromDayName(data.contract.scheduled_time[0].day),
            tenure: parseInt(data.contract.tenure),
            start_date: data.contract.start_date,
          };
          break;

        case "monthly":
          calenderData = {
            amount: parseInt(
              data.contract.contract_amount.replace(/[$,]/g, "")
            ),
            frequency: data.contract.payment_plan,
            date_of_month: data.contract.scheduled_time[0],
            tenure: parseInt(data.contract.tenure),
            start_date: data.contract.start_date,
          };
          break;

        default:
          break;
      }

      const res = await axios.post(
        `${domainUrl}generate-scheduler`,
        calenderData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        let selectedPayments = [];
        console.log(res.data.payments, "incomming dates--");
        res.data.payments.map((value, index) => {
          let temp = {
            id: "",
            title: "",
            start: "",
            end: "",
            type: "",
            description: "",
          };
          temp.id = index;
          temp.title = value.amount;
          // let dateObject = new Date(`${value.date}T00:00:00`);
          // temp.start = dateObject.toLocaleDateString();
          // temp.end = dateObject.toLocaleDateString();
          let dateObject = new Date(`${value.date}T00:00:00`);
          temp.start = dateObject.toLocaleDateString("en-CA");
          temp.end = dateObject.toLocaleDateString("en-CA");
          temp.type = "upcoming_payment";
          selectedPayments.push(temp);
        });

        console.log("Selected Payment", selectedPayments);
        console.log([...selectedPayments, ...holidayEvent]);
        setEvents([...selectedPayments, ...holidayEvent]);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  // useEffect(() => {
  //   if (
  //     data.contract.payment_plan &&
  //     data.contract.tenure &&
  //     data.contract.scheduled_time &&
  //     data.contract.start_date
  //   ) {
  //     getClientScheduleDetailsHandler();
  //   }
  // }, [
  //   data.contract?.payment_plan,
  //   data.contract?.tenure,
  //   data.contract?.scheduled_time,
  //   data.contract?.start_date,
  // ]);

  useEffect(() => {
    assignHoliday();
  }, []);

  const [pickableDates, setPickableDates] = useState([]);

  const tileContent = ({ date }) => {
    // console.log(date.toLocaleDateString('en-CA').replaceAll("-","/"))
    if (pickableDates.includes(date.toLocaleDateString("en-CA"))) {
      return <div className="bg-violet-800 h-1 rounded-3xl "></div>;
    }
    return null;
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = "";

    if (event.type === "upcoming_payment") {
      backgroundColor = "#1e90ff"; // Set the color for payment events
    } else if (event.type === "holiday") {
      backgroundColor = "#a83232"; // Set the color for holiday events
    } else if (event.type === "payment_missed") {
      backgroundColor = "#fcba03"; // Set the color for holiday events
    } else if (event.type === "note") {
      backgroundColor = "white"; // Set the color for holiday events
    }

    const style = {
      backgroundColor,
      color: "white",
    };

    return {
      style,
    };
  };
  function isWeekend(date) {
    const day = date.getDay();
    return day === 0 /* Sunday */ || day === 6 /* Saturday */;
  }
  const generatePickableDates = (value) => {
    let tempPickableDates = [];
    let allowedDuration = 2;
    var timezoneOffsetInMinutes = new Date().getTimezoneOffset();

    const today = new Date();
    // today.setDate(today.getDate());
    console.log("Today before", today);
    today.setHours(today.getHours() + today.getTimezoneOffset() / 60);
    // const today = new Date();
    console.log("Today After", today);
    const endDate = new Date();
    endDate.setHours(endDate.getHours() + endDate.getTimezoneOffset() / 60);
    // endDate.setMonth(today.getMonth() + allowedDuration);
    endDate.setDate(endDate.getDate() + 31);

    const formattedEndDate = endDate
      .toLocaleDateString("en-CA")
      .replace(/-/g, "/");

    function getSpecificDayOfMonth(year, month, dayOfWeek) {
      console.log(year, month, dayOfWeek);
      const result = [];
      const currentDate = new Date(year, month - 1, 1); // Month is 0-indexed in JavaScript

      while (currentDate.getMonth() === month - 1) {
        if (currentDate.getDay() === dayOfWeek) {
          const formattedDate = currentDate.toLocaleDateString("en-CA"); // Format as "yyyy-mm-dd"
          result.push(formattedDate);
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return result;
    }
    function getWeekIndex(date) {
      const dayIndex = date.getDay();
      const dayOfMonth = date.getDate();
      return Math.ceil(dayOfMonth / 7);
    }
    if (data.contract.payment_plan == "monthly") {
      for (let i = 0; i <= allowedDuration; i++) {
        const date = new Date();
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);

        date.setDate(1);
        date.setMonth(today.getMonth() + i);
        date.setDate(value[0]);
        if (today < date) {
          tempPickableDates.push(date.toLocaleDateString("en-CA"));
        }
      }
    } else if (data.contract.payment_plan == "semi_monthly_dates") {
      console.log(today, "$$$$");
      for (let i = 0; i <= allowedDuration; i++) {
        const date = new Date();
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        console.log("Date is:", date);
        date.setDate(1);
        date.setMonth(today.getMonth() + i);
        for (let j of value) {
          date.setDate(j);
          console.log("Picked", date);
          console.log("Adjusted", date);
          console.log(today < date, "Value");
          console.log(today);
          if (today < date) {
            tempPickableDates.push(date.toLocaleDateString("en-CA"));
          }
        }
      }
    } else if (data.contract.payment_plan == "semi_monthly_days") {
      const convert_day = {
        MONDAY: 1,
        TUESDAY: 2,
        WEDNESDAY: 3,
        THURSDAY: 4,
        FRIDAY: 5,
        SATURDAY: 6,
        SUNDAY: 0,
      };

      if (value[0]?.weeks) {
        for (let i = 0; i <= allowedDuration; i++) {
          const date = new Date();
          date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
          date.setDate(1);
          date.setMonth(today.getMonth() + i);
          let weeks = getSpecificDayOfMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            convert_day[value[0].day]
          );
          console.log(weeks);
          for (let day of weeks) {
            console.log(day, new Date(day), date);
            if (
              new Date(day) > today &&
              value[0].weeks.includes(getWeekIndex(new Date(day)))
            ) {
              tempPickableDates.push(day);
            }
          }
        }
      }
    } else if (
      data.contract.payment_plan == "weekly" ||
      data.contract.payment_plan == "bi_weekly"
    ) {
      const convert_day = { 0: 1, 1: 2, 2: 3, 3: 4, 4: 5, 5: 6, 6: 0 };
      for (let i = 0; i <= allowedDuration; i++) {
        const date = new Date();
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        date.setDate(1);
        date.setMonth(today.getMonth() + i);
        let weeks = getSpecificDayOfMonth(
          date.getFullYear(),
          date.getMonth() + 1,
          convert_day[value[0].id]
        );
        console.log(weeks);
        for (let day of weeks) {
          console.log(day, new Date(day), date);
          if (new Date(day) > today) {
            tempPickableDates.push(day);
          }
        }
      }
      console.log(tempPickableDates);
    }
    tempPickableDates = tempPickableDates.map((dateString) => {
      const date = new Date(dateString);
      date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
      while (isWeekend(date)) {
        // Move to the next Monday
        date.setDate(date.getDate() + 1); // 8 - current day gives the days until Monday
      }
      return date.toLocaleDateString("en-CA");
    });
    let newtempPickableDates = [];
    for (let dateString of tempPickableDates) {
      const date = new Date(dateString);
      date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
      if (date <= endDate) {
        newtempPickableDates.push(dateString);
      }
    }
    setPickableDates(newtempPickableDates);
  };
  const formatDate = (dateString) => {
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  const filterNumberLength = (value, max) => {
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length > max) {
      return numericValue.slice(0, max);
    }
    return numericValue;
  };
  const formHandler = async (e, form_type, index = -1) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setErrorFlag((prevErrorFlag) => ({
      ...prevErrorFlag,
      [form_type]: {
        ...prevErrorFlag[form_type],
        [e.target.name]: "",
      },
    }));
    if (form_type == "contract") {
      console.log(e.target, e);
      if (e.target.name == "case_number") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value,
          },
        }));
      } else if (e.target.name == "payment_plan") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value,
          },
        }));
      } else if (e.target.name == "contract_amount") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value,
            // [e.target.name]: filterNumberLength(
            //   e.target.value,
            //   length_validate.contract_amount
            // ),
          },
        }));
      } else if (e.target.name == "tenure") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: filterNumberLength(
              e.target.value,
              length_validate.tenure
            ),
          },
        }));
      } else if (e.target.name == "client_monthly_income") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value,
            // [e.target.name]: filterNumberLength(
            //   e.target.value,
            //   length_validate.client_monthly_income
            // ),
          },
        }));
      } else if (e.target.name == "down_payment") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value,
            // [e.target.name]: filterNumberLength(
            //   e.target.value,
            //   length_validate.down_payment
            // ),
          },
        }));
      }
    }
    setTypingTimeout(
      setTimeout(async () => validateFormFields(e, form_type, index), 300)
    );
  };

  const validateFormFields = async (e, form_type, index = -1) => {
    if (form_type == "contract") {
    }
  };

  const isSubmitButtonDisabled = () => {
    const requiredFields = [
      // data.contract.contract_amount,
      data.contract.tenure,
      // data.contract.down_payment,
      // data.contract.client_monthly_income,
      data.contract.payment_plan,
      data.contract.scheduled_time,
      data.contract.start_date,
    ];
    const allRequiredFields = [...requiredFields];
    for (const key in errorFlag.contract) {
      // console.log(key, data.contract[key]);
      if (data.contract[key] == "") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          contract: {
            ...prevErrorFlag["contract"],
            [key]: `*${key
              .replace("tenure", "Tenure")
              .replace("client_monthly_income", "monthly income")
              .replace(/_/g, " ")
              .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
          },
        }));
      }
    }
    if (data.contract.payment_plan == "semi_monthly_days") {
      if (!data.contract.scheduled_time[0]?.day) {
        setErrorFlagPayField((prevValue) => ({
          ...prevValue,
          day: "*Day is required",
        }));
      }
      if (
        !data.contract.scheduled_time[0]?.weeks ||
        data.contract.scheduled_time[0]?.weeks.length == 0
      ) {
        setErrorFlagPayField((prevValue) => ({
          ...prevValue,
          weeks: "*Weeks are required",
        }));
      }
    } else if (
      data.contract.payment_plan == "semi_monthly_dates" ||
      data.contract.payment_plan == "monthly"
    ) {
      if (data.contract.scheduled_time.length == 0) {
        setErrorFlagPayField((prevValue) => ({
          ...prevValue,
          date: "*Date is required",
        }));
      }
    } else if (
      data.contract.payment_plan == "weekly" ||
      data.contract.payment_plan == "bi_weekly"
    ) {
      if (data.contract.scheduled_time.length == 0) {
        setErrorFlagPayField((prevValue) => ({
          ...prevValue,
          day: "*Day is required",
        }));
      }
    }
    console.log("Required Fields", allRequiredFields);
    console.log(
      "Required ",
      allRequiredFields.some((field) => field === ""),
      errorFlagPayField.date !== "",
      errorFlagPayField.day !== "",
      errorFlagPayField.weeks !== ""
    );
    return (
      allRequiredFields.some((field) => field === "") ||
      errorFlagPayField.date !== "" ||
      errorFlagPayField.day !== "" ||
      errorFlagPayField.weeks !== ""
    );
  };
  const submitFormHandler = async () => {
    const isClientDetailError = isSubmitButtonDisabled();
    console.log(isClientDetailError);
    if (isClientDetailError) {
      toast.error("Complete the Fields", { id: 1 });
      return;
    }

    let submitData = data;
    // submitData.contract.contract_amount =
    //   submitData.contract.contract_amount.replace(/[$,]/g, "");
    submitData.contract.tenure = parseInt(submitData.contract.tenure);
    setSubmitClientLoader(true);
    try {
      const res = await axios.post(
        `${domainUrl}reschedule-payments?contract=${contract_id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setRefetchReschedule(!refetchReschedule);
        setSubmitClientLoader(false);
        setOpenReschedule(false);
        setData({
          contract: {
            // contract_amount: "",
            tenure: "",
            // down_payment: "",
            // client_monthly_income: "",
            // installment_amount: "",
            payment_plan: "",
            scheduled_time: [],
            start_date: "",
          },
        });
        setErrorFlag({
          contract: {
            // contract_amount: "",
            tenure: "",
            // down_payment: "",
            // client_monthly_income: "",
            // installment_amount: "",
            payment_plan: "",
            scheduled_time: "",
            start_date: "",
          },
        });
        setErrorFlagPayField({
          day: "",
          date: "",
          weeks: "",
        });
        setSelectedPaymentPlan();
        setDisplayWeekDay();
        setDisplayDate();
        setDisplayWeeks();
        setCalendarShowState(false);
        setWeekDayField([
          { id: 0, name: "MONDAY" },
          { id: 1, name: "TUESDAY" },
          { id: 2, name: "WEDNESDAY" },
          { id: 3, name: "THURSDAY" },
          { id: 4, name: "FRIDAY" },
        ]);
        setWeek([
          { id: 1, name: "Week One" },
          { id: 2, name: "Week Two" },
          { id: 3, name: "Week Three" },
          { id: 4, name: "Week Four" },
          // { id: 5, name: "Week Five" },
        ]);
        setPickableDates([]);
      }
    } catch (error) {
      console.log(error);
      setSubmitClientLoader(false);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      toast.error(error.response.data.detail,{id:1})
    }
  };

  return (
    <>
      <button
        onClick={() => {
          setOpenReschedule(true);
        }}
        className={`my-2 w-full bg-[#272729] text-white hover:bg-[#172729] focus:outline-none focus:bg-[#272729] rounded-lg
                                     px-4 py-2 sm:w-auto`}
      >
        RESCHEDULE PAYMENTS
      </button>
      <ModalComponent open={openReschedule} handleClose={() => {}}>
        <div className="w-full sm:flex justify-center">
          <div className="w-full bg-white p-3 rounded-lg sm:max-w-2xl">
            <div className="w-full">
              {/* <div className="border rounded-xl px-5 my-5">
                <form>
                  <div className="w-full flex justify-start items-center py-5">
                    <h2 className="text-lg font-semibold text-gray-900">
                      Re-schedule Payment
                    </h2>
                  </div>
                  <div className="border-gray-900/10 pb-12">
                    <div className="grid sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-5 mt-5">
                      <div className="col-span-2 sm:col-span-1 lg:col-span-2 grid mt-5">
                        <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                          Contract Amount
                        </label>
                        <div className="mt-2 text-start">
                          <CurrencyFormat
                            name="contract_amount"
                            id="contract_amount"
                            placeholder="$0.00"
                            value={data.contract.contract_amount}
                            onChange={(e) => formHandler(e, "contract")}
                            displayType={"input"}
                            thousandSeparator={true}
                            prefix={"$"}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.contract?.contract_amount}
                          </span>
                        </div>
                      </div>

                      <div className="col-span-2 sm:col-span-1 lg:col-span-2 grid mt-5">
                        <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                          Contract Period
                        </label>
                        <div className="mt-2  text-start">
                          <input
                            id="tenure"
                            name="tenure"
                            placeholder="Months"
                            value={data.contract.tenure}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                            onChange={(e) => {
                              formHandler(e, "contract");
                            }}
                          />

                          <span
                            className={
                              errorFlag.contract?.tenure
                                ? "text-red-500 text-start text-xs"
                                : "text-yellow-500 text-start text-xs"
                            }
                          >
                            {errorFlag.contract?.tenure
                              ? errorFlag.contract?.tenure
                              : parseInt(data.contract.tenure) > 10
                              ? errMsg.tenure
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div> */}
              <div className="border rounded-xl px-5 my-5">
                <form>
                  <div className="w-full flex justify-start items-center py-5">
                    <h2 className="text-lg font-semibold text-gray-900">
                      Re-schedule Payment
                    </h2>
                  </div>
                  <div className="">
                    <div className="border-gray-900/10 pb-12">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
                      <div className="col-span-2 grid mt-5">
                        <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                          Tenure
                        </label>
                        <div className="mt-2  text-start">
                          <input
                            id="tenure"
                            name="tenure"
                            placeholder="Months"
                            value={data.contract.tenure}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                            onChange={(e) => {
                              formHandler(e, "contract");
                            }}
                          />

                          <span
                            className={
                              errorFlag.contract?.tenure
                                ? "text-red-500 text-start text-xs"
                                : "text-yellow-500 text-start text-xs"
                            }
                          >
                            {errorFlag.contract?.tenure
                              ? errorFlag.contract?.tenure
                              : parseInt(data.contract.tenure) > 10
                              ? errMsg.tenure
                              : ""}
                          </span>
                        </div>
                      </div>
                      </div>
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
                        <div className="text-start col-span-1 sm:col-span-2 md:col-span-3">
                          <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                            Choose schedule Type
                          </label>
                          <Listbox
                            name="payment_plan"
                            value={data.contract.payment_plan}
                            onChange={(value) => {
                              setErrorFlagPayField({
                                ...errorFlagPayField,
                                day: "",
                                date: "",
                                weeks: "",
                              });
                              setPickableDates([]);
                              setErrorFlag((prevErrorFlag) => ({
                                ...prevErrorFlag,
                                contract: {
                                  ...prevErrorFlag["contract"],
                                  payment_plan: "",
                                  start_date: "",
                                },
                              }));
                              setCalendarShowState(false);
                              setData((prevValue) => ({
                                ...prevValue,
                                contract: {
                                  ...prevValue["contract"],
                                  start_date: "",
                                },
                              }));
                              setData((prevValue) => ({
                                ...prevValue,
                                contract: {
                                  ...prevValue["contract"],
                                  payment_plan: value.name,
                                  scheduled_time: [],
                                },
                              }));
                              setSelectedPaymentPlan(value);
                              setDisplayDate([]);
                              setDisplayWeekDay(undefined);
                              setDisplayWeeks([]);
                              setWeekDayField([
                                { id: 0, name: "MONDAY" },
                                { id: 1, name: "TUESDAY" },
                                { id: 2, name: "WEDNESDAY" },
                                { id: 3, name: "THURSDAY" },
                                { id: 4, name: "FRIDAY" },
                              ]);
                              setWeek([
                                { id: 1, name: "Week One" },
                                { id: 2, name: "Week Two" },
                                { id: 3, name: "Week Three" },
                                { id: 4, name: "Week Four" },
                                // { id: 5, name: "Week Five" },
                              ]);
                              setPreviewSchedule("");
                              setEvents([]);
                              console.log("DISPLAYED WEEK", displayWeeks);
                            }}
                          >
                            {({ open }) => (
                              <>
                                <div className="relative mt-2">
                                  <Listbox.Button className="relative w-full border-b-2 focus:border-[#344767] cursor-pointer   py-1 pl-3 pr-10 text-left text-gray-900 text-sm">
                                    <span className="block truncate">
                                      {selectedPaymentPlan &&
                                      selectedPaymentPlan ? (
                                        paymentPlanString[
                                          selectedPaymentPlan.name
                                        ]
                                      ) : (
                                        <>Select</>
                                      )}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {paymentType.map((type) => (
                                        <Listbox.Option
                                          key={type.id}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "bg-[#1a3253] text-white"
                                                : "text-gray-900",
                                              "relative cursor-default select-none py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={type}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                {paymentPlanString[type.name]}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-[#1a3253]",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>

                          <span className="text-red-500 text-start text-xs">
                            {errorFlag.contract?.payment_plan}
                          </span>
                        </div>

                        {(data.contract.payment_plan == "weekly" ||
                          data.contract.payment_plan == "semi_monthly_days" ||
                          data.contract.payment_plan == "bi_weekly") && (
                          <div className="col-span-1 sm:col-span-2 md:col-span-3">
                            <label className="text-md text-start font-medium leading-6 text-[#9ca0b3]">
                              Please Select From the below
                            </label>
                            <div className="">
                              <div className="w-full flex justify-end mt-2">
                                <label className="text-md text-start font-medium  leading-6 text-[#9ca0b3] mr-5">
                                  Every
                                </label>
                                <div className="w-1/2">
                                  <div className="">
                                    <Listbox
                                      value={displayWeekDay}
                                      onChange={(value) => {
                                        setDisplayWeekDay(value);
                                        if (
                                          data.contract.payment_plan ==
                                            "weekly" ||
                                          data.contract.payment_plan ==
                                            "bi_weekly"
                                        ) {
                                          setPickableDates([]);
                                          setData((prevValue) => ({
                                            ...prevValue,
                                            contract: {
                                              ...prevValue["contract"],
                                              start_date: "",
                                            },
                                          }));
                                          setData((prevValue) => ({
                                            ...prevValue,
                                            contract: {
                                              ...prevValue["contract"],
                                              scheduled_time: [value],
                                            },
                                          }));
                                          generatePickableDates([value]);
                                        } else {
                                          // Payment plan -> Semi monthly days
                                          setErrorFlagPayField((prevValue) => ({
                                            ...prevValue,
                                            day: "",
                                          }));
                                          setPickableDates([]);
                                          setData((prevValue) => ({
                                            ...prevValue,
                                            contract: {
                                              ...prevValue["contract"],
                                              start_date: "",
                                            },
                                          }));
                                          generatePickableDates([
                                            {
                                              ...data.contract
                                                .scheduled_time[0],
                                              day: value.name,
                                            },
                                          ]);
                                          setData((prevValue) => ({
                                            ...prevValue,
                                            contract: {
                                              ...prevValue["contract"],
                                              scheduled_time: [
                                                {
                                                  ...prevValue.contract
                                                    .scheduled_time[0],
                                                  day: value.name,
                                                },
                                              ],
                                            },
                                          }));
                                        }
                                      }}
                                    >
                                      {({ open }) => (
                                        <>
                                          <div className="relative">
                                            <Listbox.Button className="relative w-full border-b-2 focus:border-[#344767] cursor-pointer   py-1 pl-3 pr-10 text-left text-gray-900 text-sm">
                                              <span className="block truncate">
                                                {displayWeekDay ? (
                                                  displayWeekDay.name
                                                ) : (
                                                  <span className="text-[#9ca0b3]">
                                                    Select
                                                  </span>
                                                )}
                                              </span>
                                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                  className="h-5 w-5 text-gray-400"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            </Listbox.Button>

                                            <Transition
                                              show={open}
                                              as={Fragment}
                                              leave="transition ease-in duration-100"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {weeklyDayField.map((type) => (
                                                  <Listbox.Option
                                                    key={type.id}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active
                                                          ? "bg-[#1a3253] text-white"
                                                          : "text-gray-900",
                                                        "relative cursor-default select-none py-2 pl-8 pr-4"
                                                      )
                                                    }
                                                    value={type}
                                                  >
                                                    {({ selected, active }) => (
                                                      <>
                                                        <span
                                                          className={classNames(
                                                            selected
                                                              ? "font-semibold"
                                                              : "font-normal",
                                                            "block truncate"
                                                          )}
                                                        >
                                                          {type.name}
                                                        </span>

                                                        {selected ? (
                                                          <span
                                                            className={classNames(
                                                              active
                                                                ? "text-white"
                                                                : "text-indigo-600",
                                                              "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                                            )}
                                                          >
                                                            <CheckIcon
                                                              className="h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          </span>
                                                        ) : null}
                                                      </>
                                                    )}
                                                  </Listbox.Option>
                                                ))}
                                              </Listbox.Options>
                                            </Transition>
                                          </div>
                                        </>
                                      )}
                                    </Listbox>
                                  </div>
                                  <span className="text-red-500 text-start text-xs">
                                    {errorFlagPayField?.day}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(data.contract.payment_plan == "monthly" ||
                          data.contract.payment_plan ==
                            "semi_monthly_dates") && (
                          <div className="col-span-1 sm:col-span-2 md:col-span-3">
                            <label className="text-md text-start font-medium leading-6 text-[#9ca0b3]">
                              Choose your preferred date
                            </label>
                            <div className="">
                              <div className="w-full flex justify-end mt-2">
                                <label className="text-md text-start font-medium leading-6 text-[#9ca0b3] mr-5">
                                  Every
                                </label>
                                <div className="w-1/2">
                                  <div className="">
                                    <Listbox
                                      multiple={
                                        data.contract.payment_plan ==
                                        "semi_monthly_dates"
                                          ? true
                                          : false
                                      }
                                      value={displayDate}
                                      onChange={(value) => {
                                        if (Array.isArray(value)) {
                                          if (
                                            value.length == 0 ||
                                            value.length == 2
                                          ) {
                                            setErrorFlagPayField(
                                              (prevValue) => ({
                                                ...prevValue,
                                                date: "",
                                              })
                                            );
                                          }
                                        }
                                        if (Array.isArray(value)) {
                                          if (value.length > 2) {
                                            toast.error(
                                              "Maximum limit reached",
                                              {
                                                id: 1,
                                              }
                                            );
                                            return;
                                          }
                                        }
                                        if (Array.isArray(value)) {
                                          if (
                                            data.contract.payment_plan ==
                                            "semi_monthly_dates"
                                          ) {
                                            if (value.length !== 2) {
                                              setErrorFlagPayField(
                                                (prevValue) => ({
                                                  ...prevValue,
                                                  date: "Please select two dates",
                                                })
                                              );
                                            }
                                          }
                                        }
                                        setData((prevValue) => ({
                                          ...prevValue,
                                          contract: {
                                            ...prevValue["contract"],
                                            start_date: "",
                                          },
                                        }));

                                        console.log(value);
                                        setDisplayDate(value);

                                        setData((prevValue) => ({
                                          ...prevValue,
                                          contract: {
                                            ...prevValue["contract"],
                                            scheduled_time: Array.isArray(value)
                                              ? value.sort((x, y) => x - y)
                                              : [value],
                                          },
                                        }));
                                        //   formHandler(e, "payment_data");
                                        generatePickableDates(
                                          Array.isArray(value)
                                            ? value.sort((x, y) => x - y)
                                            : [value]
                                        );
                                      }}
                                    >
                                      {({ open }) => (
                                        <>
                                          <div className="relative">
                                            <Listbox.Button className="relative w-full border-b-2 focus:border-[#344767] cursor-pointer   py-1 pl-3 pr-10 text-left text-gray-900 text-sm">
                                              <span className="block truncate">
                                                {(
                                                  Array.isArray(displayDate)
                                                    ? displayDate.length
                                                    : displayDate
                                                ) ? (
                                                  `${
                                                    Array.isArray(displayDate)
                                                      ? [...displayDate]
                                                      : displayDate
                                                  }`
                                                ) : (
                                                  <span className="text-[#9ca0b3]">
                                                    Select
                                                  </span>
                                                )}
                                              </span>
                                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                  className="h-5 w-5 text-gray-400"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            </Listbox.Button>

                                            <Transition
                                              show={open}
                                              as={Fragment}
                                              leave="transition ease-in duration-100"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <Listbox.Options className="w-full absolute z-10 mt-1 max-h-60  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {selectedDateNumber.map(
                                                  (type, id) => (
                                                    <Listbox.Option
                                                      key={id}
                                                      className={({ active }) =>
                                                        classNames(
                                                          active
                                                            ? "bg-[#1a3253] text-white"
                                                            : "text-gray-900",
                                                          "relative cursor-default select-none py-2 pl-8 pr-4"
                                                        )
                                                      }
                                                      value={type}
                                                    >
                                                      {({
                                                        selected,
                                                        active,
                                                      }) => (
                                                        <>
                                                          <span
                                                            className={classNames(
                                                              selected
                                                                ? "font-semibold"
                                                                : "font-normal",
                                                              "block truncate"
                                                            )}
                                                          >
                                                            {type}
                                                          </span>

                                                          {selected ? (
                                                            <span
                                                              className={classNames(
                                                                active
                                                                  ? "text-white"
                                                                  : "text-indigo-600",
                                                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                                              )}
                                                            >
                                                              <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                              />
                                                            </span>
                                                          ) : null}
                                                        </>
                                                      )}
                                                    </Listbox.Option>
                                                  )
                                                )}
                                              </Listbox.Options>
                                            </Transition>
                                          </div>
                                        </>
                                      )}
                                    </Listbox>
                                  </div>
                                  <span className="text-red-500 text-start text-xs">
                                    {errorFlagPayField?.date}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {data.contract.payment_plan == "semi_monthly_days" && (
                          <div className="text-start col-span-1 sm:col-span-2 md:col-span-3">
                            <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                              Weeks
                            </label>
                            <div className="">
                              <Listbox
                                multiple
                                value={displayWeeks}
                                onChange={(value) => {
                                  if (Array.isArray(value)) {
                                    if (
                                      value.length == 0 ||
                                      value.length == 2
                                    ) {
                                      setErrorFlagPayField((prevValue) => ({
                                        ...prevValue,
                                        weeks: "",
                                      }));
                                    }
                                  }
                                  if (Array.isArray(value)) {
                                    if (value.length > 2) {
                                      toast.error("Maximum limit reached", {
                                        id: 1,
                                      });
                                      return;
                                    }
                                  }
                                  if (Array.isArray(value)) {
                                    if (
                                      data.contract.payment_plan ==
                                      "semi_monthly_days"
                                    ) {
                                      if (value.length == 1) {
                                        setErrorFlagPayField((prevValue) => ({
                                          ...prevValue,
                                          weeks: "Please select two weeks",
                                        }));
                                      }
                                    }
                                  }
                                  setData((prevValue) => ({
                                    ...prevValue,
                                    contract: {
                                      ...prevValue["contract"],
                                      start_date: "",
                                    },
                                  }));

                                  console.log("###", value);
                                  value = value.sort((a, b) => a.id - b.id);
                                  setDisplayWeeks(value);
                                  let ids = value.map((week) => week.id);

                                  generatePickableDates([
                                    {
                                      ...data.contract.scheduled_time[0],
                                      weeks: ids,
                                    },
                                  ]);

                                  setData((prevValue) => ({
                                    ...prevValue,
                                    contract: {
                                      ...prevValue["contract"],
                                      scheduled_time: [
                                        {
                                          ...prevValue.contract
                                            .scheduled_time[0],
                                          weeks: ids,
                                        },
                                      ],
                                    },
                                  }));
                                  console.log("@@@@@@@", value);
                                }}
                              >
                                {({ open }) => (
                                  <>
                                    <div className="relative mt-2">
                                      <Listbox.Button
                                        className={`relative w-full border-b-2 focus:border-[#344767] cursor-pointer   py-1 pl-3 pr-10 text-left text-gray-900 text-sm
                             `}
                                      >
                                        <span className="block truncate">
                                          {displayWeeks.length ? (
                                            displayWeeks
                                              .map((item) => item.name)
                                              .join(", ")
                                          ) : (
                                            <span className="text-[#9ca0b3]">
                                              Select
                                            </span>
                                          )}
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {week.map((type, id) => (
                                            <Listbox.Option
                                              key={id}
                                              className={({ active }) =>
                                                classNames(
                                                  active
                                                    ? "bg-[#1a3253] text-white"
                                                    : "text-gray-900",
                                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                                )
                                              }
                                              value={type}
                                            >
                                              {({ selected, active }) => (
                                                <>
                                                  <span
                                                    className={classNames(
                                                      selected
                                                        ? "font-semibold"
                                                        : "font-normal",
                                                      "block truncate"
                                                    )}
                                                  >
                                                    {type.name}
                                                  </span>

                                                  {selected ? (
                                                    <span
                                                      className={classNames(
                                                        active
                                                          ? "text-white"
                                                          : "text-[#1a3253]",
                                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                                      )}
                                                    >
                                                      <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                              <span className="text-red-500 text-start text-xs">
                                {/* {errorFlag.contract?.case_type} */}
                              </span>
                            </div>
                            <span className="text-red-500 text-start text-xs">
                              {errorFlagPayField?.weeks}
                            </span>
                          </div>
                        )}

                        <div className="col-span-1 sm:col-span-2 md:col-span-3">
                          <div className="text-start grid-cols-1 relative">
                            {calendarShowState && (
                              <div
                                className="absolute bottom-32 animate-fadeIn "
                                id="reactCalendar"
                              >
                                <ReactCalendar
                                  onClickDay={(e) => {
                                    console.log(
                                      "This e",
                                      e.toLocaleDateString("en-CA")
                                    );
                                    if (
                                      pickableDates.includes(
                                        e.toLocaleDateString("en-CA")
                                      )
                                    ) {
                                      setData((prevValue) => ({
                                        ...prevValue,
                                        contract: {
                                          ...prevValue["contract"],
                                          start_date:
                                            e.toLocaleDateString("en-CA"),
                                        },
                                      }));
                                      setCalendarShowState(false);
                                      setErrorFlag((prevValue) => ({
                                        ...prevValue,
                                        contract: {
                                          ...prevValue["contract"],
                                          start_date: "",
                                        },
                                      }));
                                    } else {
                                      setErrorFlag((prevValue) => ({
                                        ...prevValue,
                                        contract: {
                                          ...prevValue["contract"],
                                          start_date:
                                            "You can only pick highlighted dates",
                                        },
                                      }));
                                      toast.error(
                                        "You can only select from the highlighted dates",
                                        { id: 1 }
                                      );
                                    }
                                  }}
                                  tileContent={tileContent}
                                />
                              </div>
                            )}
                            <label className="text-md font-medium leading-6 text-[#9ca0b3] ">
                              Choose Starting Date
                            </label>
                            <div className="w-full">
                              <div>
                                <div>
                                  {pickableDates.length ? (
                                    <>
                                      {data.contract?.start_date != "" ? (
                                        <div className="lg:block flex xl:flex md:flex justify-end mt-2 w-full">
                                          <span className="text-black-500 text-xs w-2/3">
                                            <input
                                              readOnly
                                              value={formatDate(
                                                data.contract?.start_date
                                              )}
                                              className="p-2  border-b border-gray-400  cursor-default w-full"
                                            />
                                          </span>
                                          <>
                                            {!calendarShowState ? (
                                              <button
                                                className=" bg-indigo-700 text-white p-1 ml-2 pl-4 pr-4 rounded text-sm w-24 my-1"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setCalendarShowState(true);
                                                }}
                                              >
                                                Change
                                              </button>
                                            ) : (
                                              <button
                                                className=" bg-indigo-700 text-white p-1 ml-2 pl-4 pr-4 rounded text-sm w-24 my-1"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setCalendarShowState(false);
                                                }}
                                              >
                                                Hide
                                              </button>
                                            )}
                                          </>
                                        </div>
                                      ) : (
                                        <>
                                          <button
                                            className={`bg-indigo-600 text-white p-2 pl-4 pr-4 mt-3 sm:mt-0 rounded text-sm"`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (
                                                data.contract?.payment_plan ==
                                                  "weekly" ||
                                                data.contract?.payment_plan ==
                                                  "bi_weekly"
                                              ) {
                                                if (displayWeekDay) {
                                                  setCalendarShowState(true);
                                                }
                                              } else if (
                                                data.contract?.payment_plan ==
                                                "semi_monthly_days"
                                              ) {
                                                if (displayWeeks) {
                                                  setCalendarShowState(true);
                                                }
                                              } else if (
                                                data.contract?.payment_plan ==
                                                  "semi_monthly_dates" ||
                                                data.contract?.payment_plan ==
                                                  "monthly"
                                              ) {
                                                if (displayDate) {
                                                  setCalendarShowState(true);
                                                }
                                              }
                                            }}
                                          >
                                            Pick a Date
                                          </button>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <button
                                      className="bg-gray-700 text-white p-2 pl-4 pr-4 rounded text-sm"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                      disabled
                                    >
                                      Pick a Date
                                    </button>
                                  )}
                                </div>
                                <span className="text-red-500 text-start text-xs">
                                  {errorFlag.contract?.start_date}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-span-1 sm:col-span-2">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Installment Amount
                    </label>
                    <div className="mt-2">
                      <div className="border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]">
                        <span>
                          {data.contract.installment_amount != "NaN" ? (
                            <input
                              type="text"
                              name="installment_amount"
                              id="installment_amount"
                              readOnly
                              placeholder="$0.00"
                              className="outline-none px-2 cursor-default"
                              value={data.contract.installment_amount}
                            />
                          ) : (
                            " 0.00"
                          )}
                        </span>
                      </div>
                      <span className="text-red-500 text-xs">
                        {errorFlag.contract?.installment_amount}
                      </span>
                    </div>
                  </div> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {previewSchedule == "show" && (
                <Calendar
                  localizer={localizer}
                  startAccessor={"start"}
                  endAccessor={"end"}
                  style={{ height: "400px" }}
                  events={events}
                  components={{
                    event: CustomEvent,
                  }}
                  eventPropGetter={eventStyleGetter}
                  views={calendarViews}
                  formats={formats}
                  className="w-full bg-white shadow border border-violet-800 rounded-lg p-5 mt-5 sm:h-48 md:h-[100px]  "
                />
              )}
              <div className="my-3 flex justify-end items-center gap-3">
                {events.length ? (
                  <>
                    {previewSchedule == "show" ? (
                      <button
                        onClick={() => {
                          setPreviewSchedule("");
                        }}
                        type="button"
                        className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-100"
                      >
                        Hide
                        <CalendarMonth className="-mr-0.5 h-5 w-5" />
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setPreviewSchedule("show");
                        }}
                        type="button"
                        className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-100"
                      >
                        Preview
                        <CalendarMonth className="-mr-0.5 h-5 w-5" />
                      </button>
                    )}
                  </>
                ) : null}
                <div className="bg-gray-50 px-4 py-3 flex items-center gap-3">
                  <button
                    onClick={() => {
                      // handleUpload(); 
                      submitFormHandler();
                      // postGatewayData()
                    }}
                    type="button"
                    disabled={submitClientLoader}
                    className={`bg-[#2991e0] hover:bg-[#1991e0] text-white inline-flex gap-3 justify-center items-center   rounded-md  px-3 py-2 text-sm font-semibold  shadow-sm text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    Reschedule
                    <div>
                      {submitClientLoader && (
                        <Oval
                          height={20}
                          width={20}
                          color="#ffff"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#ffff"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      )}
                    </div>
                  </button>
                  <button
                    type="button"
                    className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpenReschedule(false);
                      setData({
                        contract: {
                          // contract_amount: "",
                          tenure: "",
                          // down_payment: "",
                          // client_monthly_income: "",
                          // installment_amount: "",
                          payment_plan: "",
                          scheduled_time: [],
                          start_date: "",
                        },
                      });
                      setErrorFlag({
                        contract: {
                          // contract_amount: "",
                          tenure: "",
                          // down_payment: "",
                          // client_monthly_income: "",
                          // installment_amount: "",
                          payment_plan: "",
                          scheduled_time: "",
                          start_date: "",
                        },
                      });
                      setErrorFlagPayField({
                        day: "",
                        date: "",
                        weeks: "",
                      });
                      setSelectedPaymentPlan();
                      setDisplayWeekDay();
                      setDisplayDate();
                      setDisplayWeeks();
                      setCalendarShowState(false);
                      setWeekDayField([
                        { id: 0, name: "MONDAY" },
                        { id: 1, name: "TUESDAY" },
                        { id: 2, name: "WEDNESDAY" },
                        { id: 3, name: "THURSDAY" },
                        { id: 4, name: "FRIDAY" },
                      ]);
                      setWeek([
                        { id: 1, name: "Week One" },
                        { id: 2, name: "Week Two" },
                        { id: 3, name: "Week Three" },
                        { id: 4, name: "Week Four" },
                        // { id: 5, name: "Week Five" },
                      ]);
                      setPickableDates([]);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
export default AdminRescheduleComponent;
