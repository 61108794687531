import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../constants";
import toast from "react-hot-toast";

export const updateClientPut = createAsyncThunk(
  "updateClientPut",
  async ({ data, id }, thunkAPI) => {
    const access_token = localStorage.getItem("access_token");
    try {
      const res = await axios.put(
        `${domainUrl}update-contract?contract_id=${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      console.log("this is res", res);
      return res.data;
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        const navigate = thunkAPI.extra.navigateCallback;
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      } else {
        throw JSON.stringify(error.response.data.detail);
      }
    }
  }
);

const updateClientSlice = createSlice({
  name: "updateClientStorage",
  initialState: { data: [], isLoading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateClientPut.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateClientPut.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateClientPut.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export default updateClientSlice.reducer;
