import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  CheckIcon,
  DocumentArrowUpIcon,
  PaperClipIcon,
  TrashIcon,
  ChevronUpDownIcon,
  DocumentDuplicateIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import "../App.css";
import writting from "../Images/icons/writing.png";
import amex from "../Images/cards/amex.png";
import bankImg from "../Images/cards/bank_img.jpeg";
import addcardImg from "../Images/cards/addcard.png";
import american_express from "../Images/cards/american_express.png";
import carteblanche from "../Images/cards/carteblanche.png";
import celo from "../Images/cards/celo.png";
import dankort from "../Images/cards/dankort.png";
import diners from "../Images/cards/diners.png";
import discover from "../Images/cards/discover.png";
import forbrugsforeningen from "../Images/cards/forbrugsforeningen.jpg";
import hipercard from "../Images/cards/hipercard.svg";
import instapayment from "../Images/cards/instapayment.webp";
import jcb from "../Images/cards/jcb.png";
import laser from "../Images/cards/laser.png";
import mastercard from "../Images/cards/mastercard.png";
import mnp from "../Images/cards/mnp.png";
import nocards from "../Images/cards/nocards.jpg";
import onpay from "../Images/cards/onpay.png";
import rupay from "../Images/cards/rupay.png";
import troy from "../Images/cards/troy.png";
import uatp from "../Images/cards/uatp.png";
import visa from "../Images/cards/visa.png";
import cancel from "../Images/cancel.png";
import visa_electron from "../Images/cards/visa_electron.png";
import vpay from "../Images/cards/vpay.png";
import { Combobox, Transition, Listbox } from "@headlessui/react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { domainUrl, listedStates, logOutHandler } from "../utils/constants";
import { getFirmProfileGet } from "../utils/redux/slice/getFirmProfileSlice";
import { postFNLClientNamePost } from "../utils/redux/slice/postFNLClientNameSlice";
import { Oval, TailSpin } from "react-loader-spinner";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ModalComponent from "../Components/ModalComponent";
import "../../src/assets/styles/react_calendar.css";
import ReactCalendar from "react-calendar";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import toast from "react-hot-toast";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CalendarMonth, Schedule } from "@mui/icons-material";
import { updateClientPut } from "../utils/redux/slice/updateClientSlice";
import CurrencyFormat from "react-currency-format";

const UpdateClients = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const localizer = momentLocalizer(moment);
  const firm_id = localStorage.getItem("firm_id");
  const view_client_id = localStorage.getItem("view_client_id");
  const client_data = JSON.parse(localStorage.getItem("client_data"));
  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const caseTypeOptionsRef = useRef(null);
  const basic_info = useRef(null);
  const paymentSchedule = useRef(null);
  const financeDetailsRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [holidayEvent, setHolidayEvent] = useState([]);
  const [scheduledDates, setScheduledDates] = useState([]);
  const [authorizeCustomerId, setAuthorizeCustomerId] = useState("");

  const [data, setData] = useState({
    client: {
      attorney: "",
      first_name: "",
      last_name: "",
      client_code: "",
      address_line1: "",
      address_line2: "",
      cell_phone_number: "",
      email: "",
      state: "",
      city: "",
      zip_code: "",
    },
    contract: {
      case_type: "",
      case_number: "",
      contract_amount: "",
      tenure: "",
      down_payment: "",
      client_monthly_income: "",
      installment_amount: "",
      payment_plan: "",
      scheduled_time: [],
      start_date: "",
    },
    files: {
      income: [],
      others: [],
      contract: [],
    },
    co_debtor: false,
    co_debtor_data: {
      name: "",
      email: "",
      cell_phone_number: "",
    },
    payment_card: {
      cardholder: "",
      card_number: "",
      expiration_date: "",
    },
    direct_deposit: {
      bank_name: "",
      bank_routing_number: "",
      bank_account_number: "",
    },
  });

  const [errorFlag, setErrorFlag] = useState({
    client: {
      firm_id: "",
      attorney: "",
      first_name: "",
      last_name: "",
      client_code: "",
      address_line1: "",
      address_line2: "",
      cell_phone_number: "",
      email: "",
      state: "",
      city: "",
      zip_code: "",
    },
    contract: {
      case_type: "",
      case_number: "",
      contract_amount: "",
      tenure: "",
      down_payment: "",
      client_monthly_income: "",
      installment_amount: "",
      payment_plan: "",
      scheduled_time: "",
      start_date: "",
    },
    files: {
      income: "",
      others: "",
      contract: "",
    },
    co_debtor_data: {
      name: "",
      email: "",
      cell_phone_number: "",
    },
    payment_card: {
      cardholder: "",
      card_number: "",
      expiration_date: "",
    },
    direct_deposit: {
      bank_name: "",
      bank_routing_number: "",
      bank_account_number: "",
    },
  });
  const [errorFlagPayField, setErrorFlagPayField] = useState({
    day: "",
    date: "",
    weeks: "",
  });

  const [firmData, setFirmData] = useState({
    firm_id: "",
    firm_name: "",
    firm_code: "",
  });
  const [submitClientLoader, setSubmitClientLoader] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    cardExpiryMonth: "",
    cardExpiryYear: "",
  });
  const [cardDetailsError, setCardDetailsError] = useState({
    cardExpiryMonth: "",
    cardExpiryYear: "",
  });
  const [cardExpiryAlert, setCardExpiryAlert] = useState(false);
  const [selectedAttorney, setSelectedAttorney] = useState();
  const [queryAttorney, setQueryAttorney] = useState("");
  const [attorneysList, setAttorneysList] = useState([]);
  const [incomeDoc, setIncomeDoc] = useState([]);
  const [additionalDoc, setAdditionalDoc] = useState([]);
  const [contractDoc, setContractDoc] = useState([]);
  const [incomeDocReceived, setIncomeDocReceived] = useState([]);
  const [additionalDocReceived, setAdditionalDocReceived] = useState([]);
  const [contractDocReceived, setContractDocReceived] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [selectedCaseType, setSelectedCaseType] = useState();
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState();
  const [displayWeekDay, setDisplayWeekDay] = useState();
  const [displayDate, setDisplayDate] = useState();
  const [displayWeeks, setDisplayWeeks] = useState();
  const [query, setQuery] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [caseTypeOptions, setCaseTypeOptions] = useState([]);
  const [clientExistData, setClientExistData] = useState({});
  const [openClientExistModal, setOpenClientExistModal] = useState({
    open: false,
    action: false,
    note: "",
  });
  const [calendarShowState, setCalendarShowState] = useState(false);
  const [previewSchedule, setPreviewSchedule] = useState("");
  const [contractId, setContractId] = useState("");
  const errMsg = {
    email: "Enter a valid email.",
    cell_phone_number: "Phone number must be 10 digit",
    zip_code: "Zipcode should be 5 digit",
    first_name: "Please enter a valid first name (1-30 characters)",
    last_name: "Please enter a valid last name (1-30 characters)",
    tenure: "Tenure period is greater than usual.",
    card_number: "Card number must be 16 digit.",
    bank_account_number:
      "Bank account number needs to be between 12 and 17 digits.",
    bank_routing_number: "Bank routing number should be 9 digit.",
  };

  const allowedFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/webp",
    "application/pdf",
  ];

  const paymentPlanString = {
    weekly: "Weekly Payments",
    bi_weekly: "Bi-Weekly Payments",
    semi_monthly_dates: "Semi-Monthly By Date",
    semi_monthly_days: "Semi-Monthly By Day",
    monthly: "Monthly Payments",
  };
  const [paymentType, setPaymentType] = useState([
    { id: 1, name: "weekly" },
    { id: 2, name: "bi_weekly" },
    { id: 3, name: "semi_monthly_dates" },
    { id: 3, name: "semi_monthly_days" },
    { id: 4, name: "monthly" },
  ]);

  const dateNumber = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const [selectedDateNumber, setSelectedDateNumber] = useState(dateNumber);

  const [weeklyDayField, setWeekDayField] = useState([
    { id: 0, name: "MONDAY" },
    { id: 1, name: "TUESDAY" },
    { id: 2, name: "WEDNESDAY" },
    { id: 3, name: "THURSDAY" },
    { id: 4, name: "FRIDAY" },
  ]);

  const [week, setWeek] = useState([
    { id: 1, name: "Week One" },
    { id: 2, name: "Week Two" },
    { id: 3, name: "Week Three" },
    { id: 4, name: "Week Four" },
    // { id: 5, name: "Week Five" },
  ]);

  const length_validate = {
    zip_code: 5,
    contract_amount: 10,
    tenure: 2,
    client_monthly_income: 10,
    down_payment: 10,
    semi_monthly_dates: 2,
    semi_monthly_days: 2,
    card_number: { min: 16, max: 16 },
    bank_account_number: { min: 5, max: 17 },
    bank_routing_number: 9,
  };

  const calendarViews = {
    month: true, // Show the month view
    // week: true, // Show the week view
    // day: false, // Remove the day view
    // agenda: false, // Remove the agenda view
  };
  const formats = {
    monthHeaderFormat: "MMMM yyyy", // Format for month and year (e.g., "October 2023")
  };

  function getDayFromDayName(day) {
    let id = 0;
    weeklyDayField.forEach((item, index) => {
      if (item.name == day) {
        id = item.id;
      }
    });
    return id;
  }
  const getStateByCode = (code) => {
    return listedStates.find((state) => state.code === code);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  function CustomEvent({ event }) {
    return (
      <div>
        {event.type === "payment_missed" && (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{event.title}</Typography>
                <em>{event.description}</em>
              </React.Fragment>
            }
          >
            <div className="sm:text-sm m-0 lg:text-base">
              <div>{event.title}</div>
              {/* <div>{event.description.substring(0, 20) + "..."}</div> */}
            </div>
          </HtmlTooltip>
        )}
        {event.type === "upcoming_payment" && (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">$ {event.title}</Typography>
                <em>{event.description}</em>
              </React.Fragment>
            }
          >
            <div className="sm:text-sm m-0 lg:text-base">
              <div>$ {event.title}</div>
              {/* <div>{event.description.substring(0, 5) + "..."}</div> */}
            </div>
          </HtmlTooltip>
        )}
        {event.type === "holiday" && (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{event.title}</Typography>
                <em>{event.description}</em>
              </React.Fragment>
            }
          >
            <div className="sm:text-sm m-0 lg:text-base">
              <div>{event.title}</div>
              {/* <div>{event.description.substring(0, 5) + "..."}</div> */}
            </div>
          </HtmlTooltip>
        )}
        {event.type === "note" && (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{event.title}</Typography>
                <em>{event.description}</em>
              </React.Fragment>
            }
          >
            <div className="p-2 flex justify-end">
              <img src={writting} className="w-4"></img>
            </div>
          </HtmlTooltip>
        )}
      </div>
    );
  }

  const assignHoliday = async () => {
    try {
      const res = await axios.get(`${domainUrl}public-holidays`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        console.log("Holidays Loaded", res.data);
        let holidays = res.data;
        let selectedHoliday = [];
        holidays.map((value, index) => {
          let temp = {
            id: "",
            title: "",
            start: "",
            end: "",
            type: "",
            description: "",
          };
          temp.id = value.id;
          temp.title = value.title;
          // temp.start = new Date(value.date);
          // temp.end = new Date(value.date);
          let dateObject = new Date(`${value.date}T00:00:00`);
          temp.start = dateObject.toLocaleDateString("en-CA");
          temp.end = dateObject.toLocaleDateString("en-CA");
          temp.type = "holiday";
          selectedHoliday.push(temp);
        });
        console.log("selected holidays : ", selectedHoliday);
        setHolidayEvent(selectedHoliday);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  const getClientScheduleDetailsHandler = async () => {
    try {
      let calenderData;

      switch (data.contract.payment_plan) {
        case "weekly":
        case "bi_weekly":
          calenderData = {
            amount: parseInt(
              data.contract.contract_amount.replace(/[$,]/g, "")
            ),
            frequency: data.contract.payment_plan,
            day_of_week: getDayFromDayName(
              data.contract.scheduled_time[0].name
            ),
            tenure: parseInt(data.contract.tenure),
            start_date: data.contract.start_date,
          };
          break;

        case "semi_monthly_dates":
          calenderData = {
            amount: parseInt(
              data.contract.contract_amount.replace(/[$,]/g, "")
            ),
            frequency: data.contract.payment_plan,
            dates: data.contract.scheduled_time,
            tenure: parseInt(data.contract.tenure),
            start_date: data.contract.start_date,
          };
          break;

        case "semi_monthly_days":
          calenderData = {
            amount: parseInt(
              data.contract.contract_amount.replace(/[$,]/g, "")
            ),
            frequency: data.contract.payment_plan,
            weeks: data.contract.scheduled_time[0].weeks,
            day: getDayFromDayName(data.contract.scheduled_time[0].day),
            tenure: parseInt(data.contract.tenure),
            start_date: data.contract.start_date,
          };
          break;

        case "monthly":
          calenderData = {
            amount: parseInt(
              data.contract.contract_amount.replace(/[$,]/g, "")
            ),
            frequency: data.contract.payment_plan,
            date_of_month: data.contract.scheduled_time[0],
            tenure: parseInt(data.contract.tenure),
            start_date: data.contract.start_date,
          };
          break;

        default:
          break;
      }

      const res = await axios.post(
        `${domainUrl}generate-scheduler`,
        calenderData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        let selectedPayments = [];
        console.log(res.data.payments, "incomming dates--");
        res.data.payments.map((value, index) => {
          let temp = {
            id: "",
            title: "",
            start: "",
            end: "",
            type: "",
            description: "",
          };
          temp.id = index;
          temp.title = value.amount;
          // let dateObject = new Date(`${value.date}T00:00:00`);
          // temp.start = dateObject.toLocaleDateString();
          // temp.end = dateObject.toLocaleDateString();
          let dateObject = new Date(`${value.date}T00:00:00`);
          temp.start = dateObject.toLocaleDateString("en-CA");
          temp.end = dateObject.toLocaleDateString("en-CA");
          temp.type = "upcoming_payment";
          selectedPayments.push(temp);
        });

        console.log("Selected Payment", selectedPayments);
        console.log([...selectedPayments, ...holidayEvent]);
        setEvents([...selectedPayments, ...holidayEvent]);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("client_data");
    };
  }, []);

  useEffect(() => {
    if (
      data.contract?.payment_plan &&
      data.contract?.tenure &&
      data.contract?.scheduled_time &&
      data.contract?.start_date
    ) {
      getClientScheduleDetailsHandler();
    }
  }, [
    data.contract?.payment_plan,
    data.contract?.tenure,
    data.contract?.scheduled_time,
    data.contract?.start_date,
  ]);

  useEffect(() => {
    assignHoliday();
  }, []);

  const getCaseTypeData = async () => {
    try {
      const res = await axios.get(`${domainUrl}casetypes`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        // console.log(res.data);
        setCaseTypeOptions(res.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };
  // const getFirmDetails = async () => {
  //   const res = await dispatch(getFirmProfileGet(navigate));
  //   if (res.payload) {
  //     console.log("firm data:", res.payload);
  //     setFirmData({
  //       ...firmData,
  //       firm_id: res.payload.id,
  //       firm_name: res.payload.firm_name,
  //       firm_code: res.payload.firm_code,
  //     });
  //   }
  //   if (res.error) {
  //     console.log(res.error);
  //   }
  // };

  const getClientDetailsHandler = async () => {
    try {
      // setLoading(true);
      const res = await axios.get(
        `${domainUrl}client-approval?id=${view_client_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        let clientData = {
          id: res.data.id,
          first_name: res.data.user.first_name,
          last_name: res.data.user.last_name,
          cell_phone_number: res.data.user.cell_phone_number,
          email: res.data.user.email,
          client_code: res.data.user.client_code,
          status: res.data.status,
          is_paused: res.data.is_paused,
          is_archived: res.data.is_archived,
          delinquency: res.data.delinquency,
          authorize_customer_id: res.data.authorize_customer_id,
          preferred_payment_method: res.data.preferred_payment_method,
          preferred_payment_autopay: res.data.preferred_payment_autopay,
        };
        localStorage.setItem("client_data", JSON.stringify(clientData));
        console.log(res.data);
        let scheduled_time =
          res.data.contracts[0]?.payment_plan == "monthly" ||
          res.data.contracts[0]?.payment_plan == "semi_monthly_dates"
            ? res.data.contracts[0].scheduled_time
            : Array(res.data.contracts[0].scheduled_time[0]);
        console.log("**********", res.data.contracts[0].scheduled_time);
        console.log("**##**##", scheduled_time);

        // setData(res.data);
        console.log(res.data.contracts[0].id);
        getPaymentDueDetails(res.data.authorize_customer_id);
        setAuthorizeCustomerId(res.data.authorize_customer_id);
        setFirmData({
          ...firmData,
          firm_id: res.data.contracts[0]?.attorney?.firm.id,
          firm_name: res.data.contracts[0]?.attorney?.firm.firm_name,
          firm_code: res.data.contracts[0]?.attorney?.firm.firm_code,
        });
        localStorage.setItem(
          "firm_id",
          res.data.contracts[0]?.attorney?.firm.id
        );
        setIncomeDocReceived(res.data.files);
        setAdditionalDocReceived(res.data.other_files);
        setContractDocReceived(res.data.uploaded_contracts);
        setData((prevValue) => ({
          ...prevValue,
          client: {
            ...prevValue["client"],
            client_code: res.data?.client_code,
            first_name: res.data?.user?.first_name,
            last_name: res.data?.user?.last_name,
            email: res.data?.user?.email,
            cell_phone_number: res.data?.user?.cell_phone_number,
            address_line1: res.data?.address_line1,
            address_line2: res.data?.address_line2,
            zip_code: res.data?.zip_code,
            state: getStateByCode(res.data?.state).code,
            city: res.data?.city,
            attorney: res.data.contracts[0]?.attorney.attorney_id,
          },
          contract: {
            ...prevValue["contract"],
            case_number: res.data.contracts[0]?.case_number,
            case_type: res.data.contracts[0]?.case_type,
            client_monthly_income: res.data.contracts[0]?.client_monthly_income
              ? formatUSD(res.data.contracts[0]?.client_monthly_income)
              : "",
            contract_amount: res.data.contracts[0]?.contract_amount
              ? formatUSD(res.data.contracts[0]?.contract_amount)
              : "",
            tenure: parseInt(res.data.contracts[0]?.tenure),
            down_payment: res.data.contracts[0]?.down_payment
              ? formatUSD(res.data.contracts[0]?.down_payment)
              : "",
            payment_plan: res.data.contracts[0]?.payment_plan,
            start_date: res.data.contracts[0]?.start_date,
            scheduled_time: scheduled_time,
          },
          co_debtor: res.data.contracts[0].co_debtor_data?.name ? true : false,
          co_debtor_data: {
            name: res.data.contracts[0].co_debtor_data?.name,
            email: res.data.contracts[0].co_debtor_data?.email,
            cell_phone_number:
              res.data.contracts[0].co_debtor_data?.cell_phone_number,
          },
          // files: {
          //   ...prevValue["files"],
          //   income: res.data.files,
          //   others: res.data.other_files,
          // }
        }));
        setContractId(res.data.contracts[0].id);
        setSelectedState(getStateByCode(res.data?.state));
        const initialAttorney = res.data.contracts[0]?.attorney;
        setSelectedAttorney({
          id: initialAttorney.attorney_id,
          name: `${initialAttorney.user.first_name}${initialAttorney.user.last_name}  --  ${initialAttorney.attorney_code}`,
          attorney_code: initialAttorney.attorney_code,
        });
        setSelectedCaseType({
          id: 1,
          case_type: res.data.contracts[0]?.case_type,
          created_by: 1,
        });
        for (const key in paymentPlanString) {
          console.log(
            key,
            paymentPlanString[key],
            res.data.contracts[0]?.payment_plan
          );
          if (key == res.data.contracts[0]?.payment_plan) {
            console.log(paymentPlanString[key], key);
            setSelectedPaymentPlan({ id: 1, name: key });
          }
        }
        if (
          res.data.contracts[0]?.payment_plan == "weekly" ||
          res.data.contracts[0]?.payment_plan == "bi_weekly"
        ) {
          setDisplayWeekDay(res.data.contracts[0]?.scheduled_time[0]);
          console.log(
            res.data.contracts[0]?.scheduled_time[0],
            res.data.contracts[0]?.scheduled_time.splice(0, 1)
          );
          console.log(
            "-------------",
            res.data.contracts[0]?.scheduled_time.splice(0, 1)
          );
          generatePickableDates(
            scheduled_time,
            res.data.contracts[0].payment_plan
          );
        } else if (
          res.data.contracts[0]?.payment_plan == "monthly" ||
          res.data.contracts[0]?.payment_plan == "semi_monthly_dates"
        ) {
          console.log(res.data.contracts[0]?.scheduled_time);
          setDisplayDate(res.data.contracts[0]?.scheduled_time);
          // generatePickableDates(scheduled_time);
          generatePickableDates(
            scheduled_time,
            res.data.contracts[0].payment_plan
          );
        } else if (res.data.contracts[0]?.payment_plan == "semi_monthly_days") {
          const foundWeeksArray = res.data.contracts[0]?.scheduled_time[0].weeks
            .map((weekId) => week.find((item) => item.id === weekId))
            .filter((foundWeek) => foundWeek !== undefined);
          setDisplayWeeks(foundWeeksArray);
          const weeklyDayFieldItem = weeklyDayField.find(
            (day) => day.name === res.data.contracts[0]?.scheduled_time[0].day
          );
          setDisplayWeekDay(weeklyDayFieldItem);
          // generatePickableDates(scheduled_time);
          generatePickableDates(
            scheduled_time,
            res.data.contracts[0].payment_plan
          );
        }
        // setLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (view_client_id) {
      getClientDetailsHandler();
    } else {
      navigate(-1);
    }
    return () => {
      if (role == "admin") {
        localStorage.removeItem("firm_id");
      }
    };
  }, []);

  const searchAttorneyList = async () => {
    try {
      const res = await axios.get(
        `${domainUrl}attorneys?id=${firm_id}&status=accepted&offset=0&limit=100&search=&sorting=[{"id":"user_model__first_name","desc":false}]`,
        // `${domainUrl}attorneys?id=${firm_id}&offset=0&limit=100&search=&sorting=[{"id":"user_model__first_name","desc":false}]`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (res.data) {
        let list = res.data.data.map((element) => ({
          id: element.attorney_id,
          name: `${element.user.first_name}${element.user.last_name}  --  ${element.attorney_code}`,
          attorney_code: element.attorney_code,
        }));

        setAttorneysList(list);
      }
    } catch (error) {
      console.error("Error searching for attorneys:", error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  useEffect(() => {
    // getFirmDetails();
    getCaseTypeData();
    // if (role == "primaryattorney" || role == "admin") {
    //   searchAttorneyList();
    // }
  }, []);

  useEffect(() => {
    if (role == "primaryattorney" || (role == "admin" && firm_id)) {
      searchAttorneyList();
    }
  }, [firm_id]);

  const [accountsDetail, setAccountsDetail] = useState([]);

  const getPaymentDueDetails = async (authorize_customer_id) => {
    try {
      const res = await axios.get(
        `${domainUrl}payment-info?customer_authorize_id=${authorize_customer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        const creditCardData = res.data.credit_card;
        const bankAccountData = res.data.bank_account;

        // Map credit card data
        const creditCardDetails = creditCardData.map((card, index) => ({
          id: index,
          name: card.card_type,
          description: `${card.card_number}`,
          type: "card",
          customer_payment_method_id: card.payment_method_id,
          customer_authorize_id: card.customer_authorize_id,
        }));

        // Map bank account data
        const bankAccountDetails = bankAccountData.map((account, index) => ({
          id: index + creditCardData.length, // Start index from creditCardData.length to avoid id conflicts
          name: account.account_type,
          description: `${account.routing_number}`,
          type: "ach",
          customer_payment_method_id: account.payment_method_id,
          customer_authorize_id: account.customer_authorize_id,
        }));

        // Combine both credit card and bank account details
        const combinedDetails = [...creditCardDetails, ...bankAccountDetails];

        // Set the state with combined details
        setAccountsDetail(combinedDetails);

        console.log(combinedDetails);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      // alert("Got gateway error");
    }
  };

  const clientCodeLoader = useSelector(
    (state) => state.postFNLClientNameStorage.isLoading
  );
  const filteredstates =
    query === ""
      ? listedStates
      : listedStates.filter((listedStates) =>
          listedStates.code
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleClose = () => {
    setOpenClientExistModal({
      ...openClientExistModal,
      open: false,
      action: false,
      note: "",
    });
  };
  const [pickableDates, setPickableDates] = useState([]);

  const tileContent = ({ date }) => {
    // console.log(date.toLocaleDateString('en-CA').replaceAll("-","/"))
    if (pickableDates.includes(date.toLocaleDateString("en-CA"))) {
      return <div className="bg-violet-800 h-1 rounded-3xl "></div>;
    }
    return null;
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = "";

    if (event.type === "upcoming_payment") {
      backgroundColor = "#1e90ff"; // Set the color for payment events
    } else if (event.type === "holiday") {
      backgroundColor = "#a83232"; // Set the color for holiday events
    } else if (event.type === "payment_missed") {
      backgroundColor = "#fcba03"; // Set the color for holiday events
    } else if (event.type === "note") {
      backgroundColor = "white"; // Set the color for holiday events
    }

    const style = {
      backgroundColor,
      color: "white",
    };

    return {
      style,
    };
  };

  function isWeekend(date) {
    const day = date.getDay();
    return day === 0 /* Sunday */ || day === 6 /* Saturday */;
  }

  const generatePickableDates = (value) => {
    let tempPickableDates = [];
    let allowedDuration = 2;
    var timezoneOffsetInMinutes = new Date().getTimezoneOffset();

    const today = new Date();
    // today.setDate(today.getDate());
    console.log("Today before", today);
    today.setHours(today.getHours() + today.getTimezoneOffset() / 60);
    // const today = new Date();
    console.log("Today After", today);
    const endDate = new Date();
    endDate.setHours(endDate.getHours() + endDate.getTimezoneOffset() / 60);
    // endDate.setMonth(today.getMonth() + allowedDuration);
    endDate.setDate(endDate.getDate() + 31);

    const formattedEndDate = endDate
      .toLocaleDateString("en-CA")
      .replace(/-/g, "/");

    function getSpecificDayOfMonth(year, month, dayOfWeek) {
      console.log(year, month, dayOfWeek);
      const result = [];
      const currentDate = new Date(year, month - 1, 1); // Month is 0-indexed in JavaScript

      while (currentDate.getMonth() === month - 1) {
        if (currentDate.getDay() === dayOfWeek) {
          const formattedDate = currentDate.toLocaleDateString("en-CA"); // Format as "yyyy-mm-dd"
          result.push(formattedDate);
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return result;
    }
    function getWeekIndex(date) {
      const dayIndex = date.getDay();
      const dayOfMonth = date.getDate();
      return Math.ceil(dayOfMonth / 7);
    }
    if (data.contract.payment_plan == "monthly") {
      for (let i = 0; i <= allowedDuration; i++) {
        const date = new Date();
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);

        date.setDate(1);
        date.setMonth(today.getMonth() + i);
        date.setDate(value[0]);
        if (today < date) {
          tempPickableDates.push(date.toLocaleDateString("en-CA"));
        }
      }
    } else if (data.contract.payment_plan == "semi_monthly_dates") {
      console.log(today, "$$$$");
      for (let i = 0; i <= allowedDuration; i++) {
        const date = new Date();
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        console.log("Date is:", date);
        date.setDate(1);
        date.setMonth(today.getMonth() + i);
        for (let j of value) {
          date.setDate(j);
          console.log("Picked", date);
          console.log("Adjusted", date);
          console.log(today < date, "Value");
          console.log(today);
          if (today < date) {
            tempPickableDates.push(date.toLocaleDateString("en-CA"));
          }
        }
      }
    } else if (data.contract.payment_plan == "semi_monthly_days") {
      const convert_day = {
        MONDAY: 1,
        TUESDAY: 2,
        WEDNESDAY: 3,
        THURSDAY: 4,
        FRIDAY: 5,
        SATURDAY: 6,
        SUNDAY: 0,
      };

      if (value[0]?.weeks) {
        for (let i = 0; i <= allowedDuration; i++) {
          const date = new Date();
          date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
          date.setDate(1);
          date.setMonth(today.getMonth() + i);
          let weeks = getSpecificDayOfMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            convert_day[value[0].day]
          );
          console.log(weeks);
          for (let day of weeks) {
            console.log(day, new Date(day), date);
            if (
              new Date(day) > today &&
              value[0].weeks.includes(getWeekIndex(new Date(day)))
            ) {
              tempPickableDates.push(day);
            }
          }
        }
      }
    } else if (
      data.contract.payment_plan == "weekly" ||
      data.contract.payment_plan == "bi_weekly"
    ) {
      const convert_day = { 0: 1, 1: 2, 2: 3, 3: 4, 4: 5, 5: 6, 6: 0 };
      for (let i = 0; i <= allowedDuration; i++) {
        const date = new Date();
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
        date.setDate(1);
        date.setMonth(today.getMonth() + i);
        let weeks = getSpecificDayOfMonth(
          date.getFullYear(),
          date.getMonth() + 1,
          convert_day[value[0].id]
        );
        console.log(weeks);
        for (let day of weeks) {
          console.log(day, new Date(day), date);
          if (new Date(day) > today) {
            tempPickableDates.push(day);
          }
        }
      }
      console.log(tempPickableDates);
    }
    // else if (data.contract.payment_plan == "bi_weekly") {
    //   const convert_day = { 0: 1, 1: 2, 2: 3, 3: 4, 4: 5, 5: 6, 6: 0 };
    //   for (let i = 0; i <= allowedDuration; i++) {
    //     const date = new Date();
    //     date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
    //     date.setDate(1);
    //     date.setMonth(today.getMonth() + i);
    //     let weeks = getSpecificDayOfMonth(
    //       date.getFullYear(),
    //       date.getMonth() + 1,
    //       convert_day[value[0].id]
    //     );
    //     console.log(weeks);
    //     for (let day of weeks) {
    //       console.log(day, new Date(day), date);
    //       if (new Date(day) > today) {
    //         tempPickableDates.push(day);
    //       }
    //     }
    //   }
    //   tempPickableDates = tempPickableDates.filter(
    //     (value, index) => index % 2 == 0
    //   );
    // }

    tempPickableDates = tempPickableDates.map((dateString) => {
      const date = new Date(dateString);
      date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
      while (isWeekend(date)) {
        // Move to the next Monday
        date.setDate(date.getDate() + 1); // 8 - current day gives the days until Monday
      }
      return date.toLocaleDateString("en-CA");
    });
    let newtempPickableDates = [];
    for (let dateString of tempPickableDates) {
      const date = new Date(dateString);
      date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
      if (date <= endDate) {
        newtempPickableDates.push(dateString);
      }
    }
    setPickableDates(newtempPickableDates);
  };

  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email.length > 0 && regex.test(email);
  };

  const filterPositiveDecimal = (value) => {
    var numericString = value.replace(/[^0-9]/g, "");
    return numericString;
  };

  const formatDate = (dateString) => {
    // const originalDate = new Date(dateString);
    // const day = originalDate.getDate().toString().padStart(2, "0");
    // const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    // const year = originalDate.getFullYear();

    // return `${month}/${day}/${year}`;

    console.log(dateString);
    const date = dateString.split("-");
    console.log(date);
    return `${date[1]}/${date[2]}/${date[0]}`;
  };

  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  const formatAlphabetsAndNumbers = (value) => {
    let formattedAlphabetsAndNumbers;
    const { length } = value;

    // Filter non-alphabets and non-numbers, and convert to uppercase
    const regex = () => value.replace(/[^a-zA-Z0-9]+/g, "").toUpperCase();

    // New validation
    if (length > 0) {
      formattedAlphabetsAndNumbers = regex();
    }

    return formattedAlphabetsAndNumbers;
  };
  function formatAlphabetsAndSpaces(input) {
    // Use a regular expression to match only alphabets and spaces
    const regex = /[^a-zA-Z ]/g;
    // Replace any characters that don't match the regex with an empty string
    const formattedString = input.replace(regex, "");

    return formattedString;
  }

  const formatcell_phone_number = (value) => {
    let convertedNumber = value.replace(/\D/g, "");
    convertedNumber = convertedNumber.replace(/^1/, "");
    convertedNumber = convertedNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1 $2 $3"
    );
    console.log(convertedNumber.length);
    return convertedNumber;
  };

  const formatCardNumber = (value = "") => {
    // Remove any non-numeric characters
    const numericValue = value.replace(/[^0-9]+/g, "");

    // Ensure the total length does not exceed 18 characters
    if (numericValue.length > 20) {
      return numericValue.slice(0, 20);
    }

    // Format the number with spaces every 4 digits
    const formattedNumber = numericValue.replace(/(\d{4})/g, "$1 ");

    // Trim any leading or trailing spaces
    return formattedNumber.trim();
    // handleChange(formattedObject);
  };
  const filterNumberLength = (value, max) => {
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length > max) {
      return numericValue.slice(0, max);
    }
    return numericValue;
  };

  const checkClientExist = async (num) => {
    try {
      const res = await axios.get(
        `${domainUrl}client-search?phone=${num}&exclude_client=${client_data.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        // // console.log(res.data);
        setClientExistData(res.data);
        localStorage.setItem("view_client_id", res.data.id);
        setOpenClientExistModal({
          ...openClientExistModal,
          open: true,
          action: true,
          note: "",
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      if (error.response.status == 400) {
        setOpenClientExistModal({
          ...openClientExistModal,
          open: true,
          action: false,
          note: error.response.data.detail,
        });
      }
    }
  };
  const deleteReceivedFile = async (path, type, index) => {
    // API CALLING
    try {
      const res = await axios.delete(`${domainUrl}delete-file?path=${path}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        const updatedFiles =
          type == "income"
            ? [...incomeDocReceived]
            : type == "contract"
            ? [...contractDocReceived]
            : [...additionalDocReceived];

        updatedFiles.splice(index, 1);
        type == "income"
          ? setIncomeDocReceived(updatedFiles)
          : type == "contract"
          ? setContractDocReceived(updatedFiles)
          : setAdditionalDocReceived(updatedFiles);
        toast.success("File removed successfully", { id: 1 });
      }
    } catch (error) {
      console.log(error.response.data);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      toast.error(error.response.data.detail, { id: 1 });
    }
  };

  const deleteRecievedPaymentMethod = async (
    customer_payment_method_id,
    type
  ) => {
    try {
      const res = await axios.delete(
        `${domainUrl}payment-info?customer_authorize_id=${authorizeCustomerId}&customer_payment_method_id=${customer_payment_method_id}&type=${type}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        getPaymentDueDetails(authorizeCustomerId);
        toast.success("Successfully deleted");
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      toast.error(error.response.data.detail);
    }
  };

  const formHandler = async (e, form_type, index = -1) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setErrorFlag((prevErrorFlag) => ({
      ...prevErrorFlag,
      [form_type]: {
        ...prevErrorFlag[form_type],
        [e.target.name]: "",
      },
    }));
    if (
      e.target.name == "cardExpiryMonth" ||
      e.target.name == "cardExpiryYear"
    ) {
      if (e.target.name == "cardExpiryMonth") {
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiryMonth: "",
        });
      } else {
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiryYear: "",
        });
      }
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [form_type]: {
          ...prevErrorFlag[form_type],
          ["expiration_date"]: "",
        },
      }));
    }

    if (form_type == "client") {
      if (
        [
          "first_name",
          "last_name",
          "email",
          "cell_phone_number",
          "address_line1",
          "address_line2",
          "city",
          "zip_code",
        ].includes(e.target.name)
      ) {
        setData((prevValue) => ({
          ...prevValue,
          client: {
            ...prevValue[form_type],
            [e.target.name]: ["first_name", "last_name", "city"].includes(
              e.target.name
            )
              ? formatAlphabetsAndSpaces(e.target.value)
              : e.target.name == "cell_phone_number"
              ? e.target.value
              : e.target.name == "zip_code"
              ? filterNumberLength(e.target.value, length_validate.zip_code)
              : e.target.value,
          },
        }));
      } else if (e.target.name == "attorney") {
      }
    } else if (form_type == "contract") {
      console.log(e.target, e);
      if (e.target.name == "case_number") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value,
          },
        }));
      } else if (e.target.name == "payment_plan") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value,
          },
        }));
      } else if (e.target.name == "contract_amount") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value,
            // [e.target.name]: filterNumberLength(
            //   e.target.value,
            //   length_validate.contract_amount
            // ),
          },
        }));
      } else if (e.target.name == "tenure") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: filterNumberLength(
              e.target.value,
              length_validate.tenure
            ),
          },
        }));
      } else if (e.target.name == "client_monthly_income") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value,
            // [e.target.name]: filterNumberLength(
            //   e.target.value,
            //   length_validate.client_monthly_income
            // ),
          },
        }));
      } else if (e.target.name == "down_payment") {
        setData((prevValue) => ({
          ...prevValue,
          contract: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value,
            // [e.target.name]: filterNumberLength(
            //   e.target.value,
            //   length_validate.down_payment
            // ),
          },
        }));
      }
    } else if (form_type == "co_debtor") {
      if (!e.target.checked) {
        setData((prevValue) => ({
          ...prevValue,
          co_debtor_data: {
            name: "",
            email: "",
            cell_phone_number: "",
          },
        }));
      }
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: e.target.checked,
      }));
    } else if (form_type == "co_debtor_data") {
      setData((prevValue) => ({
        ...prevValue,
        co_debtor_data: {
          ...prevValue[form_type],
          [e.target.name]:
            e.target.name == "cell_phone_number"
              ? e.target.value
              : e.target.name == "name"
              ? formatAlphabetsAndSpaces(e.target.value)
              : e.target.value,
        },
      }));
    } else if (form_type == "files") {
      console.log(e.target.name);
      const files = e.target.files;
      let newFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (allowedFileTypes.includes(file.type)) {
          // // console.log("fine type");
          let maxsize = 5 * 1024 * 1024;
          if (file.size < maxsize) {
            newFiles = [...newFiles, file];
            // toast.success(`File ${file.name} Uploaded successfully`)
          } else {
            toast.error("Maximum allowed size is 5 MB.");
          }
        } else {
          // // console.log("not allowed file", file.type);
          toast.error(
            "Invalid File Type! Please upload a file of type: [Supported File Types, e.g., .jpg, .png, .jpeg, .pdf]."
          );
        }
      }

      if (e.target.name == "income") {
        const incomePromises = [...newFiles, ...incomeDoc].map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
              const base64Data = event.target.result.split(",")[1];
              resolve({ name: file.name, file: base64Data });
            };

            reader.onerror = (error) => {
              reject(error);
            };

            reader.readAsDataURL(file);
          });
        });
        const base64FilesIncome = await Promise.all(incomePromises);
        console.log(base64FilesIncome);
        setData((prevValue) => ({
          ...prevValue,
          files: {
            ...prevValue[form_type],
            [e.target.name]: base64FilesIncome,
          },
        }));

        setIncomeDoc((prevFiles) => [...prevFiles, ...newFiles]);
      } else if (e.target.name == "contract") {
        const contractPromises = [...newFiles, ...contractDoc].map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
              const base64Data = event.target.result.split(",")[1];
              resolve({ name: file.name, file: base64Data });
            };

            reader.onerror = (error) => {
              reject(error);
            };

            reader.readAsDataURL(file);
          });
        });
        const base64FilesContract = await Promise.all(contractPromises);
        console.log(base64FilesContract);
        setData((prevValue) => ({
          ...prevValue,
          files: {
            ...prevValue[form_type],
            [e.target.name]: base64FilesContract,
          },
        }));
        setContractDoc((prevFiles) => [...prevFiles, ...newFiles]);
      } else {
        const additionalPromises = [...newFiles, ...additionalDoc].map(
          (file) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();

              reader.onload = (event) => {
                const base64Data = event.target.result.split(",")[1];
                resolve({ name: file.name, file: base64Data });
              };

              reader.onerror = (error) => {
                reject(error);
              };

              reader.readAsDataURL(file);
            });
          }
        );
        const base64FilesAdditional = await Promise.all(additionalPromises);
        console.log(base64FilesAdditional);
        setData((prevValue) => ({
          ...prevValue,
          files: {
            ...prevValue[form_type],
            [e.target.name]: base64FilesAdditional,
          },
        }));
        setAdditionalDoc((prevFiles) => [...prevFiles, ...newFiles]);
      }
    } else if (form_type == "income_del") {
      console.log(index);
      const updatedFiles = [...incomeDoc];
      updatedFiles.splice(index, 1);
      setIncomeDoc(updatedFiles);
      const incomePromises = updatedFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (event) => {
            const base64Data = event.target.result.split(",")[1];
            resolve({ name: file.name, file: base64Data });
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      });
      const base64FilesIncome = await Promise.all(incomePromises);
      console.log(base64FilesIncome);
      setData((prevValue) => ({
        ...prevValue,
        files: {
          ...prevValue["files"],
          ["income"]: base64FilesIncome,
        },
      }));
    } else if (form_type == "contract_del") {
      const updatedFiles = [...contractDoc];
      updatedFiles.splice(index, 1);
      setContractDoc(updatedFiles);
      const contractPromises = updatedFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (event) => {
            const base64Data = event.target.result.split(",")[1];
            resolve({ name: file.name, file: base64Data });
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      });
      const base64FilesContract = await Promise.all(contractPromises);
      console.log(base64FilesContract);
      setData((prevValue) => ({
        ...prevValue,
        files: {
          ...prevValue["files"],

          ["contract"]: base64FilesContract,
        },
      }));
    } else if (form_type == "others_del") {
      const updatedFiles = [...additionalDoc];
      updatedFiles.splice(index, 1);
      setAdditionalDoc(updatedFiles);
      const additionalPromises = updatedFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (event) => {
            const base64Data = event.target.result.split(",")[1];
            resolve({ name: file.name, file: base64Data });
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      });
      const base64FilesAdditional = await Promise.all(additionalPromises);
      console.log(base64FilesAdditional);
      setData((prevValue) => ({
        ...prevValue,
        files: {
          ...prevValue["files"],

          ["others"]: base64FilesAdditional,
        },
      }));
    } else if (form_type == "payment_card") {
      if (e.target.name == "cardholder") {
        setData((prevValue) => ({
          ...prevValue,
          payment_card: {
            ...prevValue[form_type],
            [e.target.name]: formatAlphabetsAndSpaces(e.target.value),
          },
        }));
      } else if (e.target.name == "card_number") {
        setData((prevValue) => ({
          ...prevValue,
          payment_card: {
            ...prevValue[form_type],
            [e.target.name]: e.target.value
          },
        }));
      } else if (e.target.name == "cardExpiryMonth") {
        setCardExpiryAlert(false);
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        const enteredMonth = parseInt(
          e.target.name === "cardExpiryMonth"
            ? e.target.value
            : cardDetails.cardExpiryMonth,
          10
        );
        const enteredYear = parseInt(
          e.target.name === "cardExpiryYear"
            ? e.target.value
            : cardDetails.cardExpiryYear,
          10
        );
        const monthsRemaining =
          (enteredYear - currentYear) * 12 + enteredMonth - currentMonth;
        console.log(
          "MONTH^^^",
          currentYear,
          currentMonth,
          enteredYear,
          enteredMonth
        );

        if (
          e.target.value.length == 2 &&
          cardDetails.cardExpiryYear.length == 2 &&
          monthsRemaining <= 10 &&
          currentYear <= enteredYear
        ) {
          if (enteredYear == currentYear && currentMonth < enteredMonth) {
            setCardExpiryAlert(true);
          } else if (currentYear < enteredYear) {
            setCardExpiryAlert(true);
          }
        }
        const monthValue = parseInt(e.target.value, 10);
        const yearValue = parseInt(cardDetails.cardExpiryYear, 10);
        if (
          monthValue == 0 ||
          monthValue > 12 ||
          (currentYear == cardDetails.cardExpiryYear &&
            monthValue < currentMonth)
        ) {
          // toast.error("Invalid expiry Month",{id:1});
          // return;
          setCardDetailsError({
            ...cardDetailsError,
            cardExpiryMonth: "*invalid month",
          });
        }
        if (
          String(yearValue).length == 2 &&
          yearValue <= currentYear % 100 &&
          parseInt(e.target.value, 10) <= currentMonth
        ) {
          setErrorFlag((prevValue) => ({
            ...prevValue,
            payment_card: {
              ...prevValue.payment_card,
              expiration_date: "*enter a valid expiry date",
            },
          }));
        }
        setCardDetails({
          ...cardDetails,
          cardExpiryMonth: e.target.value,
        });

        setData((prevValue) => ({
          ...prevValue,
          payment_card: {
            ...prevValue.payment_card,
            expiration_date: `${e.target.value}/${cardDetails.cardExpiryYear}`,
          },
        }));
      } else if (e.target.name == "cardExpiryYear") {
        setCardExpiryAlert(false);
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        const enteredMonth = parseInt(
          e.target.name === "cardExpiryMonth"
            ? e.target.value
            : cardDetails.cardExpiryMonth,
          10
        );
        const enteredYear = parseInt(
          e.target.name === "cardExpiryYear"
            ? e.target.value
            : cardDetails.cardExpiryYear,
          10
        );

        console.log(
          "YEAR^^^",
          currentYear,
          currentMonth,
          enteredYear,
          enteredMonth
        );

        const monthsRemaining =
          (enteredYear - currentYear) * 12 + enteredMonth - currentMonth;
        if (
          e.target.value.length == 2 &&
          cardDetails.cardExpiryMonth.length == 2 &&
          monthsRemaining <= 10 &&
          currentYear <= enteredYear
        ) {
          if (enteredYear == currentYear && currentMonth < enteredMonth) {
            setCardExpiryAlert(true);
          } else if (currentYear < enteredYear) {
            setCardExpiryAlert(true);
          }
        }
        const yearValue = parseInt(e.target.value, 10);
        if (
          e.target.value.length == 2 &&
          parseInt(e.target.value) <
            parseInt(new Date().getFullYear().toString().slice(-2))
        ) {
          // toast.error("Invalid expiry Year",{id:1});
          // return
          setCardDetailsError({
            ...cardDetailsError,
            cardExpiryYear: "*invalid year",
          });
        }
        if (
          String(yearValue).length == 2 &&
          yearValue <= currentYear % 100 &&
          parseInt(cardDetails.cardExpiryMonth, 10) <= currentMonth
        ) {
          setErrorFlag((prevValue) => ({
            ...prevValue,
            payment_card: {
              ...prevValue.payment_card,
              expiration_date: "*enter a valid expiry date",
            },
          }));
        }

        setCardDetails({
          ...cardDetails,
          cardExpiryYear: e.target.value,
        });
        setData((prevValue) => ({
          ...prevValue,
          payment_card: {
            ...prevValue.payment_card,
            expiration_date: `${cardDetails.cardExpiryMonth}/${e.target.value}`,
          },
        }));
        console.log(
          parseInt(e.target.value) <
            parseInt(new Date().getFullYear().toString().slice(-2))
        );
      }
    } else if (form_type == "direct_deposit") {
      if (e.target.name == "bank_account_number") {
        setData((prevValue) => ({
          ...prevValue,
          direct_deposit: {
            ...prevValue[form_type],
            [e.target.name]: filterNumberLength(
              e.target.value,
              length_validate[e.target.name].max
            ),
          },
        }));
      } else if (e.target.name == "bank_routing_number") {
        setData((prevValue) => ({
          ...prevValue,
          direct_deposit: {
            ...prevValue[form_type],
            [e.target.name]: filterNumberLength(
              e.target.value,
              length_validate[e.target.name]
            ),
          },
        }));
      }
    }
    setTypingTimeout(
      setTimeout(async () => validateFormFields(e, form_type, index), 300)
    );
  };

  const validateFormFields = async (e, form_type, index = -1) => {
    // // console.log("validating firm");
    if (form_type == "client") {
      if (["first_name", "last_name"].includes(e.target.name)) {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [e.target.name]:
            e.target.value.length > 30 ? errMsg[e.target.name] : "",
        }));
        if (e.target.name == "first_name") {
          if (e.target.value && data.client.last_name) {
            if (e.target.value.length > 0 && e.target.value.length <= 30) {
              const res = await dispatch(
                postFNLClientNamePost(
                  {
                    first_name: e.target.value,
                    last_name: data.client.last_name,
                  },
                  navigate
                )
              );
              if (res.payload) {
                toast.success("Client Code Generated", { id: 1 });
                setData((prevValue) => ({
                  ...prevValue,
                  client: {
                    ...prevValue["client"],
                    client_code: res.payload.client_code,
                  },
                }));
              }
              if (res.error) {
                console.log("res.error postFNLnamePost", res.error);
              }
            }
          } else {
            setData((prevValue) => ({
              ...prevValue,
              client: {
                ...prevValue["client"],
                client_code: "",
              },
            }));
          }
        } else {
          if (e.target.value && data.client.first_name) {
            if (e.target.value.length > 0 && e.target.value.length <= 30) {
              const res = await dispatch(
                postFNLClientNamePost(
                  {
                    first_name: data.client.first_name,
                    last_name: e.target.value,
                  },
                  navigate
                )
              );
              if (res.payload) {
                toast.success("Client Code Generated", { id: 1 });
                setData((prevValue) => ({
                  ...prevValue,
                  client: {
                    ...prevValue["client"],
                    client_code: res.payload.client_code,
                  },
                }));
              }
              if (res.error) {
                console.log("res.error postFNLnamePost", res.error);
              }
            }
          } else {
            setData((prevValue) => ({
              ...prevValue,
              client: {
                ...prevValue["client"],
                client_code: "",
              },
            }));
          }
        }
      } else if (e.target.name == "email") {
        // console.log("validating firm email");

        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          client: {
            ...prevErrorFlag["client"],

            [e.target.name]: !validateEmail(e.target.value)
              ? errMsg[e.target.name]
              : "",
          },
        }));
      } else if (e.target.name == "zip_code") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          client: {
            ...prevErrorFlag["client"],
            [e.target.name]:
              e.target.value.length != 5 ? errMsg[e.target.name] : "",
          },
        }));
      } else if (e.target.name == "cell_phone_number") {
        if (formatcell_phone_number(e.target.value).length == 12) {
          checkClientExist(formatcell_phone_number(e.target.value));
        }
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          client: {
            ...prevErrorFlag["client"],
            [e.target.name]:
              formatcell_phone_number(e.target.value).length != 12
                ? errMsg[e.target.name]
                : "",
          },
        }));
      }
    } else if (form_type == "co_debtor_data" && data.co_debtor) {
      if (e.target.name == "email") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          co_debtor_data: {
            ...prevErrorFlag["co_debtor_data"],
            [e.target.name]: !validateEmail(e.target.value)
              ? errMsg[e.target.name]
              : "",
          },
        }));
      }

      if (e.target.name == "cell_phone_number") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          co_debtor_data: {
            ...prevErrorFlag["co_debtor_data"],
            [e.target.name]:
              formatcell_phone_number(e.target.value).length !== 12
                ? errMsg[e.target.name]
                : "",
          },
        }));
      }
    } else if (form_type == "contract") {
      //   if(e.target.name == "tenure"){
      //   setErrorFlag((prevErrorFlag) => ({
      //     ...prevErrorFlag,
      //     contract: {
      //       ...prevErrorFlag["contract"],
      //       [e.target.name]:
      //         parseInt(e.target.value) > 10 ? errMsg[e.target.name] : "",
      //     },
      //   }));
      // }
    } else if (form_type == "payment_card") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        payment_card: {
          ...prevErrorFlag["payment_card"],
          [e.target.name]:
            e.target.value.length != 19 ? errMsg[e.target.name] : "",
        },
      }));
    } else if (form_type == "direct_deposit") {
      if (e.target.name == "bank_account_number") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          direct_deposit: {
            ...prevErrorFlag["direct_deposit"],
            [e.target.name]:
              e.target.value.length > 17 || e.target.value.length < 12
                ? errMsg[e.target.name]
                : "",
          },
        }));
      } else if (e.target.name == "bank_routing_number") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          direct_deposit: {
            ...prevErrorFlag["direct_deposit"],
            [e.target.name]:
              e.target.value.length != 9 ? errMsg[e.target.name] : "",
          },
        }));
      }
    }
  };

  const isSubmitButtonDisabled = () => {
    // Check if all required fields are filled except address_lin1, city, zip_code, and secondary_cell_phone_number.
    const requiredFields = [
      // data.client.firm_id,
      ...(role === "primaryattorney" || role === "admin"
        ? [selectedAttorney?.id]
        : []),
      data.client.first_name,
      data.client.last_name,
      data.client.client_code,
      data.client.address_line1,
      // data.client.address_line2,
      data.client.cell_phone_number,
      data.client.email,
      data.client.state,
      data.client.city,
      data.client.zip_code,
      data.contract.case_type,
      data.contract.case_number,
      data.contract.contract_amount,
      data.contract.tenure,
      // data.contract.down_payment,
      data.contract.client_monthly_income,
      // data.contract.installment_amount,
      data.contract.payment_plan,
      data.contract.scheduled_time,
      data.contract.start_date,
      // data.files.income,
      // data.files.others,
      // data.files.contract,
      // data.payment_card.cardholder,
      // data.payment_card.card_number,
      // data.payment_card.expiration_date,
      // data.direct_deposit.bank_name,
      // data.direct_deposit.bank_account_number,
      // data.direct_deposit.bank_routing_number,
    ];

    console.log(requiredFields);

    const coDebtorRequiredFields = data.co_debtor
      ? [
          data.co_debtor_data.name,
          data.co_debtor_data.email,
          data.co_debtor_data.cell_phone_number,
        ]
      : [];
    const allRequiredFields = [...requiredFields, ...coDebtorRequiredFields];
    let flag = false;
    for (const key in errorFlag.client) {
      if (key === "address_line2") {
        // Skip adding error message for address_line2
        continue;
      }
      // console.log(key, data.client[key]);
      if (data.client[key] == "") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          client: {
            ...prevErrorFlag["client"],
            [key]: `*${key
              .replace("cell_phone_number", "primary phone")
              .replace(/_/g, " ")
              .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
          },
        }));
      }
    }
    for (const key in errorFlag.contract) {
      if (key === "down_payment") {
        // Skip adding error message for down_payment
        continue;
      }
      // console.log(key, data.contract[key]);
      if (data.contract[key] == "") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          contract: {
            ...prevErrorFlag["contract"],
            [key]: `*${key
              .replace("tenure", "contract period")
              .replace("client_monthly_income", "monthly income")
              .replace(/_/g, " ")
              .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
          },
        }));
      }
    }
    if (data.contract.payment_plan == "semi_monthly_days") {
      if (!data.contract.scheduled_time[0]?.day) {
        setErrorFlagPayField((prevValue) => ({
          ...prevValue,
          day: "*Day is required",
        }));
      }
      if (
        !data.contract.scheduled_time[0]?.weeks ||
        data.contract.scheduled_time[0]?.weeks.length == 0
      ) {
        setErrorFlagPayField((prevValue) => ({
          ...prevValue,
          weeks: "*Weeks are required",
        }));
      }
    } else if (
      data.contract.payment_plan == "semi_monthly_dates" ||
      data.contract.payment_plan == "monthly"
    ) {
      if (data.contract.scheduled_time.length == 0) {
        setErrorFlagPayField((prevValue) => ({
          ...prevValue,
          date: "*Date is required",
        }));
      }
    } else if (
      data.contract.payment_plan == "weekly" ||
      data.contract.payment_plan == "bi_weekly"
    ) {
      if (data.contract.scheduled_time.length == 0) {
        setErrorFlagPayField((prevValue) => ({
          ...prevValue,
          day: "*Day is required",
        }));
      }
    }
    if (data.co_debtor) {
      for (const key in errorFlag.co_debtor_data) {
        if (data.co_debtor_data[key] == "") {
          setErrorFlag((prevErrorFlag) => ({
            ...prevErrorFlag,
            co_debtor_data: {
              ...prevErrorFlag.co_debtor_data,
              [key]: `*${key
                .replace("cell_phone_number", "phone number")
                .replace(/_/g, " ")
                .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
            },
          }));
        }
      }
    }
    for (const key in errorFlag.files) {
      if (key === "others") {
        // Skip adding error message for others
        continue;
      }
      console.log(key, data.files[key]);
      if (key == "income") {
        if (incomeDocReceived.length == 0 && incomeDoc.length == 0) {
          console.log(incomeDocReceived);
          flag = true;
          // if (data.files[key] == "") {
          setErrorFlag((prevErrorFlag) => ({
            ...prevErrorFlag,
            files: {
              ...prevErrorFlag["files"],
              [key]: `*${key
                .replace("income", "income document")
                .replace(/_/g, " ")
                .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
            },
          }));
          // }
        }
      }
      if (key == "contract") {
        if (contractDocReceived.length == 0 && contractDoc.length == 0) {
          console.log(contractDocReceived);
          flag = true;
          // if (data.files[key] == "") {
          setErrorFlag((prevErrorFlag) => ({
            ...prevErrorFlag,
            files: {
              ...prevErrorFlag["files"],
              [key]: `*${key
                .replace("contract", "fee agreement")
                .replace(/_/g, " ")
                .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
            },
          }));
          // }
        }
      }
    }

    let card_length = 0;
    let ach_length = 0;
    for (let i of accountsDetail) {
      if (i.type == "card") {
        card_length += 1;
      } else if (i.type == "ach") {
        ach_length += 1;
      }
    }

    for (const key in errorFlag.payment_card) {
      // console.log(key, data.payment_card[key]);
      if (card_length == 0) {
        if (data.payment_card[key] == "") {
          setErrorFlag((prevErrorFlag) => ({
            ...prevErrorFlag,
            payment_card: {
              ...prevErrorFlag["payment_card"],
              [key]: `*${key
                .replace(/_/g, " ")
                .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
            },
          }));
          flag = true;
        }
        if (
          cardDetailsError.cardExpiryMonth ||
          cardDetailsError.cardExpiryYear
        ) {
          // Handle the case where either cardExpiryMonth or cardExpiryYear has an error
          // return true;
          flag = true;
        }
      }
    }

    for (const key in errorFlag.direct_deposit) {
      // console.log(key, data.direct_deposit[key]);
      if (ach_length == 0) {
        if (data.direct_deposit[key] == "" && key != "bank_name") {
          setErrorFlag((prevErrorFlag) => ({
            ...prevErrorFlag,
            direct_deposit: {
              ...prevErrorFlag["direct_deposit"],
              [key]: `*${key
                .replace(/_/g, " ")
                .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
            },
          }));

          console.log(key, "triggering error");
          flag = true;
        }
      }
    }

    console.log("Required Fields", allRequiredFields);
    return (
      allRequiredFields.some((field) => field === "") ||
      flag ||
      errorFlagPayField.date !== "" ||
      errorFlagPayField.day !== "" ||
      errorFlagPayField.weeks !== "" ||
      errorFlag.payment_card.expiration_date !== ""
    );
  };
  const submitFormHandler = async () => {
    // setErrorFlag({
    //   client: {
    //     firm_id: "",
    //     attorney: "",
    //     first_name: "",
    //     last_name: "",
    //     client_code: "",
    //     address_line1: "",
    //     address_line2: "",
    //     cell_phone_number: "",
    //     email: "",
    //     state: "",
    //     city: "",
    //     zip_code: "",
    //   },
    //   contract: {
    //     case_type: "",
    //     case_number: "",
    //     contract_amount: "",
    //     tenure: "",
    //     down_payment: "",
    //     client_monthly_income: "",
    //     installment_amount: "",
    //     payment_plan: "",
    //     scheduled_time: "",
    //     start_date: "",
    //   },
    //   files: {
    //     income: "",
    //     others: "",
    //     contract: "",
    //   },
    //   co_debtor_data: {
    //     name: "",
    //     email: "",
    //     cell_phone_number: "",
    //   },
    //   payment_card: {
    //     cardholder: "",
    //     card_number: "",
    //     expiration_date: "",
    //   },
    //   direct_deposit: {
    //     bank_name: "",
    //     bank_routing_number: "",
    //     bank_account_number: "",
    //   },
    // });
    console.log(data, "Data");
    const isClientDetailError = isSubmitButtonDisabled();
    console.log(isClientDetailError);

    if (isClientDetailError) {
      toast.error("Complete the Fields", { id: 1 });
      return;
    }
    let submitData = data;
    submitData.client.cell_phone_number = submitData.client.cell_phone_number
      ? formatcell_phone_number(submitData.client.cell_phone_number)
      : submitData.client.cell_phone_number;
    submitData.co_debtor_data.cell_phone_number = submitData.co_debtor_data
      .cell_phone_number
      ? formatcell_phone_number(submitData.co_debtor_data.cell_phone_number)
      : submitData.co_debtor_data.cell_phone_number;
    submitData.contract.contract_amount =
      submitData.contract.contract_amount.replace(/[$,]/g, "");
    submitData.contract.client_monthly_income =
      submitData.contract.client_monthly_income.replace(/[$,]/g, "");
    submitData.contract.down_payment = submitData.contract.down_payment.replace(
      /[$,]/g,
      ""
    );
    submitData.direct_deposit.accountholder =
      submitData.payment_card.cardholder;
    submitData.contract.tenure = parseInt(submitData.contract.tenure);
    submitData.payment_card.card_number =
      submitData.payment_card.card_number.replace(/\s/g, "");
    setSubmitClientLoader(true);
    const res = await dispatch(
      updateClientPut({ data: submitData, id: contractId }, navigate)
    );
    if (res.payload) {
      navigate("/pending-clients");
      setSubmitClientLoader(false);
      toast.success("Client Updated Successfully");
    }
    if (res.error) {
      console.log(res.error, "----errors");
      setSubmitClientLoader(false);
      if (typeof JSON.parse(res.error.message) == "string") {
        console.log(typeof JSON.parse(res.error.message), "Parsed errors");
        toast.error(JSON.parse(res.error.message), { id: 1 });
      } else {
        setErrorFlag(JSON.parse(res.error.message));
        toast.error("Error Occured", { id: 1 });
      }
    }
  };

  return (
    <>
      <div className="sm:m-5  px-2 pt-8 pb-40">
        <div className="w-full flex justify-start items-center">
          {data.client?.client_code == "" && (
            <div className="text-lg font-semibold text-gray-900">
              Client Unique Code: ---
            </div>
          )}
          {data.client?.client_code !== "" && (
            <>
              <div className="flex gap-2 items-center">
                <div className="text-lg font-semibold text-gray-900">
                  Client Unique Code:
                </div>
                <div>
                  {!clientCodeLoader ? (
                    <>{data.client?.client_code}</>
                  ) : (
                    <span>
                      <TailSpin
                        height="20"
                        width="20"
                        color="black"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </span>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="border rounded-xl px-5 my-5">
          <form>
            <div
              className=""
              onClick={() => {
                console.clear();
                console.log(data);
                console.log("#", pickableDates);
              }}
            >
              <div className="w-full flex justify-start items-center py-5">
                <h2
                  className="text-lg font-semibold text-gray-900"
                  id="basic_info"
                  ref={basic_info}
                >
                  Basic Info
                </h2>
              </div>
              <div className="border-gray-900/10 pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {role == "primaryattorney" || role == "admin" ? (
                    <>
                      <div className="col-span-3 sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Firm Name
                        </label>

                        {firmData.firm_name && (
                          <div className="mt-2">
                            <div className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]">
                              {firmData.firm_name}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-span-3 sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Attorney
                        </label>
                        <div className="mt-1">
                          <Listbox
                            value={selectedAttorney}
                            onChange={(value) => {
                              setSelectedAttorney(value);
                              setErrorFlag((prevErrorFlag) => ({
                                ...prevErrorFlag,
                                client: {
                                  ...prevErrorFlag["client"],
                                  attorney: "",
                                },
                              }));

                              setData((prevValue) => ({
                                ...prevValue,
                                client: {
                                  ...prevValue["client"],
                                  attorney: value.id,
                                },
                              }));
                            }}
                          >
                            {({ open }) => (
                              <>
                                <div className="relative">
                                  <Listbox.Button className="relative w-full border-b-2 focus:border-[#344767] cursor-pointer py-1 pl-3 pr-10 text-left text-gray-900 text-sm">
                                    <span className="block truncate">
                                      {selectedAttorney ? (
                                        selectedAttorney.name
                                      ) : (
                                        <span className="text-[#9ca0b3]">
                                          Select
                                        </span>
                                      )}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {attorneysList.length === 0 &&
                                      queryAttorney !== "" ? (
                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                          No match found.
                                        </div>
                                      ) : (
                                        attorneysList.map((attorney) => (
                                          <Listbox.Option
                                            key={attorney.id}
                                            className={({ active }) =>
                                              `relative cursor-default select-none py-2 pl-10 pr-4  ${
                                                active
                                                  ? "bg-[#376080] text-white"
                                                  : "text-gray-900"
                                              }`
                                            }
                                            value={attorney}
                                          >
                                            {({ selected, active }) => (
                                              <>
                                                <span
                                                  className={`block truncate ${
                                                    selected
                                                      ? "font-medium"
                                                      : "font-normal"
                                                  }`}
                                                >
                                                  {attorney.name}
                                                </span>
                                                {selected ? (
                                                  <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                      active
                                                        ? "text-white"
                                                        : "text-[#376080]"
                                                    }`}
                                                  >
                                                    <CheckIcon
                                                      className="h-5 w-5"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))
                                      )}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                        <span className="text-red-500 text-xs">
                          {errorFlag.client?.attorney}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="col-span-3 sm:col-span-6">
                      <div className="w-full sm:w-1/2">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Firm Name
                        </label>

                        {firmData.firm_name && (
                          <div className="mt-2">
                            <div className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]">
                              {firmData.firm_name}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-span-3 sm:col-span-3">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      First Name
                    </label>
                    <div className="mt-2">
                      <input
                        name="first_name"
                        value={data.client?.first_name}
                        onChange={(e) => formHandler(e, "client")}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                        type="text"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.client?.first_name}
                      </span>
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-3">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Last Name
                    </label>
                    <div className="mt-2">
                      <input
                        name="last_name"
                        value={data.client?.last_name}
                        onChange={(e) => formHandler(e, "client")}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                        type="text"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.client?.last_name}
                      </span>
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={data.client?.email}
                        onChange={(e) => {
                          formHandler(e, "client");
                        }}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.client?.email}
                      </span>
                    </div>
                  </div>
                  {/* <div className="col-span-3 sm:col-span-6">
                    <div className="w-full sm:w-1/2">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Mobile Number
                      </label>
                      <div className="mt-2">
                        <input
                          name="cell_phone_number"
                          value={data.client?.cell_phone_number}
                          onChange={(e) => formHandler(e, "client")}
                          type="text"
                          className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                        />
                        <span className="text-red-500 text-xs">
                          {errorFlag.client?.cell_phone_number}
                        </span>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-span-3 sm:col-span-6">
                    <div className="w-full sm:w-1/2">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Mobile Number
                      </label>
                      <div className="mt-2">
                        <CurrencyFormat
                          name="cell_phone_number"
                          value={data.client?.cell_phone_number}
                          onChange={(e) => formHandler(e, "client")}
                          className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          format="+1 (###) ###-####"
                          mask="_"
                        />
                        <span className="text-red-500 text-xs">
                          {errorFlag.client?.cell_phone_number}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <label
                      htmlFor="address_line1"
                      className="block text-md font-medium leading-6 text-[#9ca0b3]"
                    >
                      Address 1
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="address_line1"
                        value={data.client?.address_line1}
                        onChange={(e) => formHandler(e, "client")}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.client?.address_line1}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <label
                      htmlFor="address_line2"
                      className="block text-md font-medium leading-6 text-[#9ca0b3]"
                    >
                      Address 2
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="address_line2"
                        value={data.client?.address_line2}
                        onChange={(e) => formHandler(e, "client")}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.client?.address_line2}
                      </span>
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-md font-medium leading-6 text-[#9ca0b3]"
                    >
                      City
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="city"
                        value={data.client?.city}
                        onChange={(e) => formHandler(e, "client")}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.client?.city}
                      </span>
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="state"
                      className="block text-md font-medium leading-6 text-[#9ca0b3]"
                    >
                      State
                    </label>
                    <div className="mt-1 w-full h-full flex items-center">
                      <div className="grow h-full">
                        <Combobox
                          value={selectedState}
                          name="state"
                          onChange={(value) => {
                            console.log(value);
                            setSelectedState(value);
                            // formHandler()
                            setData((prevValue) => ({
                              ...prevValue,
                              client: {
                                ...prevValue["client"],
                                state: value.code,
                              },
                            }));
                            setErrorFlag((prevErrorFlag) => ({
                              ...prevErrorFlag,
                              client: {
                                ...prevErrorFlag["client"],
                                state: "",
                              },
                            }));
                          }}
                        >
                          <div className="relative">
                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                              <Combobox.Input
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none   border-b-2 text-[#344767]"
                                displayValue={(listedStates) =>
                                  listedStates.code
                                }
                                onChange={(event) => {
                                  setQuery(event.target.value);
                                }}
                                placeholder=""
                              />
                            </div>
                            <span className="text-red-500 text-xs">
                              {errorFlag.client?.state}
                            </span>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setQuery("")}
                            >
                              <Combobox.Options className="absolute  max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredstates.length === 0 && query !== "" ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    No match found.
                                  </div>
                                ) : (
                                  filteredstates.map((listedStates) => (
                                    <Combobox.Option
                                      key={listedStates.id}
                                      className={({ active }) =>
                                        `relative  z-10 cursor-default select-none py-2 pl-10 pr-4  ${
                                          active
                                            ? "bg-[#376080] text-white"
                                            : "bg-white text-gray-900"
                                        }`
                                      }
                                      value={listedStates}
                                      disabled={listedStates.unavailable}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`block truncate  ${
                                              selected
                                                ? "font-medium"
                                                : "font-normal"
                                            }`}
                                          >
                                            {listedStates.code}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                active
                                                  ? "text-white"
                                                  : "text-[#376080]"
                                              }`}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="zip_code"
                      className="block text-md font-medium leading-6 text-[#9ca0b3]"
                    >
                      Zip Code
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="zip_code"
                        value={data.client?.zip_code}
                        onChange={(e) => formHandler(e, "client")}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.client?.zip_code}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-span-3 sm:col-span-2"
                    onClick={() => {
                      caseTypeOptionsRef.current.click();
                    }}
                  >
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Case Type
                    </label>
                    <div className="">
                      <Listbox
                        value={selectedCaseType}
                        onChange={(value) => {
                          console.log(value);
                          setErrorFlag((prevErrorFlag) => ({
                            ...prevErrorFlag,
                            contract: {
                              ...prevErrorFlag["contract"],
                              ["case_type"]: "",
                            },
                          }));
                          setData((prevValue) => ({
                            ...prevValue,
                            contract: {
                              ...prevValue["contract"],
                              case_type: value.case_type,
                            },
                          }));
                          setSelectedCaseType(value);
                        }}
                      >
                        {({ open }) => (
                          <>
                            <div className="relative mt-4 sm:mt-0">
                              <Listbox.Button
                                ref={caseTypeOptionsRef}
                                className={`relative w-full border-b-2 focus:border-[#344767] cursor-pointer   py-1 pl-3 pr-10 text-left text-gray-900 text-sm
                             `}
                              >
                                <span className="block truncate">
                                  {selectedCaseType ? (
                                    selectedCaseType.case_type
                                  ) : (
                                    <span className="text-[#9ca0b3]">
                                      Select
                                    </span>
                                  )}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {caseTypeOptions &&
                                    caseTypeOptions.map((type) => (
                                      <Listbox.Option
                                        key={type.id}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? "bg-[#1a3253] text-white"
                                              : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                          )
                                        }
                                        value={type}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "block truncate"
                                              )}
                                            >
                                              {type.case_type}
                                            </span>

                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active
                                                    ? "text-white"
                                                    : "text-[#1a3253]",
                                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                                )}
                                              >
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                      <span className="text-red-500 text-xs">
                        {errorFlag.contract?.case_type}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-3 sm:col-span-2">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Case Number
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="case_number"
                        value={data.contract?.case_number}
                        onChange={(e) => formHandler(e, "contract")}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.contract?.case_number}
                      </span>
                    </div>
                  </div>
                </div>

                <div className=" mt-5 inline-flex items-center justify-start gap-3">
                  <div className="">
                    <input
                      type="checkbox"
                      name="co_debtor"
                      checked={data.co_debtor}
                      onClick={(e) => {
                        // console.log(e);
                        formHandler(e, "co_debtor");
                      }}
                    />
                  </div>
                  <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                    Add Co-Debtor's
                  </label>
                </div>
                {data.co_debtor ? (
                  <div className="grid grid-col-1 gap-x-6 gap-y-8 sm:grid-cols-2 mt-5">
                    <div className="col-span-1 ">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Co-Debtor's Name
                      </label>
                      <div className="mt-2">
                        <input
                          name="name"
                          value={data.co_debtor_data?.name}
                          onChange={(e) => formHandler(e, "co_debtor_data")}
                          className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          type="text"
                        />
                        <span className="text-red-500 text-xs">
                          {errorFlag.co_debtor_data?.name}
                        </span>
                      </div>
                    </div>
                    <div className="col-span-1 ">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Co-Debtor's Email
                      </label>
                      <div className="mt-2">
                        <input
                          name="email"
                          value={data.co_debtor_data?.email}
                          onChange={(e) => formHandler(e, "co_debtor_data")}
                          className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          type="text"
                        />
                        <span className="text-red-500 text-xs">
                          {errorFlag.co_debtor_data?.email}
                        </span>
                      </div>
                    </div>
                    <div className="col-span-1 ">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Co-Debtor's Phone Number
                      </label>
                      <div className="mt-2">
                        <CurrencyFormat
                          name="cell_phone_number"
                          value={data.co_debtor_data?.cell_phone_number}
                          onChange={(e) => formHandler(e, "co_debtor_data")}
                          className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          format="+1 (###) ###-####"
                          mask="_"
                        />
                        <span className="text-red-500 text-xs">
                          {errorFlag.co_debtor_data?.cell_phone_number}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="border rounded-xl px-5 my-5">
          <form>
            <div className="" ref={financeDetailsRef}>
              <div className="w-full flex justify-start items-center py-5">
                <h2 className="text-lg font-semibold text-gray-900">
                  Financial Information
                </h2>
              </div>
              <div className="border-gray-900/10 pb-12">
                <div className="grid  sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-5 mt-5">
                  <div className="col-span-1 grid mt-5">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Contract Amount
                    </label>
                    <div className="mt-2">
                      <CurrencyFormat
                        name="contract_amount"
                        id="contract_amount"
                        placeholder="$0.00"
                        value={data.contract.contract_amount}
                        onChange={(e) => formHandler(e, "contract")}
                        displayType={"input"}
                        thousandSeparator={true}
                        prefix={"$"}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.contract?.contract_amount}
                      </span>
                    </div>
                  </div>

                  <div className="col-span-1 grid mt-5">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Contract Period
                    </label>
                    <div className="mt-2">
                      <input
                        id="tenure"
                        name="tenure"
                        placeholder="Months"
                        value={data.contract.tenure}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                        onChange={(e) => {
                          formHandler(e, "contract");
                        }}
                      />

                      <span
                        className={
                          errorFlag.contract?.tenure
                            ? "text-red-500 text-xs"
                            : "text-yellow-500 text-xs"
                        }
                      >
                        {errorFlag.contract?.tenure
                          ? errorFlag.contract?.tenure
                          : parseInt(data.contract.tenure) > 10
                          ? errMsg.tenure
                          : ""}
                      </span>
                    </div>
                  </div>

                  <div className="col-span-1 grid mt-5">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Monthly Income
                    </label>
                    <div className="mt-2">
                      <CurrencyFormat
                        name="client_monthly_income"
                        id="client_monthly_income"
                        placeholder="$0.00"
                        value={data.contract.client_monthly_income}
                        onChange={(e) => formHandler(e, "contract")}
                        displayType={"input"}
                        thousandSeparator={true}
                        prefix={"$"}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.contract?.client_monthly_income}
                      </span>
                    </div>
                  </div>

                  <div className="col-span-1 grid mt-5">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Down Payment
                    </label>
                    <div className="mt-2">
                      <CurrencyFormat
                        name="down_payment"
                        id="down_payment"
                        placeholder="$0.00"
                        value={data.contract.down_payment}
                        onChange={(e) => formHandler(e, "contract")}
                        displayType={"input"}
                        thousandSeparator={true}
                        prefix={"$"}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                      />
                      <span className="text-red-500 text-xs">
                        {errorFlag.contract?.down_payment}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-10 py-5 px-2">
                  <div className="flex flex-col items-end gap-5 sm:flex-row sm:items-center sm:gap-10">
                    <div className="text-[#8b90a7]">
                      Click on "Browse" button to upload fee agreement files
                    </div>
                    <div>
                      <label className="flex gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 hover:cursor-pointer">
                        <DocumentArrowUpIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        <input
                          type="file"
                          name="contract"
                          onChange={(e) => {
                            formHandler(e, "files");
                          }}
                          multiple
                          accept=".jpg, .jpeg, .png, .wep, .pdf"
                        />
                        <div className="hover:cursor-pointer">Upload</div>
                      </label>
                    </div>
                    <span className="text-red-500 text-xs">
                      {errorFlag.files?.contract}
                    </span>
                  </div>

                  <div>
                    {contractDoc.length || contractDocReceived.length ? (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Uploaded File
                        </dt>
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <ul
                            role="list"
                            className={`divide-y divide-gray-100 rounded-md ${
                              contractDoc.length || contractDocReceived.length
                                ? `border border-gray-200`
                                : ""
                            }`}
                          >
                            {contractDocReceived.map((file, index) => (
                              <li
                                key={index}
                                className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 "
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <PaperClipIcon
                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                    <span className="truncate font-medium">
                                      {file.name}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div
                                    onClick={() => {
                                      window.open(
                                        `${domainUrl}${file.path.slice(1)}`,
                                        "_blank"
                                      );
                                    }}
                                    className="flex text-indigo-700  cursor-pointer border-indigo-900 border-2 pl-3 pr-3 rounded"
                                  >
                                    Preview
                                  </div>
                                  <TrashIcon
                                    onClick={(e) => {
                                      // formHandler(e, "income_del", index)
                                      console.log(file.path.split("?")[0]);

                                      deleteReceivedFile(
                                        file.path.split("?")[0],
                                        "contract",
                                        index
                                      );
                                    }}
                                    className="ml-4 h-5 w-5  font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                  />
                                </div>
                              </li>
                            ))}
                            {contractDoc.map((file, index) => (
                              <li
                                key={index}
                                className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 "
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <PaperClipIcon
                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                    <span className="truncate font-medium">
                                      {file.name}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div
                                    onClick={() => {
                                      window.open(
                                        URL.createObjectURL(file),
                                        "_blank"
                                      );
                                    }}
                                    className="flex text-violet-700  cursor-pointer border-violet-900  border-2 pl-3 pr-3 rounded"
                                  >
                                    Preview
                                  </div>
                                  <TrashIcon
                                    onClick={(e) =>
                                      formHandler(e, "contract_del", index)
                                    }
                                    className="ml-4 h-5 w-5  font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </dd>
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
                <div className="mt-10 py-5 px-2">
                  <div className="flex flex-col items-end gap-5 sm:flex-row sm:items-center sm:gap-10">
                    <div className="text-[#8b90a7]">
                      Click on "Browse" button to select supporting document for
                      proof of income
                    </div>

                    <div>
                      <label className="flex gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 hover:cursor-pointer">
                        <DocumentArrowUpIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />

                        <input
                          type="file"
                          name="income"
                          onChange={(e) => {
                            formHandler(e, "files");
                          }}
                          multiple
                          accept=".jpg, .jpeg, .png, .wep, .pdf"
                        />

                        <div className="hover:cursor-pointer">Upload</div>
                      </label>
                    </div>
                    <span className="text-red-500 text-xs">
                      {errorFlag.files?.income}
                    </span>
                  </div>
                  <div>
                    {incomeDoc.length || incomeDocReceived.length ? (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Uploaded File
                        </dt>
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <ul
                            role="list"
                            className={`divide-y divide-gray-100 rounded-md ${
                              incomeDoc.length || incomeDocReceived.length
                                ? `border border-gray-200`
                                : ""
                            }`}
                          >
                            {incomeDocReceived.map((file, index) => (
                              <li
                                key={index}
                                className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 "
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <PaperClipIcon
                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                    <span className="truncate font-medium">
                                      {file.name}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div
                                    onClick={() => {
                                      window.open(
                                        `${domainUrl}${file.path.slice(1)}`,
                                        "_blank"
                                      );
                                    }}
                                    className="flex text-indigo-700  cursor-pointer border-indigo-900 border-2 pl-3 pr-3 rounded"
                                  >
                                    Preview
                                  </div>
                                  <TrashIcon
                                    onClick={(e) => {
                                      // formHandler(e, "income_del", index)
                                      console.log(file.path.split("?")[0]);

                                      deleteReceivedFile(
                                        file.path.split("?")[0],
                                        "income",
                                        index
                                      );
                                    }}
                                    className="ml-4 h-5 w-5  font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                  />
                                </div>
                              </li>
                            ))}
                            {incomeDoc.map((file, index) => (
                              <li
                                key={index}
                                className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 "
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <PaperClipIcon
                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                    <span className="truncate font-medium">
                                      {file.name}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div
                                    onClick={() => {
                                      window.open(
                                        URL.createObjectURL(file),
                                        "_blank"
                                      );
                                    }}
                                    className="flex text-indigo-700  cursor-pointer border-indigo-900 border-2 pl-3 pr-3 rounded"
                                  >
                                    Preview
                                  </div>
                                  <TrashIcon
                                    onClick={(e) =>
                                      formHandler(e, "income_del", index)
                                    }
                                    className="ml-4 h-5 w-5  font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </dd>
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
                <div className="mt-10 py-5 px-2">
                  <div className="flex flex-col items-end gap-5 sm:flex-row sm:items-center sm:gap-10">
                    <div className="text-[#8b90a7]">
                      Click on "Browse" button to upload client additional files
                    </div>
                    <div>
                      <label className="flex gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 hover:cursor-pointer">
                        <DocumentArrowUpIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        <input
                          type="file"
                          name="others"
                          onChange={(e) => {
                            formHandler(e, "files");
                          }}
                          multiple
                          accept=".jpg, .jpeg, .png, .wep, .pdf"
                        />
                        <div className="hover:cursor-pointer">Upload</div>
                      </label>
                    </div>
                    <span className="text-red-500 text-xs">
                      {errorFlag.files?.others}
                    </span>
                  </div>

                  <div>
                    {additionalDoc.length || additionalDocReceived.length ? (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Uploaded File
                        </dt>
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <ul
                            role="list"
                            className={`divide-y divide-gray-100 rounded-md ${
                              additionalDoc.length ||
                              additionalDocReceived.length
                                ? `border border-gray-200`
                                : ""
                            }`}
                          >
                            {additionalDocReceived.map((file, index) => (
                              <li
                                key={index}
                                className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 "
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <PaperClipIcon
                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                    <span className="truncate font-medium">
                                      {file.name}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div
                                    onClick={() => {
                                      window.open(
                                        `${domainUrl}${file.path.slice(1)}`,

                                        "_blank"
                                      );
                                    }}
                                    className="flex text-violet-700  cursor-pointer border-violet-900  border-2 pl-3 pr-3 rounded"
                                  >
                                    Preview
                                  </div>
                                  <TrashIcon
                                    onClick={(e) => {
                                      // formHandler(e, "others_del", index)
                                      console.log(
                                        file.path.split("?")[0],
                                        index
                                      );
                                      deleteReceivedFile(
                                        file.path.split("?")[0],
                                        "additional",
                                        index
                                      );
                                    }}
                                    className="ml-4 h-5 w-5  font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                  />
                                </div>
                              </li>
                            ))}
                            {additionalDoc.map((file, index) => (
                              <li
                                key={index}
                                className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 "
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <PaperClipIcon
                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                    <span className="truncate font-medium">
                                      {file.name}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div
                                    onClick={() => {
                                      window.open(
                                        URL.createObjectURL(file),
                                        "_blank"
                                      );
                                    }}
                                    className="flex text-violet-700  cursor-pointer border-violet-900  border-2 pl-3 pr-3 rounded"
                                  >
                                    Preview
                                  </div>
                                  <TrashIcon
                                    onClick={(e) =>
                                      formHandler(e, "others_del", index)
                                    }
                                    className="ml-4 h-5 w-5  font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </dd>
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="border rounded-xl px-5 my-5">
          <div className="w-full flex justify-start items-center py-5">
            <h2 className="text-lg font-semibold text-gray-900">
              Added Payment Methods
            </h2>
          </div>
          <div className="my-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
            {accountsDetail.map((account, accountIdx) => (
              <div
                key={accountIdx}
                className="border-indigo-500 ring-2 ring-indigo-600 relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
              >
                <>
                  <span className="flex items-center mx-3">
                    {account.type == "ach" ? (
                      <img
                        src={bankImg}
                        alt="bankImg"
                        className=" w-10 mt-1  flex-shrink-0"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "Amex" ? (
                      <img
                        src={amex}
                        alt="amex"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "AmericanExpress" ? (
                      <img
                        src={american_express}
                        alt="american_express"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "Carteblanche" ? (
                      <img
                        src={carteblanche}
                        alt="carteblanche"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "Celo" ? (
                      <img
                        src={celo}
                        alt="celo"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "Dankort" ? (
                      <img
                        src={dankort}
                        alt="dankort"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "Diners Club" ? (
                      <img
                        src={diners}
                        alt="diners"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "Discover" ? (
                      <img
                        src={discover}
                        alt="discover"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}

                    {account.name == "Forbrugsforeningen" ? (
                      <img
                        src={forbrugsforeningen}
                        alt="forbrugsforeningen"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "Hipercard" ? (
                      <img
                        src={hipercard}
                        alt="hipercard"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "Instapayment" ? (
                      <img
                        src={instapayment}
                        alt="instapayment"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "JCB" ? (
                      <img
                        src={jcb}
                        alt="jcb"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "laser" ? (
                      <img
                        src={laser}
                        alt="laser"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "MasterCard" ? (
                      <img
                        src={mastercard}
                        alt="mastercard"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "mnp" ? (
                      <img
                        src={mnp}
                        alt="mnp"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "nocards" ? (
                      <img
                        src={nocards}
                        alt="nocards"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "onpay" ? (
                      <img
                        src={onpay}
                        alt="onpay"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "rupay" ? (
                      <img
                        src={rupay}
                        alt="rupay"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "troy" ? (
                      <img
                        src={troy}
                        alt="troy"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "uatp" ? (
                      <img
                        src={uatp}
                        alt="uatp"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "Visa" ? (
                      <img
                        src={visa}
                        alt="visa"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "visa_electron" ? (
                      <img
                        src={visa_electron}
                        alt="visa_electron"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                    {account.name == "vpay" ? (
                      <img
                        src={vpay}
                        alt="vpay"
                        className=" w-12  flex-shrink-0 rounded-full"
                      />
                    ) : (
                      ""
                    )}
                  </span>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <span className="block text-base font-medium text-gray-500">
                        {account.name}
                      </span>
                      <span className="mt-1 flex items-center text-lg text-gray-900">
                        {account.description}
                      </span>
                      <span className="mt-6 text-sm font-medium text-gray-900">
                        {account.type.toUpperCase()}
                      </span>
                    </span>
                  </span>
                  <span>
                    <TrashIcon
                      onClick={(e) => {
                        console.log("Trash", account, accountIdx);
                        deleteRecievedPaymentMethod(
                          account.customer_payment_method_id,
                          account.type
                        );
                      }}
                      className="ml-4 h-5 w-5  font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                    />
                  </span>
                </>
              </div>
            ))}
          </div>
        </div>

        <div className="border rounded-xl px-5 my-5">
          <form>
            <div className="">
              <div className="w-full sm:flex flex-col justify-between items-start py-5">
                <h2 className="w-full text-lg font-semibold text-gray-900">
                  Debit Card Information
                </h2>
                <div className="flex flex-col items-start gap-1">
                  <div className="text-sm text-gray-500">
                    The Bank account/ACH will be primary payment method. If the
                    payment cannot be processed via ACH it will be processed via
                    the card. Please note and convey that all card transactions
                    carry a 2.5% processing fee
                  </div>
                </div>
              </div>
              <div className="border-gray-900/10 pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="col-span-1 sm:col-span-3">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Name on Debit Card /Account
                    </label>
                    <div className="mt-5">
                      <input
                        type="text"
                        value={data.payment_card?.cardholder}
                        name="cardholder"
                        id="cardholder"
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767] uppercase"
                        onChange={(e) => formHandler(e, "payment_card")}
                      />
                    </div>
                    <span className="text-red-500 text-xs">
                      {errorFlag.payment_card?.cardholder}
                    </span>
                  </div>
                  <div className="col-span-1 sm:col-span-3">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Debit Card Number
                    </label>
                    <div className="mt-5">
                      <CurrencyFormat
                        name="card_number"
                        id="card_number"
                        onChange={(e) => formHandler(e, "payment_card")}
                        value={data.payment_card?.card_number}
                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767] uppercase"
                        format="#### #### #### ####"
                         mask="_"
                      />
                    </div>
                    <span className="text-red-500 text-xs">
                      {errorFlag.payment_card?.card_number}
                    </span>
                  </div>
                  <div className="sm:col-span-4">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Card Expires:(MM/YY)
                    </label>
                    <div className="w-full flex mt-5">
                      <div className="w-20 flex flex-col gap-1">
                        <input
                          type="text"
                          name="cardExpiryMonth"
                          onChange={(e) => {
                            formHandler(e, "payment_card");
                          }}
                          maxLength={2}
                          id="cardExpiryMonth"
                          value={cardDetails.cardExpiryMonth}
                          className="mr-2 border-b-1 focus:border-[#344767] block w-16  outline-none   border-b-2 "
                        />
                        <p className="text-red-600 text-xs">
                          {cardDetailsError?.cardExpiryMonth}
                        </p>
                      </div>
                      /
                      <div className="w-20 flex flex-col gap-1">
                        <input
                          type="text"
                          name="cardExpiryYear"
                          onChange={(e) => formHandler(e, "payment_card")}
                          id="cardExpiryYear"
                          value={cardDetails.cardExpiryYear}
                          maxLength={2}
                          className="ml-2 border-b-1 focus:border-[#344767] block w-16 outline-none  border-b-2 "
                        />
                        <p className="text-red-600 text-xs">
                          {cardDetailsError?.cardExpiryYear}
                        </p>
                      </div>
                    </div>
                    <p className="text-red-600 text-xs">
                      {errorFlag.payment_card?.expiration_date}
                    </p>
                    {cardExpiryAlert && (
                      <div className=" mt-2 border-l-4 border-yellow-400 bg-yellow-50 px-4 py-2">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                              className="h-5 w-5 text-yellow-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-sm text-yellow-700">
                              This card will expire within 10 months
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className="w-full sm:flex flex-col justify-between items-start py-5">
                    <h2 className="w-full text-lg font-semibold text-gray-900">
                      Bank Account Information
                    </h2>
                  </div>
                  <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-1 sm:col-span-3">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Routing Number
                      </label>
                      <div className="mt-5">
                        <input
                          type="text"
                          name="bank_routing_number"
                          value={data.direct_deposit?.bank_routing_number}
                          onChange={(e) => formHandler(e, "direct_deposit")}
                          id="bank_routing_number"
                          className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 "
                        />
                      </div>
                      <span className="text-red-500 text-xs">
                        {errorFlag.direct_deposit?.bank_routing_number}
                      </span>
                    </div>
                    <div className="col-span-1 sm:col-span-3">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Account Number
                      </label>
                      <div className="mt-5">
                        <input
                          type="text"
                          name="bank_account_number"
                          value={data.direct_deposit?.bank_account_number}
                          onChange={(e) => formHandler(e, "direct_deposit")}
                          id="bank_account_number"
                          className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 "
                        />
                      </div>
                      <span className="text-red-500 text-xs">
                        {errorFlag.direct_deposit?.bank_account_number}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="border rounded-xl px-5 my-5">
          <form>
            <div className="">
              <div className="w-full flex justify-start items-center py-5">
                <h2 className="text-lg font-semibold text-gray-900">
                  Payment Schedule
                </h2>
              </div>
              <div className="border-gray-900/10 pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
                  <div className="col-span-1 sm:col-span-2">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Choose schedule Type
                    </label>
                    <Listbox
                      name="payment_plan"
                      value={data.contract.payment_plan}
                      onChange={(value) => {
                        setErrorFlagPayField({
                          ...errorFlagPayField,
                          day: "",
                          date: "",
                          weeks: "",
                        });
                        setPickableDates([]);
                        setErrorFlag((prevErrorFlag) => ({
                          ...prevErrorFlag,
                          contract: {
                            ...prevErrorFlag["contract"],
                            payment_plan: "",
                            start_date: "",
                          },
                        }));
                        setCalendarShowState(false);
                        setData((prevValue) => ({
                          ...prevValue,
                          contract: {
                            ...prevValue["contract"],
                            start_date: "",
                          },
                        }));
                        setData((prevValue) => ({
                          ...prevValue,
                          contract: {
                            ...prevValue["contract"],
                            payment_plan: value.name,
                            scheduled_time: [],
                          },
                        }));
                        setSelectedPaymentPlan(value);
                        setDisplayDate([]);
                        setDisplayWeekDay(undefined);
                        setDisplayWeeks([]);
                        setWeekDayField([
                          { id: 0, name: "MONDAY" },
                          { id: 1, name: "TUESDAY" },
                          { id: 2, name: "WEDNESDAY" },
                          { id: 3, name: "THURSDAY" },
                          { id: 4, name: "FRIDAY" },
                        ]);
                        setWeek([
                          { id: 1, name: "Week One" },
                          { id: 2, name: "Week Two" },
                          { id: 3, name: "Week Three" },
                          { id: 4, name: "Week Four" },
                          // { id: 5, name: "Week Five" },
                        ]);
                        setPreviewSchedule("");
                        setEvents([]);
                      }}
                    >
                      {({ open }) => (
                        <>
                          <div className="relative mt-2">
                            <Listbox.Button className="relative w-full border-b-2 focus:border-[#344767] cursor-pointer   py-1 pl-3 pr-10 text-left text-gray-900 text-sm">
                              <span className="block truncate">
                                {selectedPaymentPlan && selectedPaymentPlan ? (
                                  paymentPlanString[selectedPaymentPlan.name]
                                ) : (
                                  <>Select</>
                                )}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {paymentType.map((type) => (
                                  <Listbox.Option
                                    key={type.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-[#1a3253] text-white"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={type}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )}
                                        >
                                          {paymentPlanString[type.name]}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-[#1a3253]",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>

                    <span className="text-red-500 text-xs">
                      {errorFlag.contract?.payment_plan}
                    </span>
                  </div>

                  {(data.contract.payment_plan == "weekly" ||
                    data.contract.payment_plan == "semi_monthly_days" ||
                    data.contract.payment_plan == "bi_weekly") && (
                    <div className="col-span-1 sm:col-span-2">
                      <label className="text-md font-medium leading-6 text-[#9ca0b3]">
                        Please Select From the below
                      </label>
                      <div className="">
                        <div className="w-full flex justify-end mt-2">
                          <label className="text-md font-medium  leading-6 text-[#9ca0b3] mr-5">
                            Every
                          </label>
                          <div className="w-1/2">
                            <div className="">
                              <Listbox
                                value={displayWeekDay}
                                onChange={(value) => {
                                  setDisplayWeekDay(value);
                                  if (
                                    data.contract.payment_plan == "weekly" ||
                                    data.contract.payment_plan == "bi_weekly"
                                  ) {
                                    setPickableDates([]);
                                    setData((prevValue) => ({
                                      ...prevValue,
                                      contract: {
                                        ...prevValue["contract"],
                                        start_date: "",
                                      },
                                    }));
                                    setData((prevValue) => ({
                                      ...prevValue,
                                      contract: {
                                        ...prevValue["contract"],
                                        scheduled_time: [value],
                                      },
                                    }));
                                    generatePickableDates([value]);
                                  } else {
                                    // Payment plan -> Semi monthly days
                                    setErrorFlagPayField((prevValue) => ({
                                      ...prevValue,
                                      day: "",
                                    }));
                                    setPickableDates([]);
                                    setData((prevValue) => ({
                                      ...prevValue,
                                      contract: {
                                        ...prevValue["contract"],
                                        start_date: "",
                                      },
                                    }));
                                    generatePickableDates([
                                      {
                                        ...data.contract.scheduled_time[0],
                                        day: value.name,
                                      },
                                    ]);
                                    setData((prevValue) => ({
                                      ...prevValue,
                                      contract: {
                                        ...prevValue["contract"],
                                        scheduled_time: [
                                          {
                                            ...prevValue.contract
                                              .scheduled_time[0],
                                            day: value.name,
                                          },
                                        ],
                                      },
                                    }));
                                  }
                                }}
                              >
                                {({ open }) => (
                                  <>
                                    <div className="relative">
                                      <Listbox.Button className="relative w-full border-b-2 focus:border-[#344767] cursor-pointer   py-1 pl-3 pr-10 text-left text-gray-900 text-sm">
                                        <span className="block truncate">
                                          {displayWeekDay ? (
                                            displayWeekDay.name
                                          ) : (
                                            <span className="text-[#9ca0b3]">
                                              Select
                                            </span>
                                          )}
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {weeklyDayField.map((type) => (
                                            <Listbox.Option
                                              key={type.id}
                                              className={({ active }) =>
                                                classNames(
                                                  active
                                                    ? "bg-[#1a3253] text-white"
                                                    : "text-gray-900",
                                                  "relative cursor-default select-none py-2 pl-8 pr-4"
                                                )
                                              }
                                              value={type}
                                            >
                                              {({ selected, active }) => (
                                                <>
                                                  <span
                                                    className={classNames(
                                                      selected
                                                        ? "font-semibold"
                                                        : "font-normal",
                                                      "block truncate"
                                                    )}
                                                  >
                                                    {type.name}
                                                  </span>

                                                  {selected ? (
                                                    <span
                                                      className={classNames(
                                                        active
                                                          ? "text-white"
                                                          : "text-indigo-600",
                                                        "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                                      )}
                                                    >
                                                      <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>
                            <span className="text-red-500 text-xs">
                              {errorFlagPayField?.day}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {(data.contract.payment_plan == "monthly" ||
                    data.contract.payment_plan == "semi_monthly_dates") && (
                    <div className="col-span-1 sm:col-span-2">
                      <label className="text-md font-medium leading-6 text-[#9ca0b3]">
                        Choose your preferred date
                      </label>
                      <div className="">
                        <div className="w-full flex justify-end mt-2">
                          <label className="text-md font-medium leading-6 text-[#9ca0b3] mr-5">
                            Every
                          </label>
                          <div className="w-1/2">
                            <div className="">
                              <Listbox
                                multiple={
                                  data.contract.payment_plan ==
                                  "semi_monthly_dates"
                                    ? true
                                    : false
                                }
                                value={displayDate}
                                onChange={(value) => {
                                  if (Array.isArray(value)) {
                                    if (
                                      value.length == 0 ||
                                      value.length == 2
                                    ) {
                                      setErrorFlagPayField((prevValue) => ({
                                        ...prevValue,
                                        date: "",
                                      }));
                                    }
                                  }
                                  if (Array.isArray(value)) {
                                    if (value.length > 2) {
                                      toast.error("Maximum limit reached", {
                                        id: 1,
                                      });
                                      return;
                                    }
                                  }

                                  if (Array.isArray(value)) {
                                    if (
                                      data.contract.payment_plan ==
                                      "semi_monthly_dates"
                                    ) {
                                      if (value.length !== 2) {
                                        setErrorFlagPayField((prevValue) => ({
                                          ...prevValue,
                                          date: "Please select two dates",
                                        }));
                                      }
                                    }
                                  }
                                  setData((prevValue) => ({
                                    ...prevValue,
                                    contract: {
                                      ...prevValue["contract"],
                                      start_date: "",
                                    },
                                  }));
                                  if (Array.isArray(value)) {
                                    if (value.length > 2) {
                                      toast.error("Maximum limit reached", {
                                        id: 1,
                                      });
                                      return;
                                    }
                                  }
                                  console.log(value);
                                  setDisplayDate(value);

                                  setData((prevValue) => ({
                                    ...prevValue,
                                    contract: {
                                      ...prevValue["contract"],
                                      scheduled_time: Array.isArray(value)
                                        ? value.sort((x, y) => x - y)
                                        : [value],
                                    },
                                  }));
                                  //   formHandler(e, "payment_data");
                                  generatePickableDates(
                                    Array.isArray(value)
                                      ? value.sort((x, y) => x - y)
                                      : [value]
                                  );
                                }}
                              >
                                {({ open }) => (
                                  <>
                                    <div className="relative">
                                      <Listbox.Button className="relative w-full border-b-2 focus:border-[#344767] cursor-pointer   py-1 pl-3 pr-10 text-left text-gray-900 text-sm">
                                        <span className="block truncate">
                                          {(
                                            Array.isArray(displayDate)
                                              ? displayDate.length
                                              : displayDate
                                          ) ? (
                                            `${
                                              Array.isArray(displayDate)
                                                ? [...displayDate]
                                                : displayDate
                                            }`
                                          ) : (
                                            <span className="text-[#9ca0b3]">
                                              Select
                                            </span>
                                          )}
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="w-full absolute z-10 mt-1 max-h-60  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {selectedDateNumber.map(
                                            (type, id) => (
                                              <Listbox.Option
                                                key={id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active
                                                      ? "bg-[#1a3253] text-white"
                                                      : "text-gray-900",
                                                    "relative cursor-default select-none py-2 pl-8 pr-4"
                                                  )
                                                }
                                                value={type}
                                              >
                                                {({ selected, active }) => (
                                                  <>
                                                    <span
                                                      className={classNames(
                                                        selected
                                                          ? "font-semibold"
                                                          : "font-normal",
                                                        "block truncate"
                                                      )}
                                                    >
                                                      {type}
                                                    </span>

                                                    {selected ? (
                                                      <span
                                                        className={classNames(
                                                          active
                                                            ? "text-white"
                                                            : "text-indigo-600",
                                                          "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                                        )}
                                                      >
                                                        <CheckIcon
                                                          className="h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            )
                                          )}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>
                            <span className="text-red-500 text-xs">
                              {errorFlagPayField?.date}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {data.contract.payment_plan == "semi_monthly_days" && (
                    <div className="col-span-1 sm:col-span-2">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Weeks
                      </label>
                      <div className="">
                        <Listbox
                          multiple
                          value={displayWeeks}
                          onChange={(value) => {
                            if (Array.isArray(value)) {
                              if (value.length == 0 || value.length == 2) {
                                setErrorFlagPayField((prevValue) => ({
                                  ...prevValue,
                                  weeks: "",
                                }));
                              }
                            }
                            if (Array.isArray(value)) {
                              if (value.length > 2) {
                                toast.error("Maximum limit reached", {
                                  id: 1,
                                });
                                return;
                              }
                            }
                            if (Array.isArray(value)) {
                              if (
                                data.contract.payment_plan ==
                                "semi_monthly_days"
                              ) {
                                if (value.length == 1) {
                                  setErrorFlagPayField((prevValue) => ({
                                    ...prevValue,
                                    weeks: "Please select two weeks",
                                  }));
                                }
                              }
                            }
                            setData((prevValue) => ({
                              ...prevValue,
                              contract: {
                                ...prevValue["contract"],
                                start_date: "",
                              },
                            }));
                            if (Array.isArray(value)) {
                              if (value.length > 2) {
                                toast.error("Maximum limit reached", {
                                  id: 1,
                                });
                                return;
                              }
                            }

                            console.log("###", value);
                            value = value.sort((a, b) => a.id - b.id);
                            setDisplayWeeks(value);
                            let ids = value.map((week) => week.id);

                            generatePickableDates([
                              {
                                ...data.contract.scheduled_time[0],
                                weeks: ids,
                              },
                            ]);

                            setData((prevValue) => ({
                              ...prevValue,
                              contract: {
                                ...prevValue["contract"],
                                scheduled_time: [
                                  {
                                    ...prevValue.contract.scheduled_time[0],
                                    weeks: ids,
                                  },
                                ],
                              },
                            }));
                            console.log("@@@@@@@", value);
                          }}
                        >
                          {({ open }) => (
                            <>
                              <div className="relative mt-2">
                                <Listbox.Button
                                  className={`relative w-full border-b-2 focus:border-[#344767] cursor-pointer   py-1 pl-3 pr-10 text-left text-gray-900 text-sm
                             `}
                                >
                                  <span className="block truncate">
                                    {displayWeeks.length ? (
                                      displayWeeks
                                        .map((item) => item.name)
                                        .join(", ")
                                    ) : (
                                      <span className="text-[#9ca0b3]">
                                        Select
                                      </span>
                                    )}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {week.map((type, id) => (
                                      <Listbox.Option
                                        key={id}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? "bg-[#1a3253] text-white"
                                              : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                          )
                                        }
                                        value={type}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "block truncate"
                                              )}
                                            >
                                              {type.name}
                                            </span>

                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active
                                                    ? "text-white"
                                                    : "text-[#1a3253]",
                                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                                )}
                                              >
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                        <span className="text-red-500 text-xs">
                          {/* {errorFlag.contract?.case_type} */}
                        </span>
                      </div>
                      <span className="text-red-500 text-xs">
                        {errorFlagPayField?.weeks}
                      </span>
                    </div>
                  )}

                  <div className="col-span-1 sm:col-span-2">
                    <div className="grid-cols-1 relative">
                      {calendarShowState && (
                        <div
                          className="absolute bottom-32 animate-fadeIn "
                          id="reactCalendar"
                        >
                          <ReactCalendar
                            onClickDay={(e) => {
                              console.log(
                                "This e",
                                e.toLocaleDateString("en-CA")
                              );
                              if (
                                pickableDates.includes(
                                  e.toLocaleDateString("en-CA")
                                )
                              ) {
                                setData((prevValue) => ({
                                  ...prevValue,
                                  contract: {
                                    ...prevValue["contract"],
                                    start_date: e.toLocaleDateString("en-CA"),
                                  },
                                }));
                                setCalendarShowState(false);
                                setErrorFlag((prevValue) => ({
                                  ...prevValue,
                                  contract: {
                                    ...prevValue["contract"],
                                    start_date: "",
                                  },
                                }));
                              } else {
                                setErrorFlag((prevValue) => ({
                                  ...prevValue,
                                  contract: {
                                    ...prevValue["contract"],
                                    start_date:
                                      "You can only pick highlighted dates",
                                  },
                                }));
                                toast.error(
                                  "You can only select from the highlighted dates",
                                  { id: 1 }
                                );
                              }
                            }}
                            tileContent={tileContent}
                          />
                        </div>
                      )}
                      <label className="text-md font-medium leading-6 text-[#9ca0b3] ">
                        Choose Starting Date
                      </label>
                      <div className="w-full">
                        <div>
                          <div>
                            {pickableDates.length ? (
                              <>
                                {data.contract?.start_date != "" ? (
                                  <div className="lg:block flex xl:flex md:flex justify-end mt-2 w-full">
                                    <span className="text-black-500 text-xs w-2/3">
                                      <input
                                        readOnly
                                        value={formatDate(
                                          data.contract?.start_date
                                        )}
                                        className="p-2  border-b border-gray-400  cursor-default w-full"
                                      />
                                    </span>
                                    <>
                                      {!calendarShowState ? (
                                        <button
                                          className=" bg-indigo-700 text-white p-1 ml-2 pl-4 pr-4 rounded text-sm w-24 my-1"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setCalendarShowState(true);
                                          }}
                                        >
                                          Change
                                        </button>
                                      ) : (
                                        <button
                                          className=" bg-indigo-700 text-white p-1 ml-2 pl-4 pr-4 rounded text-sm w-24 my-1"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setCalendarShowState(false);
                                          }}
                                        >
                                          Hide
                                        </button>
                                      )}
                                    </>
                                  </div>
                                ) : (
                                  <>
                                    <button
                                      className={`bg-indigo-600 text-white p-2 pl-4 pr-4 mt-3 sm:mt-0 rounded text-sm"`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          data.contract?.payment_plan ==
                                            "weekly" ||
                                          data.contract?.payment_plan ==
                                            "bi_weekly"
                                        ) {
                                          if (displayWeekDay) {
                                            setCalendarShowState(true);
                                          }
                                        } else if (
                                          data.contract?.payment_plan ==
                                          "semi_monthly_days"
                                        ) {
                                          if (displayWeeks) {
                                            setCalendarShowState(true);
                                          }
                                        } else if (
                                          data.contract?.payment_plan ==
                                            "semi_monthly_dates" ||
                                          data.contract?.payment_plan ==
                                            "monthly"
                                        ) {
                                          if (displayDate) {
                                            setCalendarShowState(true);
                                          }
                                        }
                                      }}
                                    >
                                      Pick a Date
                                    </button>
                                  </>
                                )}
                              </>
                            ) : (
                              <button
                                className="bg-gray-700 text-white p-2 pl-4 pr-4 rounded text-sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                                disabled
                              >
                                Pick a Date
                              </button>
                            )}
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.contract?.start_date}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-span-1 sm:col-span-2">
                    <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                      Installment Amount
                    </label>
                    <div className="mt-2">
                      <div className="border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]">
                        <span>
                          {data.contract.installment_amount != "NaN" ? (
                            <input
                              type="text"
                              name="installment_amount"
                              id="installment_amount"
                              readOnly
                              placeholder="$0.00"
                              className="outline-none px-2 cursor-default"
                              value={data.contract.installment_amount}
                            />
                          ) : (
                            " 0.00"
                          )}
                        </span>
                      </div>
                      <span className="text-red-500 text-xs">
                        {errorFlag.contract?.installment_amount}
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </form>
        </div>
        {previewSchedule == "show" && (
          <Calendar
            localizer={localizer}
            startAccessor={"start"}
            endAccessor={"end"}
            style={{ height: "400px" }}
            events={events}
            components={{
              event: CustomEvent,
            }}
            eventPropGetter={eventStyleGetter}
            views={calendarViews}
            formats={formats}
            className="w-full bg-white shadow border border-violet-800 rounded-lg p-5 mt-5 sm:h-48 md:h-[100px]  "
          />
        )}
        <div className="my-3 flex justify-end items-center gap-3">
          {events.length ? (
            <>
              {previewSchedule == "show" ? (
                <button
                  onClick={() => {
                    setPreviewSchedule("");
                  }}
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-100"
                >
                  Hide
                  <CalendarMonth className="-mr-0.5 h-5 w-5" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setPreviewSchedule("show");
                  }}
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-100"
                >
                  Preview
                  <CalendarMonth className="-mr-0.5 h-5 w-5" />
                </button>
              )}
            </>
          ) : null}
          <button
            onClick={() => {
              // handleUpload();
              console.clear();
              submitFormHandler();
              // postGatewayData()

              // console.log("Data",data);
            }}
            disabled={submitClientLoader}
            type="button"
            className={`bg-[#2991e0] hover:bg-[#1991e0] text-white inline-flex gap-3 justify-center items-center   rounded-md  px-3 py-2 text-sm font-semibold  shadow-sm text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
          >
            Update Client
            <div>
              {submitClientLoader && (
                <Oval
                  height={20}
                  width={20}
                  color="#ffff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#ffff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              )}
            </div>
          </button>
        </div>
      </div>

      <ModalComponent open={openClientExistModal.open} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <DocumentDuplicateIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Duplicate Information
                  </h3>
                  <div className="mt-2">
                    {openClientExistModal.note == "" ? (
                      <p className="text-sm text-gray-500">
                        Client with similar phone number available in National
                        Access Network | Client Code:
                        <span className="text-black font-bold">
                          {clientExistData.client_code}
                        </span>
                      </p>
                    ) : (
                      <p className="text-sm text-gray-500">
                        {openClientExistModal.note}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              {openClientExistModal.action ? (
                <>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto outline-none"
                    onClick={() => {
                      handleClose();
                      navigate("/client-view");
                    }}
                  >
                    View Client Profile
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
export default UpdateClients;
