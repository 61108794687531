import React from 'react'
import DelinquentPaymentsTable from './Tables/DelinquentPaymentsTable'

const DelinquentPayments = () => {
  return (
    <>
      <DelinquentPaymentsTable/>
    </>
  )
}

export default DelinquentPayments
